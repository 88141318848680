import { RouteSectionProps, useIsRouting } from "@solidjs/router";
import { Show, Suspense } from "solid-js";
import { SquidCart } from "~/components/squid/squid_cart";
import { Sidebar } from "~/components/squid/sidebar";
import { SnackbarHost } from "~/shared_states/snackbar";
import { DottedLoader } from "~/widgets/loader";
import { SquidContextProvider } from "~/context/squid_context";

export default function (props: RouteSectionProps) {
  const isCataloguePage = () => props.location.pathname.includes("catalogue");

  const shouldExcludeSidebar = () =>
    /^\/purchases\/.+/.test(props.location.pathname) ||
    /^\/order\/.+/.test(props.location.pathname) ||
    /^\/proforma-invoice\/.+/.test(props.location.pathname) ||
    props.location.pathname.includes("checkout");

  const isRouting = useIsRouting();
  return (
    <SquidContextProvider>
      <SnackbarHost />
      <Show
        when={!isRouting()}
        fallback={
          <div class="flex items-center justify-center">
            <DottedLoader color="#999" from="squid index routing" />
          </div>
        }
      >
        <div class="no-scrollbar flex h-screen">
          {!shouldExcludeSidebar() && <Sidebar />}
          <Suspense
            fallback={
              <DottedLoader color="#999" from="squid index children suspense" />
            }
          >
            {props.children}
            {isCataloguePage() && <SquidCart />}
          </Suspense>
        </div>
      </Show>
    </SquidContextProvider>
  );
}
