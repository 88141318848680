"use server";

import { getProformaInvoices } from "../apis/server_apis";
import { SquidProformaInvoicesResponse } from "../types/order";

export const getSquidProformaInvoicesRouteData =
  async (): Promise<SquidProformaInvoicesResponse> => {
    const response = await getProformaInvoices();
    return response;
  };
