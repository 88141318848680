import { createSignal, For, onMount, Show } from "solid-js";
import { closeIconSM, smartGreetingCardVideo } from "~/assets/assets";
import { ThreeDotLoader } from "~/widgets/button";

const howItWorksDetails = {
  creator: [
    "Add gift balance, brands and your name to this gift.",
    "Pay the amount you decided to load as gift balance and get an activation code.",
    "Put the greeting card back in the sleeve and share it to your loved one along with the activation code.",
  ],
  receiver: [
    "Receiver can now scan the same QR code and enter the activation code to access the gift which includes gift balance & the brands you selected.",
    "Using the gift balance, they can generate gift cards of the brands included in the gift.",
    "The gift card then be used for their choice of product / service of the respective brand.",
  ],
};

export function HowItWorksModal(props: {
  closeModal: () => void;
  openFAQs: () => void;
}) {
  const [animate, setAnimate] = createSignal(false);
  const [isLoading, setIsLoading] = createSignal(true);

  onMount(() => {
    setAnimate(true);
  });

  return (
    <div
      class="noScrollbar fixed inset-0 z-50 flex h-full flex-col items-center justify-start overflow-y-scroll bg-black text-white opacity-0 transition-opacity duration-300"
      classList={{
        "opacity-100": animate(),
      }}
    >
      <div class="relative flex h-[60px] w-full items-center justify-start px-2 py-3">
        <button class="cursor-pointer p-2" onClick={props.closeModal}>
          <img src={closeIconSM} alt="close" />
        </button>
        <p class="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 font-jakartaSans text-f16Bold">
          How it works
        </p>
      </div>

      <div class="px-4 pb-8 pt-2">
        <Show when={isLoading()} >
          <div class="mx-auto flex justify-center items-center h-[343.4px]">
            <ThreeDotLoader color="#fff" />
          </div>
        </Show>
        <video
          autoplay
          preload="metadata"
          height={isLoading() ? "0px" : "221px"} 
          width={isLoading() ? "0px": "100%"}
          class="rounded-xl"
          onloadeddata={() => setIsLoading(false)}
        >
          <source src={smartGreetingCardVideo} />
        </video>

        <div class="flex w-full flex-col gap-10 px-1 pb-6 pt-4">
          <div class="flex flex-col gap-6">
            <div class="flex items-center gap-2">
              <p class="text-nowrap font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
                For you
              </p>
              <hr class="h-px w-full border-t border-basePrimaryDark" />
            </div>
            <For each={howItWorksDetails.creator}>
              {(details, index) => (
                <div class="flex items-center gap-3">
                  <div class="flex h-11 w-11 items-center justify-center rounded-full border border-basePrimaryDark py-[5px] pl-[14px] pr-3">
                    <p class="text-[24px] font-bold italic text-white">
                      {index() + 1}.
                    </p>
                  </div>
                  <p class="text-medium text-baseTertiaryMedium">{details}</p>
                </div>
              )}
            </For>
          </div>
          <div class="flex flex-col gap-6">
            <div class="flex items-center gap-2">
              <p class="text-nowrap font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
                For the receiver
              </p>
              <hr class="h-px w-full border-t border-basePrimaryDark" />
            </div>
            <For each={howItWorksDetails.receiver}>
              {(details, index) => (
                <div class="flex items-center gap-3">
                  <div class="flex h-11 w-11 items-center justify-center rounded-full border border-basePrimaryDark py-[5px] pl-[14px] pr-3">
                    <p class="text-[24px] font-bold italic text-white">
                      {index() + 1}.
                    </p>
                  </div>
                  <p class="text-medium text-baseTertiaryMedium">{details}</p>
                </div>
              )}
            </For>
          </div>
        </div>

        <button
          class="flex h-11 w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark  px-3 text-buttonMedium"
          onClick={props.openFAQs}
        >
          Have question? Check FAQs
        </button>
      </div>
    </div>
  );
}
