import {
  A,
  RouteSectionProps,
  useIsRouting,
  useSearchParams,
} from "@solidjs/router";
import { Show, Suspense } from "solid-js";
import { TopScroll } from "~/app";
import ShareIconWhite from "~/assets/svg_icons/share_icon_white";
import { config } from "~/data/config";
import { ThreeDotLoader } from "~/widgets/button";

export default function (props: RouteSectionProps) {
  const isRouting = useIsRouting();
  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  const amount = queryParams.amount;

  return (
    <Suspense
      fallback={
        <div class="flex h-full w-full items-center justify-center bg-black">
          <ThreeDotLoader color="#fff" />
        </div>
      }
    >
      <Show when={isRouting()}>
        <div class="fixed  inset-0 z-50 flex cursor-none items-center justify-center bg-black bg-opacity-60 ">
          <ThreeDotLoader />
        </div>
      </Show>
      <Show when={isPreview}>{buildPreviewHeader()}</Show>
      <div
        class="noScrollbar h-full w-full "
        classList={{
          "pt-[56px]": isPreview,
        }}
      >
        {props.children}
      </div>
    </Suspense>
  );

  function buildPreviewHeader() {
    return (
      <div class="fixed left-0 right-0 top-0 z-50 flex h-[56px] flex-row  items-center justify-start text-nowrap border-b border-basePrimaryMedium bg-black px-4 py-2 text-white">
        <span class="text-bold">Receiver's preview</span>
        <div class="w-full flex-1"></div>
        <div
          class="flex w-min flex-row items-center justify-center"
          onClick={() => {
            window.open(
              `https://wa.me/?text=${encodeURIComponent(getShareUrl())}`,
              "_blank"
            );
          }}
        >
          <div class="w-6">
            <ShareIconWhite />
          </div>
          <span class="text-medium underline-offset-2">
            <u>Share preview</u>
          </span>
        </div>
      </div>
    );
  }

  function getShareUrl() {
    try {
      let giftingUrl = window.location.pathname.split("/");
      let giftingKey = giftingUrl[3];
      let url = `${config.baseDomainUrl}/pluto/reveal/${giftingKey}?isPreview=${isPreview}&amount=${amount}`;
      return url;
    } catch (error) {
      console.log(error);
      return "";
    }
  }
}
