import {
  cache,
  createAsync,
  useNavigate,
  useParams,
  useLocation,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { cardGenerationFailed } from "~/assets/assets";
import { getSquidOrderDetail } from "~/server/apis/client_apis";
import {
  getSquidOrderProcessingRouteData,
  ProcurementOrderProcessingRouteData,
} from "~/server/data/squid_order_processing_route_data";
import { SquidOrderStatus } from "~/server/types/order";
import { ThreeDotLoader } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";
import { PhosphorIcon } from "~/widgets/icons";

const getSquidOrderDetailRouteData$C = cache(
  getSquidOrderProcessingRouteData,
  "procurement_order_processing"
);
type pageData = {
  fromPurchasesPage: Boolean;
};

export default function ProcurementOrderProcessing() {
  const params = useParams();
  const routeData: Accessor<ProcurementOrderProcessingRouteData | undefined> =
    createAsync<ProcurementOrderProcessingRouteData>(() => {
      return getSquidOrderDetailRouteData$C(params.orderId);
    });
  const [state, setState] = createSignal<SquidOrderStatus | undefined>();
  let interval: any;
  let longInterval: any;
  const [showTakingLonger, setShowTakingLonger] = createSignal(false);
  const navigator = useNavigate();
  const [orderProgress, setOrderProgress] = createSignal(0);

  onMount(async () => {
    await processOrder();
  });

  const processOrder = async () => {
    try {
      const result = await getSquidOrderDetail({
        orderId: params.orderId,
      });
      if (result) {
        setState(result.status);
        setOrderProgress(result.progressPercentage);
        if (
          result.status === "FULFILLED" ||
          result.status === "PARTIALLY_FULFILLED"
        ) {
          navigator("/purchases/" + params.orderId, {
            replace: true,
          });
        } else if (
          result.status === "FAILED" ||
          result.paymentDetails?.status === "FAILED"
        ) {
          setState("FAILED");
          clearInterval(interval);
          clearInterval(longInterval);
        }
      }
    } catch (error) {
      console.error("Failed to process order:", error);
    }
  };

  createEffect(() => {
    interval = setInterval(() => {
      processOrder();
    }, 10000);
  });

  createEffect(() => {
    longInterval = setInterval(() => {
      setShowTakingLonger(true);
    }, 120000);
  });

  onCleanup(() => {
    clearInterval(interval);
    clearInterval(longInterval);
  });

  return (
    <Switch>
      <Match when={state() == "FAILED"}>
        <ProcurementOrderFailure />
      </Match>
      <Match when={true}>
        <div class="flex w-full flex-col items-center justify-center text-h5">
          <Show
            when={orderProgress() > 0}
            fallback={<ThreeDotLoader color="#999" />}
          >
            <div class="mt-6 flex w-[300px]">
              <div
                class={`h-1  bg-black ${orderProgress() === 100 ? "rounded" : "rounded-s"}`}
                style={{
                  width: `${orderProgress()}%`,
                }}
              ></div>
              <div
                class="h-1  rounded-e bg-gray-200"
                style={{
                  width: `${100 - orderProgress()}%`,
                }}
              ></div>
            </div>
          </Show>
          <Spacer height={20} />
          <div
            class={`${showTakingLonger() ? "text-h5 text-textNormal" : "text-h3 text-textDark"}`}
          >
            Your order is getting ready
          </div>
          <Show when={showTakingLonger()}>
            <div class="flex flex-col items-center justify-center">
              <div class="my-3 h-1 w-14 rounded-[4px] bg-baseTertiaryMedium text-center"></div>
              <div class=" text-center text-h3 text-textDark">
                <div>It’s will take sometime to generate</div>
                {/* <div>we’ll inform you once its ready</div> */}
              </div>
              <div
                class="mt-4 flex h-10 w-40 cursor-pointer items-center justify-center rounded-[8px] border border-basePrimaryDark py-3 text-center text-mediumBold"
                onClick={() => {
                  navigator("/catalogue", { replace: true });
                }}
              >
                Go to home
              </div>
            </div>
          </Show>
        </div>
      </Match>
    </Switch>
  );

  function ProcurementOrderFailure() {
    const location = useLocation();

    const pageData = location.state as pageData;

    return (
      <div class="flex w-full  flex-col items-center justify-center pb-[250px]">
        <div class=" relative flex flex-col  content-center items-center  justify-center pt-[50px]">
          <div
            class=" absolute right-12   top-2 flex h-9 w-9 cursor-pointer items-center justify-center rounded-[100%] border bg-white"
            style={{ "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02)" }}
            onClick={() => {
              if (pageData && pageData.fromPurchasesPage) {
                navigator(-1);
              } else {
                navigator("/catalogue", { replace: true });
              }
            }}
          >
            <PhosphorIcon name="x" fontSize={20} class="text-baseTertiary" />
          </div>

          <img
            src={cardGenerationFailed}
            class="h-[120px] w-[150px]"
            alt="Card generation failed"
          />
          <div class="pb-1 pt-3 text-h5 text-errorDark">
            Card failed to generate
          </div>
          <div class="my-3 h-1 w-14 rounded-[4px] bg-baseTertiaryMedium"></div>
          <div class="flex max-w-[75%] text-center text-h3 text-textDark">
            Any money deducted will be refunded in you wallet
          </div>
        </div>
      </div>
    );
  }
}
