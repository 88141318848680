"use server";

import { getCookie } from "vinxi/http";
import { getGiftBoxMetadata } from "~/server/apis/server_apis";
import { GiftBoxMetadata } from "~/server/types/gift";
import { Cookie } from "~/types";

export interface TopUpRouteData {
  giftingMetadata: GiftBoxMetadata;
  isLoggedIn: boolean;
}

export const getSmartGCRouteData = async (
  giftingKey: string
): Promise<TopUpRouteData> => {
  const result = await getGiftBoxMetadata(giftingKey, undefined, {
    isUnauthenticated: true,
  });

  let sid = getCookie(Cookie.SessionId);

  return {
    isLoggedIn: sid ? true : false,
    giftingMetadata: result,
  };
};
