import {
  createContext,
  createEffect,
  createSignal,
  JSX,
  onMount,
  Show,
  useContext,
} from "solid-js";
import { isMobile } from "~/utils/platform";
import {
  gift_cover_v2,
  glitter_lottie,
  plutoReceiverBg,
} from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import { UpArrowIndicator } from "~/components/gifting/pluto-receiver/pluto-receiver-landing/arrows";
import { capitalizeAllWords } from "~/utils/string_utils";
import useScript from "~/utils/hooks/use_script";
import { isServer } from "solid-js/web";

type VerticalDragRevealProps = {
  greetingMessage?: string;
  senderName?: string;
  brandingImageSrc: string;
  children: JSX.Element;
  onAnimationComplete?: () => void;
};

export default function VerticalDragReveal(props: VerticalDragRevealProps) {
  let threshold = 200; // adjust this value to change the threshold

  const [isSwipeComplete, setSwipeComplete] = createSignal(false);
  const [isSwipeAnimationComplete, setSwipeAnimationComplete] =
    createSignal(false);

  const [swipeAnimationDuration, setSwipeAnimationDuration] = createSignal(500);
  const [transitionCard, setTransitionCard] = createSignal(true);

  let contentHeight: number | undefined;

  let glitterLottieElement: HTMLInputElement | undefined;

  if (!isServer && !window.customElements.get("lottie-player")) {
    useScript(
      "https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js",
      async () => {
        console.log(`lottie player loaded`);
      }
    );
  }

  function calculateHeights() {
    contentHeight =
      document.getElementById("vertical-drag-reveal-content")?.offsetHeight ??
      400;
  }

  onMount(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    await import("jquery")
      .then((module) => {
        return ((window as any).$ = module.default);
      })
      .catch((err) => {
        alert(err);
      });
    calculateHeights();
    await runAnimations();

    if (isMobile()) {
      listenToMouseEvents();
    }
  });

  onMount(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    createEffect(async () => {
      if (isSwipeComplete()) {
        document.body.style.overflow = "auto";
        document.body.style.position = "relative";
        setAnimateInitialOpacity(false);
        await new Promise((resolve) =>
          setTimeout(resolve, swipeAnimationDuration())
        );
        setSwipeAnimationComplete(true);
      } else {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
      }
    });
  });

  createEffect(() => {
    if (isSwipeComplete()) {
      setSwipeAnimationComplete(true);
    }
  });

  function animateToInitialState() {
    // card
    getCardToBeRevealedHTMLElement().css({
      transform: "translateY(20px)",
    });

    //cover
    getCardCoverHTMLElement().animate(
      {
        top: 0,
      },
      swipeAnimationDuration()
    );
  }

  function animateToFinalState() {
    // card
    setTransitionCard(true);
    setSwipeAnimationDuration(500);
    playLottie();

    getCardToBeRevealedHTMLElement().css({
      transform: `translateY(${-(contentHeight ?? 0) + 24}px)`,
    });
    //cover
    getCardCoverHTMLElement().animate(
      {
        bottom: "-200vh",
      },
      swipeAnimationDuration()
    );
  }

  function getCardToBeRevealedHTMLElement() {
    return $("#card-to-be-revealed");
  }

  function getCardCoverHTMLElement() {
    return $("#reveal-card-cover");
  }

  function playLottie() {
    try {
      // @ts-ignore
      glitterLottieElement?.play();
    } catch (e) {
      console.error(e);
    }
  }

  function listenToMouseEvents() {
    let giftCard = getCardToBeRevealedHTMLElement();
    let giftCardCover = getCardCoverHTMLElement();

    let initialMouse = 0;

    $(document).on("mousedown touchstart", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }
      initialMouse = e.clientY || e.originalEvent.touches[0].pageY;
    });

    $(document).on("mouseup touchend", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }
      const currentMouse = e.clientY || e.changedTouches[0].pageY;
      const relativeMouse = currentMouse - initialMouse;
      if (relativeMouse < threshold) {
        setTransitionCard(true);
        animateToInitialState();
        return;
      }
    });

    $(document).on("mousemove touchmove", function (e: any) {
      if (isSwipeComplete() || !e.originalEvent) {
        return;
      }

      const currentMouse = e.clientY || e.originalEvent.touches[0].pageY;
      let swipeDistance = initialMouse - currentMouse;

      if (swipeDistance < 0) {
        return;
      }

      if (transitionCard()) {
        setTransitionCard(false);
      }

      giftCard.css({
        transform: `translateY(${-swipeDistance}px)`,
      });
      giftCardCover.css({
        // bottom: -swipeDistance + "px",
        top: swipeDistance + "px",
      });

      if (swipeDistance > threshold) {
        setTransitionCard(true);
        setSwipeComplete(true);
        animateToFinalState();
      }
    });
  }

  // animation controlling hooks
  const [animateInitialOpacity, setAnimateInitialOpacity] = createSignal(false);
  const [slideGreetingAndCard, setSlideGreetingAndCard] = createSignal(false);
  const [animateCardInsideHolder, setAnimateCardInsideHolder] =
    createSignal(false);
  const [animateSlideUpIcons, setAnimateSlideUpIcons] = createSignal(false);
  const [animateDragUpText, setAnimateDragUpText] = createSignal(false);

  async function runAnimations() {
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateInitialOpacity(true);
    await new Promise((resolve) => setTimeout(resolve, 800));
    setSlideGreetingAndCard(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setAnimateCardInsideHolder(true);
    await new Promise((resolve) => setTimeout(resolve, 300));
    setAnimateSlideUpIcons(true);
    await new Promise((resolve) => setTimeout(resolve, 700));
    setAnimateDragUpText(true);
    setTransitionCard(false);
    props.onAnimationComplete?.();
  }

  return (
    <>
      <Background />
      <div
        onClick={() => {
          if (!isMobile()) {
            setSwipeAnimationDuration(1200);
            if (!isSwipeComplete()) {
              setSwipeComplete(true);
              animateToFinalState();
            }
          }
        }}
        class={`relative flex h-full w-full flex-col items-center justify-start   bg-black px-4 text-center  text-white  `}
        classList={{
          "overflow-y-hidden": !isSwipeComplete(),
          "overflow-y-scroll": isSwipeComplete(),
        }}
      >
        <div class="pointer-events-none absolute z-50 h-full w-full max-w-[400px] overflow-hidden">
          <lottie-player
            ref={glitterLottieElement}
            src={glitter_lottie}
            autoplay={false}
            loop={false}
          ></lottie-player>
        </div>
        <div
          id="vertical-drag-reveal-content"
          class="flex flex-1  flex-col items-center justify-start   "
          classList={{
            "opacity-0": isSwipeComplete(),
          }}
        >
          <div class="mt-[10vh]"></div>
          <Greeting />
          <Spacer height={12} />
          <SenderName />
          <Spacer height={40} />
          <DragUpText />
          <div class="mt-[12px]"></div>
          <UpArrowIndicator startAnimation={animateSlideUpIcons} />
          <div class="mt-[44px]"></div>
        </div>
        <div class="relative h-full max-h-[400px] w-full sm:max-w-[363px]">
          <CardToBeRevealed />
          <Show when={!isSwipeAnimationComplete()}>
            <GiftCardCover />
            <div
              class="fixed bottom-0 left-[50%] z-30 flex w-full translate-x-[-50%] items-center justify-center transition-opacity duration-300 sm:max-w-[363px]"
              classList={{
                "opacity-0": !animateCardInsideHolder() || isSwipeComplete(),
              }}
            >
              <img src={props.brandingImageSrc} alt="" />
            </div>
          </Show>
        </div>
      </div>
    </>
  );

  function Greeting() {
    return (
      <span
        class="text-center text-normal font-light text-[#D9D9D9] duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]"
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
          "translate-y-[200px]": !slideGreetingAndCard(),
          "translate-y-0": slideGreetingAndCard(),
        }}
      >
        {props.greetingMessage ?? "Hi 👋"}
      </span>
    );
  }

  function SenderName() {
    return (
      <span
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
          "translate-y-[200px]": !slideGreetingAndCard(),
          "translate-y-0": slideGreetingAndCard(),
        }}
        class="px-4 text-center font-jakartaSans text-[40px] font-semibold leading-[120%] tracking-[0.4px] duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]"
      >
        {props.senderName
          ? `${capitalizeAllWords(props.senderName)} sent you a gift`
          : "You got a gift"}
      </span>
    );
  }

  function DragUpText() {
    return (
      <span
        classList={{
          "opacity-0": !animateDragUpText(),
          "opacity-100": animateDragUpText(),
        }}
        class="text-smallMedium uppercase text-textNormal transition-opacity duration-500"
      >
        <span class="flex sm:hidden">Drag up to reveal</span>
        <span class="hidden sm:flex">Tap to reveal</span>
      </span>
    );
  }

  function CardToBeRevealed() {
    return (
      <div
        id="card-to-be-revealed"
        class={`absolute top-[0px] z-10 flex w-full flex-row  items-start justify-center `}
        classList={{
          "translate-y-[70px] opacity-0": !animateCardInsideHolder(),
          "translate-y-[20px] opacity-100": animateCardInsideHolder(),
          "transition-transform ": transitionCard(),
          "ease-[cubic-bezier(.06,1.25,.37,1.11)]": isSwipeComplete(),
        }}
        style={
          transitionCard()
            ? {
                "transition-duration": `${swipeAnimationDuration()}ms`,
              }
            : {}
        }
      >
        <VerticalDragRevealPlayLottieContext.Provider value={playLottie}>
          {props.children}
        </VerticalDragRevealPlayLottieContext.Provider>
      </div>
    );
  }

  function GiftCardCover() {
    return (
      <div
        id="reveal-card-cover"
        classList={{
          "translate-y-[450px]": !slideGreetingAndCard(),
          "translate-y-[1px]": slideGreetingAndCard(),
        }}
        class={`absolute z-[20] flex w-full flex-row items-center justify-center  transition-transform duration-[1000ms] ease-[cubic-bezier(.13,1.12,.4,.96)]`} //cubic-bezier(.13,1.12,.4,.96)
      >
        <div class="relative w-full  ">
          <img src={gift_cover_v2}></img>
        </div>
      </div>
    );
  }

  function Background() {
    return (
      <div
        class="absolute top-0 z-10 flex aspect-[355/228] w-full bg-no-repeat   transition-opacity duration-500 sm:hidden sm:max-w-[798px]"
        classList={{
          "opacity-0": !animateInitialOpacity(),
          "opacity-100": animateInitialOpacity(),
        }}
        style={{
          "background-image": `url('${plutoReceiverBg}')`,
          "background-size": "100% 100%",
        }}
      ></div>
    );
  }
}

const VerticalDragRevealPlayLottieContext = createContext<() => void>();

export function useVerticalDragRevealPlayLottie() {
  return useContext(VerticalDragRevealPlayLottieContext)!;
}
