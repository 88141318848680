import { Accessor, Show, Suspense, onMount } from "solid-js";
import { Header } from "~/components/brand_l2/header";
import { DottedLoader } from "~/widgets/loader";
import {
  GiftCardBalanceRouteData,
  getGiftCardBalanceRouteData,
} from "~/server/data/gift_card_balance_route_data";
import { createAsync } from "@solidjs/router";
import { BreadcrumbsNav } from "~/widgets/breadcrumbs";
import { ItemList } from "~/widgets/ItemList";
import { Footer } from "~/components/footer";
import CheckBalance from "~/components/gift_card_balance/check_balance";
import ExploreBrands from "~/components/gift_card_balance/explore_brands";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import attachPopStateListener from "~/utils/popstate_listener";
import { Link, Meta, Title } from "@solidjs/meta";

export default function GiftCardBalance() {
  const routeData: Accessor<GiftCardBalanceRouteData | undefined> = createAsync(
    () => getGiftCardBalanceRouteData(),
    {
      deferStream: true,
    }
  );

  onMount(() => {
    attachPopStateListener();
  });

  return (
    <>
      <Show when={routeData()}>
        <Meta name="robots" content="follow, index" />
        <Title>{routeData()?.staticContent?.metadata.title}</Title>
        <Link
          rel="canonical"
          href={"https://offers.myhubble.money/check/gift-card-balance"}
        />
        <Meta
          name="og:title"
          content={routeData()?.staticContent?.metadata.title}
        />
        <Meta
          name="description"
          content={routeData()?.staticContent?.metadata.description}
        />
        <Meta
          name="keywords"
          content={routeData()?.staticContent?.metadata.keywords}
        />
        <Meta
          name="og:description"
          content={routeData()?.staticContent?.metadata.description}
        />
        {routeData()?.staticContent?.metadata.scripts.map((script: any) => (
          <script
            type="application/ld+json"
            innerHTML={JSON.stringify(script)}
          />
        ))}
      </Show>

      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />

      <Suspense fallback={<DottedLoader color="#999" />}>
        <Header
          showActions={true}
          isLoggedIn={() => routeData()?.isLoggedIn ?? false}
          mode="hubble"
        />
        <section class="mx-[7%] my-8 lg:mx-[15%]">
          <div class="md:mb-6">
            <BreadcrumbsNav
              breadcrumbs={[
                { label: "Home", href: "/" },
                {
                  label: "Check Balance",
                  href: "/check/gift-card-balance",
                  active: true,
                },
              ]}
            />
            <h2 class="mt-2 text-h3 md:text-h2">Check gift card balance</h2>
          </div>
          <div class="mt-6 flex flex-col items-start justify-between gap-8 md:flex-row">
            <div class="w-full md:w-3/4">
              <CheckBalance />
              <div class="mt-8 w-full md:hidden">
                <ExploreBrands />
              </div>
              <div class="mt-8 md:mt-12 lg:w-full">
                <Show when={routeData()?.staticContent.content}>
                  <ItemList items={routeData()?.staticContent.content!} />
                </Show>
              </div>
            </div>
            <div class="sticky top-24 hidden w-full md:block md:w-1/3">
              <ExploreBrands />
            </div>
          </div>
        </section>
        <footer class="flex justify-center">
          <Footer />
        </footer>
      </Suspense>
    </>
  );
}
