import { createEffect, createSignal, Match, Show, Switch } from "solid-js";
import { ButtonDark } from "~/components/gifting/verification/components";
import { GiftBoxSendOtpResponse } from "~/server/types/gift";
import { createJob } from "~/utils/job";
import { Spacer } from "~/widgets/spacer";

export function CollectPhoneForm(props: {
  type: "smart-gc" | "pluto";
  submitPhoneNumber: (phoneNumber: string) => Promise<GiftBoxSendOtpResponse>;
  onValidPhoneNumberSubmitted: (phoneNumber: string, otpToken: string) => void;
  subtitle?: string;
}) {
  const [phoneNumber, setPhoneNumber] = createSignal<string>("");
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>("");

  const [isFieldActive, setIsFieldActive] = createSignal(false);

  const submitPhoneNumberJob = createJob<GiftBoxSendOtpResponse>({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    initialJob: async () => {
      if (phoneNumber().length != 10) {
        throw new Error("Please enter a 10 digit phone number");
      }
      return props.submitPhoneNumber(phoneNumber());
    },
    successCallback: async (response) => {
      return props.onValidPhoneNumberSubmitted(
        phoneNumber(),
        response.otpToken
      );
    },
  });

  let phoneInput: HTMLInputElement | undefined;
  createEffect(() => {
    if (props.type === "pluto") {
      phoneInput?.focus();
    }
  });

  createEffect(() => {
    phoneNumber();
    setErrorMessage(undefined);
  });

  return (
    <div>
      {buildTitleAndSubtitle()}
      <div
        classList={{
          "h-6": props.type === "smart-gc",
          "h-5 sm:h-6": props.type === "pluto",
        }}
      />
      {buildInputField()}
      <div
        classList={{
          "h-6": props.type === "smart-gc",
          "h-8": props.type === "pluto",
        }}
      />
      <ButtonDark
        class="!rounded-full"
        onClick={() => {
          submitPhoneNumberJob.run();
        }}
        isLoading={submitPhoneNumberJob.jobState() == "running"}
      >
        Let's start
      </ButtonDark>
    </div>
  );

  function buildTitleAndSubtitle() {
    return (
      <div
        class="flex flex-col  text-baseTertiaryLight lg:mt-0"
        classList={{
          "mt-0 items-center": props.type === "smart-gc",
          "mt-5 items-start": props.type === "pluto",
        }}
      >
        <Switch>
          <Match when={props.type === "pluto"}>
            <span class="text-smallBold">GET STARTED</span>
          </Match>
          <Match when={props.type === "smart-gc"}>
            <span class="text-smallBold uppercase tracking-[1.1px] text-textNormal">
              top up smart greeting card
            </span>
          </Match>
        </Switch>
        <Spacer height={6} />
        <Switch>
          <Match when={props.type === "pluto"}>
            <span class="text-center text-h4">Enter your phone no.</span>
          </Match>
          <Match when={props.type === "smart-gc"}>
            <span class="font-jakartaSans text-h3">Enter your phone no.</span>
          </Match>
        </Switch>

        <Show when={props.subtitle}>
          <Spacer height={4} />
          <span class="text-medium text-textNormal">{props.subtitle!}</span>
        </Show>
      </div>
    );
  }

  function buildInputField() {
    return (
      <div class="flex flex-col">
        <div
          class="rounded-lg border px-3 py-[12px] transition-colors"
          classList={{
            "border-baseTertiaryLight": isFieldActive(),
            "border-baseSecondaryMedium": !isFieldActive(),
            "border-errorDark": !!errorMessage(),
          }}
        >
          <form
            class="text flex text-bold text-baseTertiaryLight"
            onSubmit={(e) => {
              e.preventDefault();
              submitPhoneNumberJob.run();
            }}
          >
            <input
              class="flex-1 bg-transparent text-[16px] font-semibold tracking-[-0.15px]  text-baseTertiaryLight placeholder:text-normal placeholder:text-textNormal"
              ref={phoneInput}
              placeholder=" 10 digit phone number"
              value={phoneNumber()}
              onInput={(e) => setPhoneNumber(e.currentTarget.value)}
              type="tel"
              maxlength={10}
              onFocus={() => setIsFieldActive(true)}
              onBlur={() => setIsFieldActive(false)}
            />
          </form>
        </div>
        <Show when={errorMessage()}>
          <Spacer height={4} />
          <span class="text-medium  text-errorDark">{errorMessage()}</span>
        </Show>
      </div>
    );
  }
}
