import { createSignal, Match, Setter, Show, Switch } from "solid-js";
import { CollectPhoneForm } from "../pluto/login/collect_phone_form";
import { CollectOtp } from "../pluto/login/collect_otp_form";
import { VerifyPartnerOTPResponse } from "~/server/types/brand";
import { happyBirthdaySmartGreeting, HubbleFullSvg } from "~/assets/assets";
import SmartGreetingAuth from "./auth";
import { ViewState } from "~/routes/smart-gc/create/[giftingKey$]";
import { useSmartGreeting } from "~/context/smart_greeting_context";

export type LoginStepState = "phone" | "otp";

export default function SmartGreetingLoginComponent(props: {
  setView: Setter<ViewState>;
}) {
  const [step, setStep] = createSignal<LoginStepState>("phone");
  const [otpToken, setOtpToken] = createSignal<string>("");
  const [phone, setPhone] = createSignal<string>("");
  const smartGreetingAuth = new SmartGreetingAuth();

  const { smartGCDetails } = useSmartGreeting();

  return (
    <div class="dark flex h-dvh w-full flex-col bg-black p-4">
      <div class="h-40 w-full">
        <Show when={smartGCDetails.senderCardImageUrl}>
          <img src={smartGCDetails.senderCardImageUrl!} alt="" class="w-full" />
        </Show>
      </div>
      <div
        class={`mb-6 mt-8 flex-col items-center justify-center gap-4 rounded-3xl border border-basePrimaryDark bg-[#0D0D0D] p-4 pt-5`}
      >
        <div class="flex h-full w-full flex-col ">
          <Switch>
            <Match when={step() === "phone"}>{buildCollectPhoneForm()}</Match>
            <Match when={step() === "otp"}>{buildCollectOtpForm()}</Match>
          </Switch>
        </div>
      </div>

      <div class="absolute bottom-4 left-0 right-0 flex flex-col items-center gap-1">
        <div
          class={`m-4 rounded-xl p-2.5 text-center text-f12 text-textNormal`}
        >
          You'll receive a WhatsApp & SMS with the gift activation code on the
          number you provide. P.S. No spam, we promise!
        </div>

        <p class="text-center text-[10px] font-medium uppercase tracking-[1.8px] text-baseSecondaryMedium">
          Powered by
        </p>
        <HubbleFullSvg class="h-[17.6px] w-[81px]" fill="#7C7C7C" />
      </div>
    </div>
  );

  function buildCollectPhoneForm() {
    return (
      <CollectPhoneForm
        type={"smart-gc"}
        onValidPhoneNumberSubmitted={(phoneNumber, otpToken) => {
          setOtpToken(otpToken);
          setPhone(phoneNumber);
          setStep("otp");
        }}
        submitPhoneNumber={async (phoneNumber) => {
          const response = await smartGreetingAuth.sendOtp(phoneNumber);
          return {
            otpToken: response.otpToken,
          };
        }}
      />
    );
  }

  function buildCollectOtpForm() {
    return (
      <CollectOtp<VerifyPartnerOTPResponse>
        type={"smart-gc"}
        onVerificationSuccess={async (response) => {
          smartGreetingAuth.setSessionId(response.sessionId);
          props.setView("top_up");
        }}
        otpToken={otpToken()}
        phoneNumber={phone()}
        resendOtp={async (number) => {
          const response = await smartGreetingAuth.sendOtp(number);
          setOtpToken(response.otpToken);
          return {
            otpToken: response.otpToken,
          };
        }}
        submitOtp={async (props) => {
          return await smartGreetingAuth.verifyOtp({
            phoneNumber: phone(),
            otp: props.otp,
            otpToken: props.otpToken,
          });
        }}
        onClickEditPhoneNumber={() => {
          setStep("phone");
        }}
      />
    );
  }
}
