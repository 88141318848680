import {
  createSignal,
  For,
  Match,
  onMount,
  Show,
  Switch,
  useTransition,
} from "solid-js";
import { closeIconSM } from "~/assets/assets";
import { FAQs } from "~/components/pluto/faqs";
import { smartGCFaqs } from "~/data/smart_gc_faqs";
import { PhosphorIcon } from "~/widgets/icons";

export function SmartGCFAQsModal(props: { closeModal: () => void }) {
  const [animate, setAnimate] = createSignal(false);

  const [tab, setTab] = createSignal("gift");
  const [pending, start] = useTransition();

  const updateTab = (side: string) => () => start(() => setTab(side));

  const tabs = [
    { key: "gift", label: "About smart greeting card" },
    { key: "gift-balance", label: "Top-up & Balance" },
    { key: "redeem", label: "Redeem" },
    { key: "activation-code", label: "Activation code" },
    { key: "validity-returns", label: "Validity & Returns" },
  ];

  onMount(() => {
    setAnimate(true);
  });

  return (
    <div
      class="noScrollbar fixed inset-0 z-50 flex h-full flex-col items-center justify-start overflow-y-scroll bg-black text-white opacity-0 transition-opacity duration-300"
      classList={{
        "opacity-100": animate(),
      }}
    >
      <div class="relative flex h-[60px] w-full items-center justify-center px-2 py-3">
        <button
          class="absolute left-2 top-[0.7rem] cursor-pointer p-2"
          onClick={props.closeModal}
        >
          <img src={closeIconSM} alt="close" />
        </button>
        <p class="font-jakartaSans text-f16Bold">Frequently asked questions</p>
      </div>

      <div class="w-full ">
        <nav class="no-scrollbar mb-5 flex gap-x-3 overflow-x-scroll  pl-4 pr-4">
          <For each={tabs}>
            {(item) => (
              <button
                type="button"
                class="flex h-8 w-fit cursor-pointer items-center justify-center gap-1 text-nowrap rounded-lg border border-basePrimaryMedium px-3 py-2.5 text-mediumBold transition-colors duration-300"
                classList={{
                  "text-textDark bg-white ": tab() === item.key,
                  "text-white bg-transparent": tab() !== item.key,
                }}
                onClick={updateTab(item.key)}
              >
                <Show when={tab() === item.key}>
                  <PhosphorIcon
                    name="check"
                    fontSize={16}
                    size="bold"
                    class="text-textDark"
                  />
                </Show>
                <span>{item.label}</span>
              </button>
            )}
          </For>
        </nav>

        <div classList={{ pending: pending() }} class="px-4">
          <Switch>
            <Match when={tab() === "gift"}>
              <FAQs faqs={smartGCFaqs.gift} />
            </Match>
            <Match when={tab() === "gift-balance"}>
              <FAQs faqs={smartGCFaqs.giftBalance} />
            </Match>
            <Match when={tab() === "redeem"}>
              <FAQs faqs={smartGCFaqs.redeem} />
            </Match>
            <Match when={tab() === "activation-code"}>
              <FAQs faqs={smartGCFaqs.activationCode} />
            </Match>
            <Match when={tab() === "validity-returns"}>
              <FAQs faqs={smartGCFaqs.validityReturns} />
            </Match>
          </Switch>
        </div>
      </div>
    </div>
  );
}
