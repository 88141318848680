import { Meta } from "@solidjs/meta";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  ErrorBoundary,
  For,
  Match,
  onCleanup,
  onMount,
  Setter,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";
import {
  createConversation,
  getGift,
  sendNewMessage,
} from "~/server/apis/client_apis";
import {
  ChatDetails,
  ChatHistory,
  GiftCardDetails,
} from "~/server/types/pluto";
import { IntroCard } from "../new-chat/intro_card";
import { CreditsDisplay } from "../new-chat/credits";
import { CompletedConversation } from "./completed_conversation";
import { DesktopSuggestions } from "../modals/desktop_suggestions";
import { FullScreenInputModal } from "../modals/full_screen_input_modal";
import { GiftCard } from "../gift-card/gift_card";
import { goBack } from "~/shared_states/modal";
import { PhosphorIcon } from "~/widgets/icons";
import { MessageBubble } from "./message_bubble";
import { LoadingIndicator } from "./loading";
import ViewGeneratedGiftChatMessage from "./view_generated_gift_chat_message";
import { useNavigate } from "@solidjs/router";
import { usePluto } from "~/context/pluto_context";
import { APIError } from "~/utils/fetch";
import { showSnackBar } from "~/shared_states/snackbar";
import autosize from "autosize";
import { captureErrorInSentry } from "~/utils/third_party/sentry";
import { HubbleError } from "~/components/error";
import { giftGenerationGlitter } from "~/assets/assets";

export type PlutoChatStore = {
  chatDetails: ChatDetails | null;
  userMessage: string;
  isTextareaFocused: boolean;
  currentTextareaHeight: string;
  isMessageLoading: boolean;
  isPollLoading: boolean;
  isFirstMessage: boolean;
  hasConversationEnded: boolean;
};

export type ModalState = {
  id: string;
};

export type PlutoChatProps = {
  isFullScreenModalOpen: Accessor<boolean>;
  setIsFullScreenModalOpen: Setter<boolean>;
  giftDetails?: GiftCardDetails | undefined;
  chatDetails?: ChatDetails | null;
};

export default function PlutoChatComponent(props: PlutoChatProps) {
  const [state, setState] = createStore<PlutoChatStore>({
    chatDetails: props.chatDetails ?? null,
    userMessage: "",
    isTextareaFocused: false,
    isFirstMessage: !props.chatDetails,
    currentTextareaHeight: "auto",
    isMessageLoading: false,
    isPollLoading: false,
    hasConversationEnded: false,
  });

  const { plutoGifts, setNewGiftCard, syncConversations, syncCredits } =
    usePluto();

  const navigate = useNavigate();

  const [characterCount, setCharacterCount] = createSignal(0);
  const [isGiftCardReady, setIsGiftCardReady] = createSignal(false);
  let giftGenerationGlitterRef: HTMLDivElement | undefined;

  createEffect(() => {
    if (state.chatDetails?.status === "COMPLETED" && !props.giftDetails) {
      pollForGiftStatus();
    }
  });

  createEffect(async () => {
    if (plutoGifts.newGiftCardDetails) {
      await syncCredits();
    }
  });

  createEffect(() => {
    if (
      state.chatDetails?.status === "COMPLETED" ||
      state.chatDetails?.status === "OFF_TOPIC"
    ) {
      setState("hasConversationEnded", true);
    }
  });

  const pollForGiftStatus = () => {
    setState("isPollLoading", true);
    const intervalId = setInterval(async () => {
      try {
        const giftResponse = await getGift(state.chatDetails?.id!);
        if (giftResponse.status === "COMPLETED") {
          setNewGiftCard(giftResponse);
          setState("isPollLoading", false);
          clearInterval(intervalId);
          setIsGiftCardReady(true);
        }
      } catch (error) {
        console.error("Error fetching gift status:", error);
        setState("isPollLoading", false);
      }
    }, 5000);

    onCleanup(() => clearInterval(intervalId));
  };

  const handleNewMessage = async () => {
    const trimmedMessage = state.userMessage.trim();
    if (!trimmedMessage) return;

    props.setIsFullScreenModalOpen(false);
    setState("isMessageLoading", true); // sus
    try {
      if (state.chatDetails === null) {
        const initialSystemMessages: ChatHistory[] = [
          {
            sender: "user",
            content: trimmedMessage,
            timestamp: new Date().toISOString(),
          },
        ];
        setState("chatDetails", (chatDetails) => ({
          ...chatDetails!,
          messages: initialSystemMessages,
        }));
        const conversationDetails = await createConversation({
          throwAuthError: true,
        });
        await syncCredits();
        await syncConversations();
        setState("chatDetails", () => ({
          id: conversationDetails.id,
          title: "",
          messages: initialSystemMessages,
          status: "INITIATED",
        }));
      } else {
        setState("chatDetails", (chatDetails) => ({
          ...chatDetails!,
          messages: [
            ...(chatDetails?.messages || []),
            {
              sender: "user",
              content: trimmedMessage,
              timestamp: new Date().toISOString(),
            },
          ],
        }));
      }

      const updatedChat = await sendNewMessage(
        state.chatDetails!.id,
        trimmedMessage,
        { throwAuthError: true }
      );
      setState("chatDetails", updatedChat);
      setState("userMessage", "");
      setState("currentTextareaHeight", "auto");
    } catch (error: any) {
      if (error instanceof APIError && error.code === 401) {
        showSnackBar({
          message: "session expired, redirecting to login page",
          level: "error",
        });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate("/pluto/login", { replace: true });
      } else {
        showSnackBar({
          message:
            error.message ?? "Failed to send message, please contact support",
          level: "error",
        });
      }
    } finally {
      setState("userMessage", "");
      setState("isMessageLoading", false);
    }
  };

  const credits = createMemo(() => {
    return plutoGifts.credits;
  });

  const handleAutoFlipComplete = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    navigate(`/pluto/create/${plutoGifts.newGiftCardDetails?.id}`, {
      state: { autoFlip: false },
    });
  };

  const Chat = ({ state }: { state: PlutoChatStore }) => {
    const { plutoGifts } = usePluto();
    const navigate = useNavigate();

    let chatEndRef: HTMLDivElement | undefined;

    onMount(() => {
      if (state.chatDetails?.status === "COMPLETED" && chatEndRef) {
        chatEndRef.scrollIntoView({ behavior: "smooth" });
      }
    });

    return (
      <div class="noScrollbar flex-1 overflow-y-auto">
        <div class="space-y-4 p-4 pb-12 pt-8">
          <For each={state.chatDetails?.messages}>
            {(message) => <MessageBubble message={message} />}
          </For>
          <Show when={state.isMessageLoading}>
            <LoadingIndicator />
          </Show>
          <Show
            when={
              state.isPollLoading ||
              (props.giftDetails ?? plutoGifts.newGiftCardDetails)
            }
          >
            <ViewGeneratedGiftChatMessage
              gift={props.giftDetails ?? plutoGifts.newGiftCardDetails}
              recipientName={
                (props.giftDetails ?? plutoGifts.newGiftCardDetails)?.content
                  .text.occasion.recipientName ?? ""
              }
              onClick={() => {
                navigate(
                  `/pluto/create/${(props.giftDetails ?? plutoGifts.newGiftCardDetails)?.id}`,
                  {
                    state: {
                      autoFlip: plutoGifts.newGiftCardDetails ? true : false,
                    },
                  }
                );
              }}
            />
          </Show>
          <div ref={chatEndRef}></div>
        </div>
      </div>
    );
  };

  function GiftenerationGlitterLottie() {
    return (
      <lottie-player
        ref={giftGenerationGlitterRef}
        src={giftGenerationGlitter}
        autoplay={true}
        loop={true}
      ></lottie-player>
    );
  }

  return (
    <ErrorBoundary
      fallback={(err) => {
        captureErrorInSentry(err);
        return <HubbleError errorMessage={err.message} />;
      }}
    >
      <Suspense>
        <div class="m-auto flex h-[90dvh] w-full flex-col md:w-[800px] lg:my-0 lg:h-[95vh]">
          <Meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content"
          />
          <Show
            when={state.chatDetails && state.chatDetails.messages.length > 0}
            fallback={
              <IntroCard
                setState={setState}
                setIsFullScreenModalOpen={props.setIsFullScreenModalOpen}
                setCharacterCount={setCharacterCount}
                credits={credits}
              />
            }
          >
            <Chat state={state} />
          </Show>
          <div class="fixed bottom-0 left-0 right-0 sm:mx-0 lg:left-[280px]">
            <Show when={!state.chatDetails}>
              <CreditsDisplay credits={credits} />
            </Show>
            <Switch>
              <Match when={state.hasConversationEnded}>
                <CompletedConversation
                  canStartNew={credits()?.available !== 0}
                />
              </Match>
              <Match when={state.chatDetails?.id || credits()?.available !== 0}>
                <MessageInput
                  state={state}
                  setState={setState}
                  handleNewMessage={handleNewMessage}
                  characterCount={characterCount}
                  setCharacterCount={setCharacterCount}
                  isFullScreenModalOpen={props.isFullScreenModalOpen}
                  setIsFullScreenModalOpen={props.setIsFullScreenModalOpen}
                />
              </Match>
            </Switch>
          </div>

          <Show when={props.isFullScreenModalOpen()}>
            <div
              class="hidden "
              classList={{
                "lg:block": characterCount() == 0,
              }}
            >
              <DesktopSuggestions
                setCharacterCount={setCharacterCount}
                state={state}
                setState={setState}
                setIsFullScreenModalOpen={props.setIsFullScreenModalOpen}
              />
            </div>
            <div class="lg:hidden">
              <FullScreenInputModal
                closeModal={() => {
                  goBack();
                }}
                initialCount={characterCount}
                setCharacterCount={setCharacterCount}
                state={state}
                setState={setState}
                handleNewMessage={handleNewMessage}
              />
            </div>
          </Show>

          <Show when={isGiftCardReady()}>
            <div class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-black lg:bg-plutoGiftGenerationBgLG lg:bg-cover lg:bg-no-repeat">
              <div class="absolute left-2/4  top-2/4 z-[100] w-[373px] -translate-x-1/2 -translate-y-1/2 animate-slide-up lg:w-[420px]">
                <GiftenerationGlitterLottie />
              </div>
              <div class="absolute left-2/4 top-2/4 -translate-x-1/2 -translate-y-1/2 animate-slide-up">
                <GiftCard
                  giftDetails={() => plutoGifts.newGiftCardDetails!}
                  flipOnCard={true}
                  showActions={false}
                  autoFlip={true}
                  showNavigationDots={true}
                  showActionsBg={false}
                  onAutoFlipComplete={handleAutoFlipComplete}
                />
              </div>
            </div>
          </Show>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}

const MessageInput = (props: {
  state: PlutoChatStore;
  setState: SetStoreFunction<PlutoChatStore>;
  handleNewMessage: () => Promise<void>;
  characterCount: Accessor<number>;
  setCharacterCount: Setter<number>;
  isFullScreenModalOpen: Accessor<boolean>;
  setIsFullScreenModalOpen: Setter<boolean>;
}) => {
  const maxCharLimit = 500;

  const openFullScreenInput = () => {
    if (props.state.isFirstMessage) {
      props.setIsFullScreenModalOpen(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      if (
        !props.state.isMessageLoading &&
        !props.state.isPollLoading &&
        props.characterCount() <= maxCharLimit
      ) {
        props.handleNewMessage();
      }
    }
  };

  createEffect(() => {
    if (
      props.state.chatDetails &&
      props.state.chatDetails?.messages.length > 1
    ) {
      props.setState("isFirstMessage", false);
    }
  });

  onMount(() => {
    let elemet = document.getElementById("adjinput");
    if (elemet) {
      autosize(elemet);
    }
  });

  return (
    <div class="bg-black px-4 pb-4 pt-0 lg:mx-auto lg:w-[800px]">
      <div class="relative  max-w-full ">
        <textarea
          id="adjinput"
          rows={1}
          class={`no-scrollbar block max-h-40 w-full  overflow-y-auto rounded-2xl border border-basePrimaryLight  bg-black p-3 pe-16 pl-5 text-[16px] font-normal text-white placeholder:text-nowrap placeholder:text-baseSecondaryDark ${props.characterCount() > maxCharLimit ? "focus:border-errorDark" : "focus:border-baseTertiaryLight"} focus:ring-baseTertiaryLight disabled:pointer-events-none`}
          placeholder={
            props.state.isFirstMessage
              ? "Tell me who it's for & the occasion..."
              : "Message Pluto..."
          }
          onFocus={() => {
            props.setState("isTextareaFocused", true);
            if (props.state.isFirstMessage) {
              openFullScreenInput();
            }
          }}
          onBlur={() => props.setState("isTextareaFocused", false)}
          onKeyDown={handleKeyDown}
          value={props.state.isMessageLoading ? "" : props.state.userMessage}
          onInput={(e) => {
            const message = e.currentTarget.value;
            if (message.length <= maxCharLimit) {
              props.setState("userMessage", message);
              props.setCharacterCount(message.length);
            }
          }}
          disabled={props.state.isMessageLoading || props.state.isPollLoading}
        />
        <div class="absolute bottom-[0.45rem] end-2">
          <button
            onClick={props.handleNewMessage}
            class={`flex h-9 w-9 items-center justify-center rounded-lg disabled:pointer-events-none disabled:opacity-50 ${
              props.state.isTextareaFocused
                ? "bg-white"
                : "bg-baseSecondaryDark"
            }`}
            disabled={
              props.state.isMessageLoading ||
              props.state.isPollLoading ||
              props.characterCount() > maxCharLimit
            }
          >
            <PhosphorIcon name="arrow-up" fontSize={20} size="bold" />
          </button>
        </div>
      </div>
      <div
        class={`mt-0.5 h-[15px] text-end text-[10px] font-semibold ${
          props.characterCount() > maxCharLimit
            ? "text-errorDark"
            : "text-textNormal"
        }`}
      >
        <Show when={props.state.isTextareaFocused}>
          <span>
            {props.characterCount()}/{maxCharLimit}
          </span>
        </Show>
      </div>
    </div>
  );
};
