import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  batch,
  createContext,
  JSX,
  onMount,
  useContext,
  createEffect,
  createSignal,
} from "solid-js";
import { createStore } from "solid-js/store";
import { getSquidWalletBalance, simulateCart } from "~/server/apis/client_apis";
import {
  getSquidContextData,
  SquidContextData,
} from "~/server/data/squid_route_data";
import { SquidProductWithName } from "~/server/types/order";
import { LocalStorageKey } from "~/types";
import LocalStorageUtil from "~/utils/local_storage";

type SquidContextProviderProps = {
  children: JSX.Element;
};

type ProductDetail = {
  logoUrl: string;
  brandName: string;
};

export type CartProduct = {
  detail: ProductDetail;
  productId: string;
  amount: number;
  denomination: number;
  quantity: number;
};

export type Cart = {
  totalAmount: number;
  totalQuantity: number;
  totalDiscount: number;
  products: CartProduct[];
  proformaInvoiceId?: string;
};

type SquidDataStore = {
  cart: Cart;
  userProfile: { name: string; logo: string; email: string; phone: string };
  walletBalance: number;
};

type SquidContext = {
  squidDataStore: SquidDataStore;
  addToCart: (newProduct: CartProduct) => void;
  updateQuantity: (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => void;
  clearCart: () => void;
  updatePIInfo: (id: string) => void;
  clearAndAddProductsToCart: (products: SquidProductWithName[]) => void;
  refetchBalance: () => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
};

const SquidContext = createContext<SquidContext>();

const getSquidContextData$C = cache(getSquidContextData, "squid-route-data");

export function SquidContextProvider(props: SquidContextProviderProps) {
  const routeData: Accessor<SquidContextData | undefined> =
    createAsync<SquidContextData>(() => getSquidContextData$C());

  const [simulateErrorMessage, setSimulateErrorMessage] = createSignal<
    string | undefined
  >(undefined);

  const [loadingSimulateData, setLoadingSimulateData] = createSignal(false);

  const [squidDataStore, setSquidDataStore] = createStore<SquidDataStore>({
    cart: {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [] as CartProduct[],
      proformaInvoiceId: undefined as undefined | string,
    },
    userProfile: {
      name: "Team",
      logo: "",
      email: "",
      phone: "",
    },
    walletBalance: 0,
  });

  createEffect(() => {
    if (routeData()) {
      setSquidDataStore("userProfile", {
        name: routeData()?.userProfile?.name ?? "Team",
        logo: routeData()?.userProfile?.logo ?? "",
        email: routeData()?.userProfile?.email ?? "",
        phone: routeData()?.userProfile?.phone ?? "",
      });
      setSquidDataStore("walletBalance", routeData()?.balance ?? 0);
    }
  });

  onMount(() => {
    const cartStr = LocalStorageUtil.getItem<string>(LocalStorageKey.SquidCart);
    if (cartStr) {
      const cart = JSON.parse(cartStr) as Cart;
      setSquidDataStore("cart", cart);
    }
  });

  const simulateAndUpdateCartDiscount = async () => {
    const simulatePayload = {
      totalAmount: squidDataStore.cart.totalAmount,
      products: squidDataStore.cart.products.map((product) => ({
        productId: product.productId,
        amount: product.amount,
        denominationDetails: [
          {
            denomination: product.denomination,
            quantity: product.quantity,
          },
        ],
      })),
    };

    try {
      setSimulateErrorMessage(undefined);
      setLoadingSimulateData(true);
      const response = await simulateCart(simulatePayload);
      setSquidDataStore(
        "cart",
        "totalDiscount",
        response.data[0].totalDiscount
      );
      setSquidDataStore("cart", "totalAmount", response.data[0].totalAmount);

      LocalStorageUtil.setItem(
        LocalStorageKey.SquidCart,
        JSON.stringify(squidDataStore.cart)
      );
    } catch (error: any) {
      setSimulateErrorMessage(error.message);
      console.error("Error simulating cart:", error);
    }
    setLoadingSimulateData(false);
  };

  const addAProductToCart = async (newProduct: CartProduct) => {
    const existingProductIndex = squidDataStore.cart.products.findIndex(
      (product) =>
        product.productId === newProduct.productId &&
        product.denomination === newProduct.denomination
    );

    if (existingProductIndex !== -1) {
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "quantity",
        (quantity) => quantity + newProduct.quantity
      );
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "denomination",
        () => newProduct.denomination
      );
      setSquidDataStore(
        "cart",
        "products",
        existingProductIndex,
        "amount",
        (amount) => amount + newProduct.amount
      );
    } else {
      setSquidDataStore("cart", "products", (products) => [
        ...products,
        newProduct,
      ]);
    }

    setSquidDataStore(
      "cart",
      "totalQuantity",
      squidDataStore.cart.products.length
    );
    setSquidDataStore(
      "cart",
      "totalAmount",
      (amount) => amount + newProduct.amount
    );
    await simulateAndUpdateCartDiscount();
  };

  const clearAndAddProductsToCart = async (
    products: SquidProductWithName[]
  ) => {
    clearCart();
    products.map((product) => {
      product.denominationDetails.length == 1
        ? setSquidDataStore("cart", "products", (products) => [
            ...products,
            {
              productId: product.productId,
              amount: product.amount,
              denomination: product.denominationDetails[0].denomination,
              quantity: product.denominationDetails[0].quantity,
              detail: {
                logoUrl: "",
                brandName: product.productName,
              },
            },
          ])
        : product.denominationDetails.map((pdt) => {
            setSquidDataStore("cart", "products", (products) => [
              ...products,
              {
                productId: product.productId,
                amount: product.amount,
                denomination: pdt.denomination,
                quantity: pdt.quantity,
                detail: {
                  logoUrl: "",
                  brandName: product.productName,
                },
              },
            ]);
          });
      setSquidDataStore(
        "cart",
        "totalAmount",
        (amount) => amount + product.amount
      );
    });

    setSquidDataStore(
      "cart",
      "totalQuantity",
      squidDataStore.cart.products.length
    );

    await simulateAndUpdateCartDiscount();
  };

  const updateQuantity = async (
    productId: string,
    denomination: number,
    newQuantity: number
  ) => {
    const existingProductIndex = squidDataStore.cart.products.findIndex(
      (product) =>
        product.productId === productId && product.denomination === denomination
    );

    if (existingProductIndex !== -1) {
      const existingProduct =
        squidDataStore.cart.products[existingProductIndex];
      const quantityDifference = newQuantity - existingProduct.quantity;
      const amountDifference = quantityDifference * denomination;

      batch(() => {
        if (newQuantity === 0) {
          setSquidDataStore("cart", "products", (products) =>
            products.filter((_, index) => index !== existingProductIndex)
          );
        } else {
          setSquidDataStore(
            "cart",
            "products",
            existingProductIndex,
            "quantity",
            newQuantity
          );
          setSquidDataStore(
            "cart",
            "products",
            existingProductIndex,
            "amount",
            existingProduct.denomination * newQuantity
          );
        }

        const updatedTotalAmount = squidDataStore.cart.products.reduce(
          (total, product) =>
            total +
            (product.productId === productId &&
            product.denomination === denomination
              ? denomination * newQuantity
              : product.amount),
          0
        );

        setSquidDataStore(
          "cart",
          "totalQuantity",
          squidDataStore.cart.products.length
        );
        setSquidDataStore("cart", "totalAmount", updatedTotalAmount);
      });

      if (squidDataStore.cart.products.length === 0) {
        setSquidDataStore("cart", "totalQuantity", 0);
        LocalStorageUtil.removeItem(LocalStorageKey.SquidCart);
        return;
      }

      await simulateAndUpdateCartDiscount();
    }
  };

  const updatePIInfo = (id: string) => {
    setSquidDataStore("cart", {
      proformaInvoiceId: id,
    });
  };

  const clearCart = () => {
    setSquidDataStore("cart", {
      totalAmount: 0,
      totalQuantity: 0,
      totalDiscount: 0,
      products: [],
      proformaInvoiceId: undefined,
    });
    LocalStorageUtil.removeItem(LocalStorageKey.SquidCart);
  };

  const refetchBalance = async () => {
    const balance = await getSquidWalletBalance();
    if (balance) {
      setSquidDataStore("walletBalance", balance.balance);
    }
  };

  return (
    <SquidContext.Provider
      value={{
        squidDataStore,
        addToCart: addAProductToCart,
        updateQuantity,
        clearCart,
        updatePIInfo,
        clearAndAddProductsToCart,
        refetchBalance,
        simulateErrorMessage,
        loadingSimulateData,
      }}
    >
      {props.children}
    </SquidContext.Provider>
  );
}

export function useSquidContext() {
  return useContext(SquidContext)!;
}
