"use server";

import { getSmartGCCurationDetails } from "~/server/apis/server_apis";
import { GiftBoxMetadataDetails } from "~/server/types/gift";

export interface SharePageRouteData {
  giftingMetadata: GiftBoxMetadataDetails;
}

export const getSharePageRouteData = async (
  curationId: string
): Promise<SharePageRouteData> => {
  const result = await getSmartGCCurationDetails(curationId);

  return {
    giftingMetadata: result,
  };
};
