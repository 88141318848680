import {
  cache,
  createAsync,
  useNavigate,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { Accessor, createSignal, For, onMount, Show, Suspense } from "solid-js";
import {
  copyWhiteIcon,
  greenTickLg,
  HubbleFullSvg,
  shareIconSmartGC,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import { ShareAppTray } from "~/components/pluto/modals/share_app_tray";
import { PaymentFailure } from "~/components/pluto/payment_failure";
import { useSmartGreeting } from "~/context/smart_greeting_context";
import {
  getSharePageRouteData,
  SharePageRouteData,
} from "~/server/data/smart_greeting/share_route_data";
import { showSnackBar } from "~/shared_states/snackbar";
import { openIntercom } from "~/utils/third_party/intercom";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getSharePageRouteData$C = cache(getSharePageRouteData, "share_page");

export default function PlutoGiftCardShare() {
  const params = useParams();
  const giftingKey = params.giftingKey as string;
  const [queryParams] = useSearchParams();
  const curationId = queryParams.curationId as string;
  const paymentFailed = queryParams.paymentFailed === "true";
  const { openModal } = useModal()!;
  const {
    setSmartGCDetailsToLocalForPreview,
    getSmartGCDetailsFromLocalPostPreview,
  } = useSmartGreeting();
  const navigate = useNavigate();

  const routeData: Accessor<SharePageRouteData | undefined> =
    createAsync<SharePageRouteData>(
      () => {
        return getSharePageRouteData$C(curationId!);
      },
      {
        deferStream: true,
      }
    );

  const paymentStatus = paymentFailed ? "failure" : "success";

  if (paymentStatus === "failure") {
    return (
      <PaymentFailure closeLink={`/smart-gc/create/${params.giftingKey$}`} />
    );
  }

  const [copied, setCopied] = createSignal(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        routeData()?.giftingMetadata.activationCode!
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (error) {
      showSnackBar({
        level: "error",
        message: "Failed to copy code.",
      });
    }
  };

  function handleShareAppTray() {
    const handleShareClick = async () => {
      try {
        navigator.clipboard.writeText(
          `Gift activation code: ${routeData()?.giftingMetadata.activationCode!}`
        );
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (error) {
        showSnackBar({
          level: "error",
          message: "Failed to copy code",
        });
      }
    };

    openModal(
      () => {
        return (
          <div class="relative w-full p-4">
            <ShareAppTray
              code
              isCopied={copied}
              shareLink={() =>
                `Gift activation code: ${routeData()?.giftingMetadata.activationCode!}`
              }
              handleShareClick={handleShareClick}
            />
          </div>
        );
      },
      "dark",
      "!bg-black !border-t !border-basePrimaryDark"
    );
  }

  onMount(() => {
    getSmartGCDetailsFromLocalPostPreview();
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <Show when={routeData()} fallback={<DottedLoader color="#999" />}>
        <div class="noScrollbar flex h-full w-full flex-col items-center justify-start ">
          <div class="h-[400px] w-full bg-[linear-gradient(180deg,_#004D57_0%,_#000_100%)]">
            <div class="flex flex-col items-center justify-center gap-6 px-5 pb-8 pt-9">
              <img
                class="h-[53px] w-[53px]"
                src={greenTickLg}
                alt="brand card"
              />
              <div class="flex flex-col items-center justify-center">
                <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-[#24DD7D]">
                  Payment successful
                </p>
                <p class="w-full text-center font-jakartaSans text-h3 font-medium text-white">
                  Share greeting card <br /> & the gift activation code <br />
                  with the receiver
                </p>
              </div>
            </div>
          </div>
          <div class="mx-5 mt-0">
            <div class="relative h-[414px]">
              <div class="animated-top-up-border-box-glow h-[414px] min-w-[335px]"></div>
              <div class=" animated-top-up-border-box h-[414px] w-full min-w-[335px] rounded-[18px] border border-[#212427] bg-black p-6 [box-shadow:0px_-12px_24px_0px_rgba(0,_0,_0,_0.20)]">
                <div class="mb-6 h-[148px]">
                  <img src={shareIconSmartGC} alt="" />
                </div>
                <div class="flex flex-col items-center justify-center gap-4">
                  <div class="flex items-center gap-2">
                    <hr class="w-[78px] border border-t border-basePrimaryDark" />
                    <p class="text-nowrap font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-white">
                      Activation code
                    </p>
                    <hr class="w-[78px] border border-t border-basePrimaryDark" />
                  </div>
                  <div class="flex flex-col items-center gap-5">
                    <div class="flex gap-[7px]">
                      <Show when={routeData()?.giftingMetadata.activationCode}>
                        <For
                          each={routeData()?.giftingMetadata.activationCode?.split(
                            ""
                          )}
                        >
                          {(character) => (
                            <div class="flex h-[50px] w-[42px] flex-col items-center justify-center rounded-lg bg-[#004D57]  p-2.5 font-jakartaSans text-h2 font-medium text-white">
                              {character}
                            </div>
                          )}
                        </For>
                      </Show>
                    </div>
                    <button
                      class="flex h-[44px] w-[288px] items-center justify-center gap-2 rounded-[41px] border border-white px-3 text-buttonMedium text-white"
                      onClick={copyToClipboard}
                    >
                      <Show
                        when={copied()}
                        fallback={
                          <>
                            <img src={copyWhiteIcon} alt="copy" />
                            <p>Copy code</p>
                          </>
                        }
                      >
                        <PhosphorIcon
                          name="check"
                          fontSize={16}
                          class={"text-[#00AF54]"}
                        />
                        <p class="text-buttonMedium text-[#00AF54]">
                          Copied to clipboard
                        </p>
                      </Show>
                    </button>
                  </div>
                  <button
                    class="flex h-[44px] w-full items-center justify-center gap-2 text-buttonMedium text-white"
                    onClick={handleShareAppTray}
                  >
                    <PhosphorIcon
                      name={"share-network"}
                      fontSize={18}
                      class="text-white"
                      size="light"
                    ></PhosphorIcon>
                    <p>Share code</p>
                  </button>
                </div>
              </div>
            </div>

            <div class="my-6">
              <p class="px-4 text-center text-normal text-textNormal">
                Receiver can scan the same QR code and use the activation code
                to access the gift you topped up.
              </p>
              <div class="my-6">
                <div
                  class="flex h-[60px] w-full cursor-pointer items-center justify-between gap-2 border-y border-basePrimaryMedium"
                  onClick={() => {
                    setSmartGCDetailsToLocalForPreview();
                    const state = {
                      senderName: routeData()?.giftingMetadata.senderName,
                      budget: routeData()?.giftingMetadata.budget,
                      brandDetails: routeData()?.giftingMetadata.brandDetails,
                      receiverCardImageUrl:
                        routeData()?.giftingMetadata.receiverCardImageUrl,
                      receiverCardBackgroundColour:
                        routeData()?.giftingMetadata
                          .receiverCardBackgroundColour,
                    };
                    navigate(
                      `/smart-gc/reveal/${giftingKey}?isPreview=true&data=${btoa(JSON.stringify(state))}`
                    );
                  }}
                >
                  <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
                    Receiver’s preview
                  </p>
                  <PhosphorIcon
                    name={"caret-right"}
                    class={"text-textNormal"}
                    fontSize={14}
                    size="bold"
                  />
                </div>
                <div
                  class="flex h-[60px] w-full cursor-pointer items-center justify-between gap-2 border-b border-basePrimaryMedium"
                  onClick={() => {
                    openIntercom();
                  }}
                >
                  <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-textNormal">
                    Facing issues? Contact Support
                  </p>
                  <PhosphorIcon
                    name={"caret-right"}
                    class={"text-textNormal"}
                    fontSize={14}
                    size="bold"
                  />
                </div>
              </div>
              <div class="flex flex-col items-center gap-1">
                <p class="text-center text-[10px] font-medium uppercase tracking-[1.8px] text-baseSecondaryMedium">
                  Powered by
                </p>
                <HubbleFullSvg class="h-[17.6px] w-[81px]" fill="#7C7C7C" />
              </div>
            </div>
          </div>
        </div>
      </Show>
    </Suspense>
  );
}
