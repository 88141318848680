import { onCleanup, onMount } from "solid-js";

export default function RestrictBodyScroll() {
  onMount(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = "hidden";
    }
    window.document.body.style.position = "fixed";

    onCleanup(() => {
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = "auto";
      }
      window.document.body.style.position = "relative";
    });
  });

  return <></>;
}
