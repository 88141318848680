"use server";

import { getCategories, getProductSearch } from "~/server/apis/server_apis";
import { CategoryResult } from "~/server/types/category";
import { SearchResults } from "~/server/types/search";

export interface SelectBrandsRouteData {
  categories: CategoryResult;
  popularBrands: SearchResults;
}

const priorityOrder = [
  "FASHION",
  "BEAUTY",
  "JEWELLERY",
  "ELECTRONICS",
  "FOOD",
  "HOME_DECOR",
  "ACCESSORIES",
  "FITNESS",
  "ENTERTAINMENT",
  "HOTELS",
  "TRAVEL_AND_TRANSPORT",
  "LUXURY",
  "WATCHES",
  "FURNISHING",
];

const notIncludedCategories = [
  "HOT_DEALS",
  "NEW_BRANDS",
  "GAMING",
  "SOFTWARE",
  "KIDS",
  "PHARMACY",
  "OTT",
  "EMERGING_BRANDS",
  "HAND_BAGS",
  "MUSIC",
];

export const getSelectBrandsRouteData =
  async (): Promise<SelectBrandsRouteData> => {
    const categories$P = getCategories();
    const popularBrands$P = getProductSearch({ category: "POPULAR_BRANDS" });

    const resolved = await Promise.all([categories$P, popularBrands$P]);

    const categories = resolved[0];
    const popularBrands = resolved[1];

    const sortedCategories = {
      data: categories.data
        .filter((category) => !notIncludedCategories.includes(category.name))
        .sort((a, b) => {
          const indexA = priorityOrder.indexOf(a.name);
          const indexB = priorityOrder.indexOf(b.name);

          const adjustedIndexA = indexA === -1 ? priorityOrder.length : indexA;
          const adjustedIndexB = indexB === -1 ? priorityOrder.length : indexB;

          return adjustedIndexA - adjustedIndexB;
        }),
    };

    return { categories: sortedCategories, popularBrands };
  };
