import { CartProduct } from "~/context/squid_context";
import { BrandDenominationDetailModal } from "./brand_detail_modal";
import { DenominationDetailsModal } from "./denominations_details_modal";
import { createSignal } from "solid-js";

export function BrandDetailDenominationModal(props: {
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  denominationDetails?: { quantity: number; denomination: number }[];
}) {
  const [denominationDetails, setDenominationDetails] = createSignal<
    { quantity: number; denomination: number }[]
  >([]);

  return (
    <div class="relative flex h-[700px] max-h-[700px] w-full overflow-y-hidden bg-white">
      <div class="noScrollbar  w-[514px] max-w-[514px]  overflow-y-scroll rounded-2xl border">
        <BrandDenominationDetailModal
          brandKey={props.brandKey}
          isInDetailedView={true}
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          denominationDetails={props.denominationDetails}
          denominationDetailsAccessor={
            denominationDetails().length > 0 ? denominationDetails : undefined
          }
        />
      </div>
      <div class="noScrollbar absolute right-0 top-0 h-[480px] w-[360px] overflow-y-scroll rounded-[20px] border bg-white">
        <DenominationDetailsModal
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          brandKey={props.brandKey}
          isInDetailedView={true}
          denominationDetails={props.denominationDetails}
          denominationDetailsSetter={setDenominationDetails}
        />
      </div>
    </div>
  );
}
