import {
  Accessor,
  JSX,
  Setter,
  Show,
  createContext,
  createEffect,
  createSignal,
  useContext,
} from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";
import { zIndexes } from "~/z_indexes";
import "./modal.scss";
import "./scroll_bar_style.scss";
import { ThemeMode } from "~/types";
import { classNames } from "~/utils/etc";

type ModalProps = {
  isModalOn: Accessor<boolean>;
  setIsModalOn: Setter<boolean>;
  getModalContentGetter: () => () => JSX.Element;
  updateModalContentGetter: (newGetter: () => JSX.Element) => void;
  themeMode: Accessor<ThemeMode>;
  outerFrameClass: Accessor<string>;
  setOuterFrameClass: Setter<string>;
  innerFrameClass: Accessor<string>;
  setInnerFrameClass: Setter<string>;
  showFloatingCrossIcon: Accessor<boolean>;
};

export function Modal(props: ModalProps) {
  const isModalOn = () => props.isModalOn();
  const isModalOff = () => !props.isModalOn();

  return (
    <div
      class="modal-wrapper fixed left-0 top-0 h-full w-full"
      classList={{
        dark: props.themeMode() == "dark",
        "bg-[#212427]/40 darkTheme:bg-[#0F0F10]/80 ": isModalOn(),
      }}
      style={{
        "z-index": isModalOff() ? zIndexes.default : zIndexes.modal,
        visibility: isModalOn() ? "visible" : "hidden",
      }}
      onClick={() => {
        if (isModalOn()) {
          props.setIsModalOn(false);
          props.updateModalContentGetter(() => <></>);
          props.setOuterFrameClass("");
          props.setInnerFrameClass("");
        }
      }}
    >
      <Show when={isModalOn()}>
        <div class="lg:center lg:flex lg:h-full lg:w-full lg:items-center lg:justify-center">
          <div
            class={`modal-content noScrollbar fixed bottom-0 z-20 w-full translate-y-[5rem] transform-gpu overflow-y-scroll rounded-t-2xl bg-transparent lg:relative  lg:translate-y-0 lg:rounded-b-2xl`}
            classList={{
              "h-0": isModalOff(),
              "max-h-[80%]": isModalOn(),
              "lg:max-w-[512px]": isModalOn(),
              "lg:max-h-[600px]": isModalOn(),
              [props.outerFrameClass()]: !!props.outerFrameClass(),
            }}
            style={{
              visibility: props.isModalOn() ? "visible" : "hidden",
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Show when={props.showFloatingCrossIcon()}>
              <div class="flex w-full flex-row justify-end pb-2 pt-1   ">
                <CloseModalButton onClick={() => props.setIsModalOn(false)} />
              </div>
            </Show>

            {/*<Show when={isModalOn()} fallback={<div class="h-0"></div>}>*/}
            <ModalOuterFrame
              isModalOn={props.isModalOn}
              setIsModalOn={props.setIsModalOn}
              innerFrameClass={props.innerFrameClass}
              updateModalContentGetter={props.updateModalContentGetter}
            >
              {props.getModalContentGetter()()}
            </ModalOuterFrame>
            {/*</Show>*/}
          </div>
        </div>
      </Show>
    </div>
  );
}

type ModalOuterFrameProps = {
  children: JSX.Element;
  isModalOn: Accessor<boolean>;
  setIsModalOn: Setter<boolean>;
  innerFrameClass: Accessor<string>;

  updateModalContentGetter: (newGetter: () => JSX.Element) => void;
};

function ModalOuterFrame(props: ModalOuterFrameProps) {
  return (
    <div
      class="inline-flex h-fit w-full rounded-t-3xl border border-neutral-200 bg-baseTertiaryLight p-2 darkTheme:border-baseTertiaryDark darkTheme:bg-[#121212] dark:border-basePrimaryLight dark:bg-darkBg lg:rounded-b-3xl "
      classList={{ [props.innerFrameClass()]: !!props.innerFrameClass() }}
    >
      {props.children}
    </div>
  );
}

export function CloseModalButton(props: { onClick?: () => void }) {
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        if (props.onClick) {
          props.onClick();
          return;
        }
        setIsModalOn(false);
        updateModalContentGetter(() => <></>);
      }}
    >
      <div class="flex h-8 w-8 cursor-pointer items-center  justify-center rounded-[50%] border bg-baseTertiaryLight darkTheme:border-[#555] darkTheme:bg-[#222427] dark:border-basePrimaryLight dark:bg-basePrimaryDark">
        <PhosphorIcon
          name="x"
          size="bold"
          fontSize={16}
          class="text-basePrimaryDark dark:text-baseTertiaryLight"
        />
      </div>
    </div>
  );
}

type ModalProviderProps = {
  children: JSX.Element;
};

type ModalContextValue = {
  isModalOn: Accessor<any>;
  setIsModalOn: Setter<boolean>;

  getModalContentGetter: () => () => JSX.Element;
  updateModalContentGetter: (newGetter: () => JSX.Element) => void;

  setThemeMode: Setter<ThemeMode>;
  themeMode: Accessor<ThemeMode>;

  outerFrameClass: Accessor<string>;
  setOuterFrameClass: Setter<string>;

  innerFrameClass: Accessor<string>;
  setInnerFrameClass: Setter<string>;

  showFloatingCrossIcon: Accessor<boolean>;
  setShowFloatingCrossIcon: Setter<boolean>;

  openModal: (
    newGetter: () => JSX.Element,
    outerFrameClass?: string,
    innerFrameClass?: string,
    options?: {
      showFloatingCrossIcon?: boolean;
    }
  ) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextValue>();

export function ModalProvider(props: ModalProviderProps) {
  const [themeMode, setThemeMode] = createSignal<ThemeMode>("light");
  const [isModalOn, setIsModalOn] = createSignal<boolean>(false);
  const [outerFrameClass, setOuterFrameClass] = createSignal<string>("");
  const [innerFrameClass, setInnerFrameClass] = createSignal<string>("");
  const [showFloatingCrossIcon, setShowFloatingCrossIcon] = createSignal(false);
  let modalContentGetter: () => JSX.Element = () => <></>;
  const getModalContentGetter = () => {
    return modalContentGetter;
  };
  const updateModalContentGetter = (newGetter: () => JSX.Element) => {
    modalContentGetter = newGetter;
  };

  const openModal = (
    newGetter: () => JSX.Element,
    outerFrameClass?: string,
    innerFrameClass?: string,
    options?: {
      showFloatingCrossIcon?: boolean;
    }
  ) => {
    if (outerFrameClass) setOuterFrameClass(outerFrameClass);
    if (innerFrameClass) setInnerFrameClass(innerFrameClass);
    setShowFloatingCrossIcon(options?.showFloatingCrossIcon ?? false);

    modalContentGetter = newGetter;
    setIsModalOn(true);
  };

  const closeModal = () => {
    updateModalContentGetter(() => <></>);
    setIsModalOn(false);
  };

  createEffect(() => {
    if (isModalOn()) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  });

  return (
    <ModalContext.Provider
      value={{
        isModalOn,
        setIsModalOn,
        getModalContentGetter,
        updateModalContentGetter,
        setThemeMode,
        outerFrameClass,
        setOuterFrameClass,
        innerFrameClass,
        setInnerFrameClass,
        openModal,
        closeModal,
        themeMode,
        showFloatingCrossIcon,
        setShowFloatingCrossIcon,
      }}
    >
      <div about={"for wrapping modal"} class="h-full">
        {props.children}

        <Modal
          isModalOn={isModalOn}
          setIsModalOn={setIsModalOn}
          getModalContentGetter={getModalContentGetter}
          updateModalContentGetter={updateModalContentGetter}
          themeMode={themeMode}
          outerFrameClass={outerFrameClass}
          setOuterFrameClass={setOuterFrameClass}
          innerFrameClass={innerFrameClass}
          setInnerFrameClass={setInnerFrameClass}
          showFloatingCrossIcon={showFloatingCrossIcon}
        />
      </div>
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}

type ModalInnerFrameProps = {
  children: JSX.Element;
  paddingClass?: string;
};

export function ModalInnerFrame(props: ModalInnerFrameProps) {
  return (
    <div
      class={classNames(
        "modalGradient flex h-fit w-full flex-col rounded-t-2xl border border-baseTertiaryDark darkTheme:bg-baseLight   dark:border-basePrimaryMedium dark:bg-basePrimaryDark dark:from-current dark:to-current lg:rounded-b-2xl",
        props.paddingClass ?? "p-4"
      )}
    >
      {props.children}
    </div>
  );
}
