import { Match, Switch, createSignal } from "solid-js";
import { getVoucherProduct } from "../../data/products";
import styles from "./voucher_edit.module.scss";
import { VoucherCard } from "./voucher_card";
import { ButtonRegular } from "../../widgets/button";
import {
  VoucherCart,
  VoucherCartState,
  buildDefaultVoucherCart,
  refreshCart,
} from "./voucher";
import { NumberKeyboard } from "./number_keyboard";
import { VoucherDenomination } from "./denomination";
import { ProductVariantSelection } from "./variant";
import { HubbleRoute, pushPage } from "../../shared_states/modal";
import { ResourceLoader } from "../../widgets/resource_loader";
import { classNames } from "../../utils/etc";
import { VoucherProduct } from "~/server/types/brand";
import { useClassicContext } from "~/data/classic_context";

function getClassForState(state?: VoucherCartState) {
  switch (state) {
    case VoucherCartState.valid:
      return styles.valid;
    case VoucherCartState.errored:
      return styles.errored;
    case VoucherCartState.processing:
      return styles.processing;
    default:
      return "";
  }
}

export function VoucherGenEntry(props: {
  productId: string;
  onContinue: (cart: VoucherCart, voucher: VoucherProduct) => void;
  currentCart?: VoucherCart;
  voucherProduct?: VoucherProduct;
}) {
  const { data, isClientDonationType } = useClassicContext();

  const [cart, setCart] = createSignal<VoucherCart>(
    props.currentCart || {
      voucherType: "unknown",
      voucherProductId: props.productId,
      amount: 0,
    }
  );

  const [isFinishingUp, setIsFinishingUp] = createSignal(false);

  function refreshCartForChanges() {
    if (cart().voucherType != "unknown") {
      setCart({
        ...cart(),
        cartState: VoucherCartState.processing,
        cartMessage: "Verifying the changes...",
      });

      refreshCart(cart(), false, data.isLoggedIn).then((cart) => {
        setCart(cart);
      });
    }
  }

  return (
    <ResourceLoader
      loaderColor="#fff"
      fetch={async () =>
        props.voucherProduct || getVoucherProduct(props.productId)
      }
      onAfterFetch={(voucher) => {
        setCart(() => props.currentCart || buildDefaultVoucherCart(voucher));
        refreshCartForChanges();
      }}
    >
      {(voucher) => (
        <div class={`${styles.voucherGenEntry} no-scrollbar`}>
          <div class={`${styles.voucherGenContent} no-scrollbar`}>
            <div class={styles.topPadding}>
              <VoucherCard
                product={voucher}
                class={styles.voucherCard}
                value={cart()}
                showCursor={true}
              />
              <Switch>
                <Match when={voucher.voucherProductType == "denominations"}>
                  <VoucherDenomination
                    class={styles.voucherDenomination}
                    cart={cart()}
                    onChange={(denominations) => {
                      const count = denominations.reduce(
                        (acc, denomination) => acc + denomination.quantity,
                        0
                      );
                      const displayText =
                        (count > 0 ? count : "") +
                        " " +
                        (count > 1 ? "Cards" : "Card") +
                        " worth";
                      setCart({
                        ...cart(),
                        amount: denominations.reduce(
                          (acc, denomination) =>
                            acc +
                            denomination.denomination * denomination.quantity,
                          0
                        ),
                        denominations,
                        displayText,
                        coupons: undefined,
                      });
                      refreshCartForChanges();
                    }}
                  />
                </Match>
                <Match when={voucher.voucherProductType == "variant"}>
                  <ProductVariantSelection
                    class={styles.variants}
                    cart={cart()}
                    onChange={(variant) => {
                      setCart({
                        ...cart(),
                        variantId: variant.variantId,
                        displayText: variant.description,
                        displayValue: variant.name,
                        amount: variant.amount,
                        coupons: undefined,
                      });

                      refreshCartForChanges();
                    }}
                  />
                </Match>
                <Match when={true}>
                  <NumberKeyboard
                    onChange={(value) => {
                      setCart({
                        ...cart(),
                        amount: value,
                        coupons: undefined,
                      });
                      refreshCartForChanges();
                    }}
                    value={cart().amount!}
                    class={styles.numberKeyBoard}
                  />
                </Match>
              </Switch>
            </div>
          </div>

          <div class={styles.pinnedBottom}>
            <div class={styles.overflowArea}></div>
            <div class={styles.stickyBottom}>
              <div
                class={classNames(
                  styles.bottomMessage,
                  getClassForState(cart().cartState)
                )}
              >
                <span class={styles.message}>{cart().cartMessage}&nbsp;</span>
                {/*adding empty space above so that the modal doesn't move up and down*/}
              </div>

              <ButtonRegular
                onClick={async () => {
                  setIsFinishingUp(true);
                  await refreshCart(cart(), true, data.isLoggedIn);
                  setIsFinishingUp(false);
                  props.onContinue(cart(), voucher);
                }}
                class={styles.viewDiscountButton}
                isEnabled={cart().cartState == VoucherCartState.valid}
                isLoading={isFinishingUp()}
              >
                <Switch>
                  <Match when={isClientDonationType()}>Get this card</Match>
                  <Match when={true}>View discounted price</Match>
                </Switch>
              </ButtonRegular>
            </div>
          </div>
        </div>
      )}
    </ResourceLoader>
  );
}

export function openVoucherGen({
  onContinue,
  productId,
  currentCart,
  voucherProduct,
}: {
  productId: string;
  onContinue: (cart: VoucherCart, voucher: VoucherProduct) => void;
  currentCart?: VoucherCart;
  voucherProduct?: VoucherProduct;
}) {
  pushPage(
    () => (
      <VoucherGenEntry
        productId={productId}
        onContinue={onContinue}
        currentCart={currentCart}
        voucherProduct={voucherProduct}
      />
    ),
    { isModal: true },
    HubbleRoute.VOUCHER_EDIT_MODAL
  );
}
