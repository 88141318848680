import { config } from "~/data/config";

export function routerLog(log: string) {
  if (config.consoleLogging.router == "enabled") console.log(`[router] ${log}`);
}

export function fraudChecksLog(log: string) {
  if (config.consoleLogging.fraudChecks == "enabled")
    console.log(`[fraud-checks] ${log}`);
}
