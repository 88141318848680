import { Base64 } from 'js-base64';

/**
 * Safely encodes a string to base64 that works with Unicode characters and emojis
 * @param str The string to encode
 * @returns Base64 encoded string
 */
export function encodeBase64(str: string): string {
  try {
    return Base64.encode(str);
  } catch (error) {
    console.error("Failed to encode string to base64:", error);
    return "";
  }
}

/**
 * Safely decodes a base64 string that contains Unicode characters and emojis
 * @param str The base64 string to decode
 * @returns Decoded string
 */
export function decodeBase64(str: string): string {
  if (!str) return "";

  try {
    return Base64.decode(str);
  } catch (error) {
    console.error("Failed to decode base64 string:", error);
    return "";
  }
}
