import { useNavigate } from "@solidjs/router";
import { DummyData } from "~/components/dummy";

export default function Dummy2() {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate("/buy-gift-card/uber");
      }}
    >
      <DummyData />
    </div>
  );
}
