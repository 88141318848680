import { A, cache, createAsync, useNavigate } from "@solidjs/router";
import { Accessor, createSignal, Show, Suspense } from "solid-js";
import { config } from "~/data/config";
import {
  downloadSquidOrder,
  sendInvoiceOverEmail,
} from "~/server/apis/client_apis";
import {
  getSquidPurchaseRouteData,
  SquidPurchaseRouteData,
} from "~/server/data/squid_purchase_route_data";
import { showSnackBar } from "~/shared_states/snackbar";
import { toIndianNumber } from "~/utils/number";
import { EmptyState } from "~/widgets/empty_state";
import { PhosphorIcon } from "~/widgets/icons";
import { CircularLoader, DottedLoader } from "~/widgets/loader";
import { Tooltip } from "~/widgets/tooltip";

const getSquidPurchaseRouteData$C = cache(
  getSquidPurchaseRouteData,
  "squid_purchase"
);

export default function Purchases() {
  const routeData: Accessor<SquidPurchaseRouteData | undefined> =
    createAsync<SquidPurchaseRouteData>(() => {
      return getSquidPurchaseRouteData$C();
    });

  const [loading, setLoading] = createSignal(false);
  const [activeRow, setActiveRow] = createSignal<string>("");
  const navigator = useNavigate();

  const downloadOrder = async (orderId: string) => {
    setLoading(true);
    try {
      const response = await downloadSquidOrder(orderId);
      if (response) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `hubble_procurement_order_${orderId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the order:");
      }
    } catch (error) {
      console.error("An error occurred while downloading the order:", error);
    } finally {
      setLoading(false);
    }
  };

  const [sendingEmail, setSendingEmail] = createSignal<string[]>([]);

  const sendInvoiceEmail = async (props: { purchaseId: string }) => {
    setSendingEmail((prev) => [...prev, props.purchaseId]);
    try {
      await sendInvoiceOverEmail({ purchaseId: props.purchaseId });
      showSnackBar({
        level: "success",
        message: "email sent to your registered email-id",
      });
    } catch (e: any) {
      showSnackBar({
        level: "error",
        message: e.message,
      });
    }
    setSendingEmail((prev) => prev.filter((id) => id !== props.purchaseId));
  };

  return (
    <Suspense
      fallback={
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" from="squid purchases suspense" />
        </div>
      }
    >
      <div class="flex-1 items-center justify-center">
        <div class="sticky z-10 w-full border border-baseTertiaryMedium bg-white px-5 py-3 text-h5 text-textDark">
          {" "}
          My purchases
        </div>
        {/* <Spacer height={54} /> */}
        <Show
          when={
            routeData()?.purchaseOrders?.data &&
            routeData()!.purchaseOrders?.data.length > 0
          }
          fallback={emptyState()}
        >
          {PurchaseTable()}
        </Show>
      </div>
    </Suspense>
  );

  function PurchaseTable() {
    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 z-10 rounded-full  bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  COUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  NOTES
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
                <th class="rounded-tr-lg px-3  py-2 text-left text-smallBold text-textDark">
                  CSV
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.purchaseOrders.data.map((purchase) => (
                <tr
                  class={` text-medium last:rounded-b-lg  hover:cursor-pointer hover:bg-baseTertiaryMedium   ${purchase.status === "FAILED" ? "text-textNormal" : "text-textDark"}`}
                  onClick={() => {
                    if (
                      purchase.status === "FULFILLED" ||
                      purchase.status === "PARTIALLY_FULFILLED"
                    ) {
                      navigator("/purchases/" + purchase.id, {
                        state: {
                          fromPurchasesPage: true,
                        },
                      });
                    } else {
                      navigator("/order/" + purchase.id, {
                        state: {
                          fromPurchasesPage: true,
                        },
                      });
                    }
                  }}
                  onMouseEnter={() => {
                    setActiveRow(purchase.id);
                  }}
                  onMouseLeave={() => {
                    setActiveRow("");
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    {new Date(purchase.transactedAt).toLocaleDateString(
                      "en-IN",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    ₹{toIndianNumber(purchase.totalAmount)}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {purchase.voucherCount}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    <div class=" w-full">
                      <Show
                        when={purchase.notes && purchase.notes?.length > 0}
                        fallback={<div>{"-"}</div>}
                      >
                        <Tooltip
                          parent={
                            <div class=" max-w-[132px] overflow-hidden text-ellipsis">
                              {purchase.notes}
                            </div>
                          }
                          tooltip={
                            <div class="mt-2 rounded bg-black px-2 py-1 text-textWhite">
                              {purchase.notes}
                            </div>
                          }
                        />
                      </Show>
                    </div>
                  </td>

                  <td class=" whitespace-nowrap border-r p-3">
                    <div class="flex items-center justify-center">
                      <div class="grow">
                        {purchase.brands.length > 4
                          ? purchase.brands.slice(0, 4).join(", ").toString() +
                            " +" +
                            (purchase.brands.length - 4) +
                            " more"
                          : purchase.brands.join(", ")}
                      </div>
                      <Show
                        when={
                          activeRow() === purchase.id &&
                          (purchase.status === "FULFILLED" ||
                            purchase.status === "PARTIALLY_FULFILLED")
                        }
                      >
                        <div class="flex gap-2">
                          <Tooltip
                            parent={
                              <div
                                class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (sendingEmail().includes(purchase.id)) {
                                    return;
                                  }
                                  sendInvoiceEmail({
                                    purchaseId: purchase.id,
                                  });
                                }}
                              >
                                <Show
                                  when={sendingEmail().includes(purchase.id)}
                                  fallback={
                                    <PhosphorIcon
                                      name="envelope"
                                      fontSize={20}
                                    />
                                  }
                                >
                                  <CircularLoader size={18} />
                                </Show>
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                Send invoice over mail
                              </div>
                            }
                          />
                          <Tooltip
                            parent={
                              <A
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                href={
                                  config.receiptUrl + "/invoice/" + purchase.id
                                }
                                target="_blank"
                              >
                                <div class="flex h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white">
                                  <PhosphorIcon name="file-pdf" fontSize={20} />
                                </div>
                              </A>
                            }
                            tooltip={
                              <div class="tooltip mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                View invoice
                              </div>
                            }
                          />
                        </div>
                      </Show>
                    </div>
                  </td>
                  <td class="whitespace-nowrap p-3 ">
                    <div
                      class="cursor-pointer p-1"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (purchase.status === "FULFILLED" && !loading()) {
                          downloadOrder(purchase.id);
                        }
                      }}
                    >
                      <PhosphorIcon
                        name={
                          purchase.status === "PROCESSING"
                            ? "circle-notch"
                            : purchase.status === "FAILED"
                              ? "warning"
                              : "cloud-arrow-down"
                        }
                        fontSize={20}
                        class={`${purchase.status === "FAILED" ? "text-errorDark" : "text-baseSecondaryLight hover:text-black"}`}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Gifts cards you purchase will appear here"
        secondaryText="No gift cards purchased yet"
      />
    );
  }
}
