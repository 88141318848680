import {
  Accessor,
  createEffect,
  createSignal,
  For,
  JSX,
  Match,
  Show,
  Switch,
} from "solid-js";
import { LoginRNRRouteData } from "~/server/data/login_rnr_route_data";
import { AxisHeader } from "./axis_header";
import { BorderGradient } from "../gifting/gift_card";
import { Spacer } from "~/widgets/spacer";
import { DottedLoader } from "~/widgets/loader";
import { createJob } from "~/utils/job";
import { axisFloorBgDesktop } from "~/assets/assets";
import AxisHubbleBranding from "./axis_hubble_branding";
import { ModalInnerFrame, useModal } from "../modal";
import { PhosphorIcon } from "~/widgets/icons";
import { verifyPartnerCode } from "~/server/apis/client_apis";
import { VerifyCodeResponse } from "~/server/types/brand";
import { setCookie } from "~/utils/client_cookie";
import { Cookie, Mode } from "~/types";
import { useIsRouting, useNavigate } from "@solidjs/router";
import { setIsFreshLogin } from "../brand_l2/header";
import { config } from "~/data/config";

export default function LoginRnrAxisComponent(props: {
  routeData: Accessor<LoginRNRRouteData | undefined>;
}) {
  const [isFieldActive, setIsFieldActive] = createSignal(false);
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>("");
  const [code, setCode] = createSignal<string>("");

  const [isDropdownOpen, setIsDropdownOpen] = createSignal<boolean>(false);
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  const navigate = useNavigate();
  const isRouting = useIsRouting();

  let phoneInput: HTMLInputElement | undefined;
  createEffect(() => {
    phoneInput?.focus();
  });

  createEffect(() => {
    code();
    setErrorMessage(undefined);
  });

  const submitJob = createJob<VerifyCodeResponse>({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    initialJob: async () => {
      const verifyCodeResponse = await verifyPartnerCode(
        {
          clientId: props.routeData()?.clientId ?? "",
          clientSecret: props.routeData()?.clientSecret ?? "",
        },
        {
          code: code(),
        }
      );

      if (verifyCodeResponse.sessionId) {
        setCookie({
          key: Cookie.SessionId,
          value: verifyCodeResponse.sessionId,
          expiryInDays: config.sesssionDurationInMinutes.axis_portal,
        });
        setIsFreshLogin(true);
        navigate("/");
        return verifyCodeResponse;
      } else {
        throw new Error(
          "An unexpected error occurred. Please try again later."
        );
      }
    },
  });

  return (
    <>
      <div class=" z-10 flex h-full flex-col items-center justify-start">
        <AxisHeader
          isLoggedIn={() => false}
          mode={props.routeData()?.mode ?? "rnr"}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
        <div class="mt-[128px] sm:mt-[144px]"></div>
        <span class="text-normal text-textNormal">
          👋 Welcome to Axis rewards portal
        </span>
        <div class="mt-3 sm:mt-4 "></div>
        <span class="max-w-[351px] text-center font-jakartaSans text-h2 font-medium sm:max-w-[618px] sm:text-[52px]">
          We’re grateful to have you as a customer
        </span>
        <div class="mt-12 sm:mt-14"></div>
        {buildLoginForm()}
        <div class="mt-[29px] sm:mt-6"></div>
        <span
          onClick={() => {
            updateModalContentGetter(() => <HowItWorks />);
            setIsModalOn(true);
          }}
          class="cursor-pointer text-subtitleSmall text-textNormal underline-offset-2"
        >
          <u>How it works?</u>
        </span>
        <div class="absolute bottom-[10px] ">
          <AxisHubbleBranding />
        </div>
      </div>
      <div
        class="fixed bottom-0 -z-10 flex h-[200px] w-full sm:h-[338px] "
        style={`
           background-image: url(${axisFloorBgDesktop});
          background-size: 100% 100%;
          `}
      ></div>
    </>
  );

  function buildLoginForm() {
    return (
      <div class="w-full px-4 sm:w-min sm:px-0">
        <BorderGradient>
          <div class="flex w-full flex-col items-center justify-center rounded-2xl border border-[#333232]  bg-baseLight p-4 sm:w-[412px] sm:p-6">
            <span class="text-nowrap text-f12Bold uppercase text-textNormal">
              Enter code to unlock rewards
            </span>
            <Spacer height={20} />
            {buildInputField()}
            <Spacer height={20} />
            <ButtonDarkV2
              isLoading={submitJob.jobState() === "running" || isRouting()}
              onClick={() => {
                if (submitJob.jobState() == "running") return;
                submitJob.run();
              }}
            >
              Redeem
            </ButtonDarkV2>
          </div>
        </BorderGradient>
      </div>
    );
  }

  function buildInputField() {
    return (
      <div class="flex w-full flex-col">
        <div
          class="w-full rounded-xl border bg-baseTertiaryDark px-4 py-[13px] transition-colors"
          classList={{
            "border-textDark": isFieldActive(),
            "border-[#555]": !isFieldActive(),
            "border-errorDark": !!errorMessage(),
          }}
        >
          <form
            class="text flex text-bold text-textDark"
            onSubmit={(e) => {
              e.preventDefault();
              submitJob.run();
            }}
          >
            <input
              class="flex-1 bg-transparent text-textDark placeholder:text-normal placeholder:text-textNormal"
              ref={phoneInput}
              placeholder="16 digit code"
              value={code()}
              onInput={(e) => setCode(e.currentTarget.value)}
              maxlength={30}
              onFocus={() => setIsFieldActive(true)}
              onBlur={() => setIsFieldActive(false)}
            />
          </form>
        </div>
        <Show when={errorMessage()}>
          <Spacer height={4} />
          <span class="text-medium  text-errorDark">{errorMessage()}</span>
        </Show>
      </div>
    );
  }
}

function HowItWorks() {
  const howHubbleCodesWork = [
    "Enter the code & click “Redeem”",
    "You’ll receive coins in wallet. 1 coin = ₹1",
    "Get the gift card of your choice using coins",
  ];

  return (
    <ModalInnerFrame>
      <div class="flex w-full flex-row  items-start justify-between ">
        <span class="text-nowrap text-h4 text-basePrimaryDark">
          How it works?
        </span>
        <CloseModalButton />
      </div>
      <div class="mt-5"></div>
      <ol class="flex flex-col gap-2">
        <For each={howHubbleCodesWork}>
          {(text, index) => (
            <li class="items flex flex-row items-center justify-start gap-2 text-baseSecondaryDark">
              <div class="flex h-[18px] w-[18px] items-center justify-center rounded-full  bg-baseSecondaryLight text-[10px] font-bold">
                {index() + 1}
              </div>{" "}
              <span class="text-[15px] font-normal ">{text}</span>
            </li>
          )}
        </For>
      </ol>
    </ModalInnerFrame>
  );
}

export function CloseModalButton(props: { onClick?: () => void }) {
  const { setIsModalOn, updateModalContentGetter } = useModal()!;

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        if (props.onClick) {
          props.onClick();
          return;
        }
        setIsModalOn(false);
        updateModalContentGetter(() => <></>);
      }}
    >
      <div class="flex h-8 w-8 cursor-pointer  items-center justify-center rounded-[50%] border border-[#555] bg-[#222427] dark:border-basePrimaryLight dark:bg-basePrimaryDark">
        <PhosphorIcon
          name="x"
          size="bold"
          fontSize={16}
          class="text-basePrimaryDark dark:text-baseTertiaryLight"
        />
      </div>
    </div>
  );
}

export function ButtonDarkV2(props: {
  isLoading: boolean;
  onClick: () => void;
  children: JSX.Element;
}) {
  return (
    <div
      onClick={props.onClick}
      class="flex h-[48px] w-full cursor-pointer items-center justify-center rounded-full bg-basePrimaryDark  text-[14px] font-semibold text-baseTertiaryDark  sm:text-f16Bold"
    >
      <Switch>
        <Match when={props.isLoading}>
          <DottedLoader color="#000" />
        </Match>
        <Match when={true}>{props.children}</Match>
      </Switch>
    </div>
  );
}
