"use server";

import { getProformaInvoiceDetail } from "../apis/server_apis";
import { SquidProformaInvoiceDetail } from "../types/order";

export type ProcurementProformaInvoiceDetailRouteDataProps = {
  proformaInvoiceId: string;
};

export const getSquidProformaInvoiceDetailRouteData = async (
  props: ProcurementProformaInvoiceDetailRouteDataProps
): Promise<SquidProformaInvoiceDetail> => {
  const invoiceDetail = await getProformaInvoiceDetail(props.proformaInvoiceId);
  return invoiceDetail;
};
