import { FetchOptions, Header } from "~/types";

function getRandomRequestId(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export async function hubbleFetch(
  url: string,
  method: string,
  headers?: HeadersInit,
  body?: any,
  options?: FetchOptions
): Promise<Response> {
  try {
    let completeHeaders: HeadersInit = {
      [Header.ContentType]: "application/json",
      [Header.AppVersion]: "10000",
      [Header.RequestId]: getRandomRequestId(),
      ...headers,
    };

    let response;
    if (method == "GET") {
      response = await fetch(url, {
        method,
        headers: completeHeaders,
      });
    } else {
      response = await fetch(url, {
        method,
        body: JSON.stringify(body ?? {}),
        headers: completeHeaders,
      });
    }

    return response;
  } catch (e) {
    throw e;
  }
}
