import { setCookie } from "~/utils/client_cookie";

import {
  generatePartnerOTP,
  verifyPartnerOTP,
} from "~/server/apis/client_apis";
import { config } from "~/data/config";

export default class SmartGreetingAuth {
  smart_greeting_client_id = "smart-greeting-curation";
  smart_greeting_client_secret = "2tHXf1AzwSs3";

  sid_key = "sid";

  async verifyOtp(props: {
    phoneNumber: string;
    otp: string;
    otpToken: string;
  }) {
    return await verifyPartnerOTP(
      {
        clientId: this.smart_greeting_client_id,
        clientSecret: this.smart_greeting_client_secret,
      },
      {
        phoneNumber: props.phoneNumber,
        otp: props.otp,
        otpToken: props.otpToken,
      },
      {
        isUnauthenticated: true,
      }
    );
  }

  async sendOtp(phoneNumber: string) {
    return await generatePartnerOTP(
      {
        clientId: this.smart_greeting_client_id,
        clientSecret: this.smart_greeting_client_secret,
      },
      {
        phoneNumber: phoneNumber,
      },
      {
        isUnauthenticated: true,
      }
    );
  }

  setSessionId(sid: string) {
    setCookie({
      key: this.sid_key,
      value: sid,
      expiryInMinutes: config.sesssionDurationInMinutes.smart_greeting,
    });
  }
}
