import { Meta, Title } from "@solidjs/meta";
import { useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { createSignal, Match, onMount, Show, Switch } from "solid-js";
import {
  createdUsingPlutoBranding,
  pluto_reveal_meta_image,
} from "~/assets/assets";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import {
  getPlutoRevealLandingRouteData,
  PlutoRevealLandingRouteData,
} from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_route_data";
import { createJob } from "~/utils/job";
import attachPopStateListener from "~/utils/popstate_listener";
import { ThreeDotLoader } from "~/widgets/button";
import VerticalDragReveal from "~/components/vertical_drag_reveal";
import { GiftCardComponent } from "~/components/gifting/pluto-receiver/pluto-receiver-landing/gift_card_component";
import { GiftCardDetails, PaymentDetails } from "~/server/types/pluto";
import { GiftBoxMetadata } from "~/server/types/gift";
import { Optional } from "~/types";

export default function PlutoRevealLandingRoute() {
  const params = useParams();
  const giftingKey = params.giftingKey as string;
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>();

  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  const amount = Number(queryParams.amount ?? "5000");

  const getGiftBoxDataJob = createJob<PlutoRevealLandingRouteData>({
    initialJob: async () => {
      return await getPlutoRevealLandingRouteData({
        giftingKey: giftingKey,
        isPreview: isPreview,
      });
    },
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
    },
  });

  onMount(() => {
    attachPopStateListener();
    getGiftBoxDataJob.run();
  });

  const navigate = useNavigate();

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <MetaTags />
      <Switch>
        <Match
          when={
            getGiftBoxDataJob.jobState() == "success" &&
            getGiftBoxDataJob.jobResult() != null
          }
        >
          <VerticalDragReveal
            greetingMessage={`${getGiftBoxDataJob.jobResult()!.giftBoxMetadata.plutoGiftDetails?.occasion.recipientName} 👋`}
            senderName={
              getGiftBoxDataJob.jobResult()!.giftBoxMetadata?.plutoGiftDetails
                ?.senderName
            }
            brandingImageSrc={createdUsingPlutoBranding}
          >
            <GiftCardComponent
              giftDetailsAccessor={() => getGiftCardDetails()!}
              giftingKey={giftingKey}
              isRtu={getGiftBoxDataJob.jobResult()!.isRtu}
              sessionId={getGiftBoxDataJob.jobResult()!.sessionId}
              giftBoxMetadata={getGiftBoxDataJob.jobResult()!.giftBoxMetadata}
              isPreview={isPreview}
              navigateToDetailsRoute={() => {
                let link = `/pluto/reveal/${giftingKey}/gift-details`;
                if (isPreview) {
                  let amt =
                    getGiftBoxDataJob.jobResult()!.giftBoxMetadata
                      .plutoGiftDetails?.amount ?? amount;
                  link = `${link}?isPreview=true&amount=${amt}`;
                }
                navigate(link);
              }}
            />
          </VerticalDragReveal>
        </Match>
        <Match when={getGiftBoxDataJob.jobState() == "error"}>
          <div class="text-center">{errorMessage()}</div>
        </Match>
        <Match
          when={
            getGiftBoxDataJob.jobState() == "running" ||
            getGiftBoxDataJob.jobState() == "idle"
          }
        >
          <div class=" flex h-full w-full flex-col items-center justify-center bg-baseDark">
            <ThreeDotLoader color="#fff" />
          </div>
        </Match>
      </Switch>
    </>
  );

  function MetaTags() {
    return (
      <>
        <Title>{`A gift for you`}</Title>
        <Meta name="title" content={`A gift for you`} />
        <Meta name="image" content={pluto_reveal_meta_image} />
      </>
    );
  }

  function getActivitySlots() {
    let activities =
      getGiftBoxDataJob.jobResult()!.giftBoxMetadata?.plutoGiftDetails
        ?.activities ?? {};

    return Object.values(activities).map((activity) => {
      return {
        id: activity.id,
        activities: [activity],
      };
    });
  }

  function getGiftCardDetails(): Optional<GiftCardDetails> {
    let giftBoxMetadata = getGiftBoxDataJob.jobResult()!.giftBoxMetadata;
    let giftBoxGiftDetails =
      getGiftBoxDataJob.jobResult()!.giftBoxMetadata.plutoGiftDetails;

    if (!giftBoxGiftDetails) {
      return null;
    }

    return {
      id: "1",
      paymentDetails: {} as PaymentDetails,
      conversationId: "1",
      receiversPreviewUrl: "",
      senderName: giftBoxGiftDetails.senderName,
      generatedOn: "2023-06-01",
      paymentStatus: "PAID",
      title: giftBoxGiftDetails.title.title,
      brandDetails: giftBoxGiftDetails.brandDetails,
      status: "COMPLETED",
      budget: giftBoxMetadata.amount,
      content: {
        text: {
          occasion: giftBoxGiftDetails.occasion,
          titles: [giftBoxGiftDetails.title],
          activitySlots: getActivitySlots(),
        },
        images: {
          occasionImages: [giftBoxGiftDetails.occasionImage],
          activityImages: [giftBoxGiftDetails.activityImage],
        },
        selection: {
          titleId: giftBoxGiftDetails.title.id,
          occasionImageId: giftBoxGiftDetails.occasionImage.id,
          activities: {},
          activityImageId: giftBoxGiftDetails.activityImage.id,
        },
      },
    } as GiftCardDetails;
  }
}
