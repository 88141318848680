import { Accessor, createEffect, createSignal, JSX } from "solid-js";
import {
  appstore_badge_dark,
  GiftFromHubbleIcon,
  hubbleAppNudgeHelperImage,
  playstore_badge_dark,
} from "~/assets/assets";
import GradientText from "~/widgets/gradient_text";
import { classNames } from "~/utils/etc";
import CopyIcon from "~/widgets/copy_icon";
import HubbleImage from "~/widgets/hubble_image";
import { CloseModalButton, useModal } from "./modal";
import {
  APP_STORE_HUBBLE_APP_URL,
  PLAYSTORE_HUBBLE_APP_URL,
} from "~/data/consts";
import { A } from "@solidjs/router";
import { showSnackBar } from "~/shared_states/snackbar";
import { PhosphorIcon } from "~/widgets/icons";
import { toIndianNumber } from "~/utils/number";

export type HubbleAppNudgeCtaProps = {
  code: string;
  amount: number;
  startBlinkAnimation: Accessor<boolean>;
  isDarkMode: boolean;
};

export default function HubbleAppNudgeCta(props: HubbleAppNudgeCtaProps) {
  const { openModal, setThemeMode } = useModal()!;

  const [borderOpacity, setBorderOpacity] = createSignal(false);

  function delay() {
    return new Promise((resolve) => setTimeout(resolve, 500));
  }

  createEffect(() => {
    if (props.startBlinkAnimation()) {
      startAnimation();
    }
  });

  async function startAnimation() {
    setBorderOpacity(true);
    await delay();
    setBorderOpacity(false);
    await delay();
    setBorderOpacity(true);
    await delay();
    setBorderOpacity(false);
  }

  return (
    <div
      class="relative h-[64px] w-full cursor-pointer overflow-clip rounded-[14px]"
      onClick={() => {
        if (props.isDarkMode) {
          setThemeMode("dark");
        }
        openModal(
          () => {
            return (
              <HubbleAppGiftRedemptionSteps
                code={props.code}
                amount={props.amount}
              />
            );
          },
          undefined,
          "border-baseTertiaryDark dark:border-basePrimaryDark"
        );
      }}
    >
      <div
        class="absolute inset-0 z-0 bg-[linear-gradient(90deg,#DCE1FF_0%,#EFC6DB_100%)] transition-all duration-500 dark:bg-[linear-gradient(90deg,#5E4124_0%,#562765_100%)]"
        classList={{
          "opacity-0": borderOpacity(),
          "opacity-100": !borderOpacity(),
        }}
      ></div>
      <div
        class="absolute inset-1 z-10 flex  flex-row items-center rounded-[10px] bg-baseTertiaryLight py-3 dark:border dark:border-basePrimaryMedium dark:bg-black"
        style={{
          "box-shadow": "0px 0px 4px 0px rgba(0, 0, 0, 0.30)",
        }}
      >
        <img
          class="ml-[8px] mr-[6px] h-[32px] w-[32px]"
          src={GiftFromHubbleIcon}
          alt=""
        />
        <div class="flex flex-1 flex-col items-start gap-[2px]">
          <span class="text-[10px] font-bold tracking-[0.8px]">
            <div class="hidden dark:block">
              <GradientText gradient="linear-gradient(90deg, #D3792F 0%, #F24DEC 100%)">
                CHERRY ON TOP
              </GradientText>
            </div>
            <div class="dark:hidden">
              <GradientText gradient="linear-gradient(90deg, #5548FC 0%, #E72588 100%)">
                CHERRY ON TOP
              </GradientText>
            </div>
          </span>
          <span class="line-clamp-1 text-ellipsis text-mediumBold text-textDark dark:text-white">
            ₹{toIndianNumber(props.amount)} gift from Hubble.{" "}
            <u class="underline-offset-2">Avail now</u>
          </span>
        </div>
        <div class="mr-1">
          <PhosphorIcon
            name="caret-right"
            size="bold"
            fontSize={13}
            class="px-2 text-[#7C7C7C]"
          />
        </div>
      </div>
    </div>
  );
}

export type HubbleAppGiftRedemptionStepsProps = {
  code: string;
  amount: number;
};

function HubbleAppGiftRedemptionSteps(
  props: HubbleAppGiftRedemptionStepsProps
) {
  let bgClass = "bg-white dark:bg-black";
  const { themeMode } = useModal()!;

  return (
    <div
      class={classNames(
        "relative flex flex-col rounded-2xl border border-baseTertiaryDark dark:border-basePrimaryMedium dark:text-white",
        bgClass
      )}
    >
      <div class="absolute right-4 top-4 sm:right-4 sm:top-4">
        <CloseModalButton />
      </div>

      <div class="flex flex-col items-start p-4">
        <img class="h-[60px] w-[60px]" src={GiftFromHubbleIcon} alt="" />
        <div class="mt-3"></div>
        <span class="text-[12px] font-bold tracking-[0.96px]">
          <div
            classList={{
              hidden: themeMode() == "dark",
            }}
          >
            <GradientText gradient="linear-gradient(90deg, #5548FC 0%, #E72588 100%)">
              GIFT FROM HUBBLE
            </GradientText>
          </div>
          <div
            classList={{
              hidden: themeMode() != "dark",
            }}
          >
            <GradientText gradient="linear-gradient(90deg, #D3792F 0%, #F24DEC 100%)">
              GIFT FROM HUBBLE
            </GradientText>
          </div>
        </span>
        <div class="mt-1"></div>
        <span class="text-h5 sm:text-h3 ">
          Get extra ₹{toIndianNumber(props.amount)} off on your first gift card
          with Hubble
        </span>
        <div class="mt-2"></div>
        <Code />
      </div>
      <div class="h-[1px] w-full bg-baseTertiaryDark dark:bg-basePrimaryMedium"></div>
      <div class="flex flex-col p-4">
        <Step
          number={1}
          contentBuiler={() => (
            <div class="flex flex-col">
              <span>
                Download
                <span class="font-semibold"> Hubble: Shopping discounts </span>
                app from Playstore or Appstore
              </span>
              <div class="mt-2"></div>
              <DownloadAppCta />
            </div>
          )}
        />
        <div class="mt-6"></div>
        <Step
          number={2}
          contentBuiler={() => (
            <div class="flex flex-col">
              <span>
                Choose a brand and apply code during checkout to get an
                additional ₹{toIndianNumber(props.amount)} off.
              </span>
              <div class="mt-2"></div>
              <HelperImage />
            </div>
          )}
        />
      </div>
    </div>
  );

  function Code() {
    const { themeMode } = useModal()!;
    return (
      <div
        onClick={() => {
          navigator.clipboard.writeText(props.code);
          showSnackBar({
            message: "Code copied to clipboard",
            level: "success",
          });
        }}
        class="relative flex h-11 w-full cursor-pointer items-center justify-center overflow-clip rounded-[5px]"
        style={
          themeMode() == "dark"
            ? {
                background:
                  "linear-gradient(90deg, #5E4124 0%, #562765 100%), linear-gradient(90deg, #DCE1FF 0%, #EFC6DB 100%), #EDEEF1",
              }
            : {
                background:
                  "linear-gradient(90deg, #DCE1FF 0%, #EFC6DB 100%), #EDEEF1",
              }
        }
      >
        <span class="!text-[15px] text-h5 text-textDark dark:text-white sm:!text-[19px]">
          Use code: {props.code}
        </span>
        <div class="w-2"></div>
        <CopyIcon />
        <div class="absolute left-[-8px]">
          <Circle />
        </div>
        <div class="absolute right-[-8px]">
          <Circle />
        </div>
      </div>
    );

    function Circle() {
      return <div class={classNames("h-4 w-4 rounded-full", bgClass)}></div>;
    }
  }

  function Step(props: { number: number; contentBuiler: () => JSX.Element }) {
    return (
      <div class="flex flex-row justify-start">
        <div class="flex h-5 w-5 items-center justify-center rounded-full bg-baseTertiaryMedium text-f12Bold dark:bg-basePrimaryMedium">
          <span>{props.number}</span>
        </div>
        <div class="w-2"></div>
        <div class="flex-1 text-normal text-textDark dark:text-white ">
          {props.contentBuiler()}
        </div>
      </div>
    );
  }

  function DownloadAppCta() {
    return (
      <div class="flex flex-row justify-start">
        <A href={PLAYSTORE_HUBBLE_APP_URL} target="_blank">
          <img src={playstore_badge_dark} class="h-8 cursor-pointer" alt="" />
        </A>
        <div class="w-3"></div>
        <A href={APP_STORE_HUBBLE_APP_URL} target="_blank">
          <img src={appstore_badge_dark} class="h-8 cursor-pointer" alt="" />
        </A>
      </div>
    );
  }

  function HelperImage() {
    return (
      <HubbleImage
        alt="apply-code"
        class="aspect-[42/30] w-full"
        src={hubbleAppNudgeHelperImage}
      ></HubbleImage>
    );
  }
}
