"use server";

import { redirect } from "@solidjs/router";
import { getGiftBoxMetadata } from "../apis/server_apis";
import { GiftBoxMetadata } from "../types/gift";

export const getGiftBoxRedirectionRouteData = async (
  giftId: string
): Promise<GiftBoxMetadata> => {
  const result = await getGiftBoxMetadata(giftId, undefined, {
    isUnauthenticated: true,
  });

  if (result.type == "SMART_GREETING_CARD") {
    if (result.status == "NOT_ACTIVATED") {
      throw redirect(`/smart-gc/create/${giftId}`);
    } else {
      // will need to change
      throw redirect(`/smart-gc/reveal/${giftId}`);
    }
  } else if (result.type === "PLUTO_CURATED") {
    throw redirect(`/pluto/reveal/${giftId}`);
  } else if (result.type === "MULTI_BRAND") {
    throw redirect(`/gift-box/m/${giftId}`);
  } else {
    throw redirect(`/gift-box/s/${giftId}`);
  }
};
