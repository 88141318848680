import { BrandL2RouteData } from "~/server/data/brand_l2_route_data";
import { Meta } from "@solidjs/meta";

import { config } from "~/data/config";
import { getCategorySlugFromCategoryName } from "~/utils/common";
import { formatDateNumeric } from "~/utils/date";
import { getSimulateTransactionDetails } from "~/utils/brandl2";

function getSchemaStringified(
  routeData: () => BrandL2RouteData | undefined
): string {
  const generateRandomRating = () => (Math.random() * 0.5 + 4.5).toFixed(1);
  const generateRandomCount = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const ratingValue = generateRandomRating();
  const ratingCount = generateRandomCount(15, 40);
  const reviewCount = generateRandomCount(15, 40);

  const { brand, brandExtraMetadata, metadataSEOSchema } = routeData()!;

  let validity = "1 year after purchase";
  if (brandExtraMetadata.voucherHighlights.includes("VALID_FOR_NINE_MONTHS"))
    validity = "9 months after purchase";
  else if (
    brandExtraMetadata.voucherHighlights.includes("VALID_FOR_SIX_MONTHS")
  )
    validity = "6 months after purchase";
  else if (
    brandExtraMetadata.voucherHighlights.includes("VALID_FOR_THREE_MONTHS")
  )
    validity = "3 months after purchase";

  let addlFeatures = [];
  if (brandExtraMetadata.voucherHighlights.includes("MULTIPLE_TIMES"))
    addlFeatures.push("Can be used multiple times till balance is utilized");
  if (brandExtraMetadata.voucherHighlights.includes("CARDS_CAN_BE_CLUBBED"))
    addlFeatures.push("Multiple cards can be clubbed in one purchase");
  if (brandExtraMetadata.voucherHighlights.includes("CLUB_WITH_OFFERS"))
    addlFeatures.push("Brand offers can be clubbed");

  let usage = "";
  if (brandExtraMetadata.voucherHighlights.includes("ONLINE"))
    usage = `Use to pay online on ${brand.voucherProductMetadata.title}'s app or website`;
  else if (brandExtraMetadata.voucherHighlights.includes("OFFLINE"))
    usage = "Use to pay in-store";
  else if (brandExtraMetadata.voucherHighlights.includes("ONLINE_OFFLINE"))
    usage = "Use to online or in-store";

  let faqObjs = brandExtraMetadata.faqs.map(({ question, answer }) => {
    return {
      "@type": "Question",
      name: question,
      acceptedAnswer: {
        "@type": "Answer",
        text: answer,
      },
    };
  });

  const seoSchemaForBrand = metadataSEOSchema?.[brand.brandKey];

  const title = brandExtraMetadata.categoryTitle
    ? brandExtraMetadata.categoryTitle + " Gift Cards"
    : "Shop";

  return JSON.stringify({
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Product",
        name: `${brand.voucherProductMetadata.title} ${brand.amountConditions.minAmount} INR Gift Card`,
        url: `https://offers.myhubble.money/buy-gift-card/${brand.brandKey}`,
        ...(seoSchemaForBrand?.alternateName && {
          alternateName: seoSchemaForBrand.alternateName,
        }),
        description: brandExtraMetadata.voucherDescription,
        image: brand.voucherProductMetadata.logoUrl,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: ratingValue,
          ratingCount: ratingCount.toString(),
          reviewCount: reviewCount.toString(),
        },
        brand: {
          "@type": "Brand",
          name: brand.voucherProductMetadata.title,
          description: brandExtraMetadata.brandDescription,
          logo: {
            "@type": "ImageObject",
            name: `${brand.voucherProductMetadata.title} Logo`,
            url: brand.voucherProductMetadata.logoUrl,
          },
          ...(seoSchemaForBrand?.sameAs && {
            sameAs: seoSchemaForBrand.sameAs,
          }),
        },
        offers: {
          "@type": "Offer",
          price: `${getSimulateTransactionDetails(routeData)?.dueAmount}`,
          priceCurrency: "INR",
          priceValidUntil: "2025-01-01",
          itemCondition: "https://schema.org/NewCondition",
          availability: "https://schema.org/InStock",
          eligibleQuantity: {
            "@type": "QuantitativeValue",
            value: 1,
          },
          description: `This product is currently available at a ${getSimulateTransactionDetails(routeData)?.discountDetails.totalDiscount.percentage}% discount.`,
          eligibleRegion: {
            "@type": "Place",
            name: "India",
          },
          seller: {
            "@type": "Organization",
            name: "Hubble",
            sameAs: [
              "https://offers.myhubble.money/",
              "https://www.linkedin.com/company/hubblemoney",
              "https://www.instagram.com/hubblemoney/",
              "https://x.com/hubblemoney",
            ],
          },
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingDestination: {
              "@type": "DefinedRegion",
              addressCountry: "IN",
            },
            doesNotShip: true,
          },
          hasMerchantReturnPolicy: {
            "@type": "MerchantReturnPolicy",
            returnPolicyCategory: "https://schema.org/NonRefundable",
          },
        },
        additionalProperty: addlFeatures,
        ...(seoSchemaForBrand?.keywords && {
          keywords: seoSchemaForBrand.keywords,
        }),
        additionalType: [
          "http://productontology.org/id/Gift_card",
          "https://en.wikipedia.org/wiki/Gift_card",
          "https://www.wikidata.org/wiki/Q554910",
        ],
        ...(seoSchemaForBrand?.disambiguatingDescription && {
          disambiguatingDescription:
            seoSchemaForBrand.disambiguatingDescription,
        }),
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": `https://offers.myhubble.money/buy-gift-card/${brand.brandKey}`,
        },
        potentialAction: {
          "@type": "BuyAction",
          target: {
            "@type": "EntryPoint",
            urlTemplate: `https://offers.myhubble.money/buy-gift-card/${brand.brandKey}`,
            inLanguage: "en",
            actionPlatform: [
              "http://schema.org/DesktopWebPlatform",
              "http://schema.org/MobileWebPlatform",
            ],
          },
          name: `Buy ${brand.voucherProductMetadata.title} Gift Card`,
        },
      },

      {
        "@type": "WebPage",
        name: "Hubble Gift Card Store",
        url: "https://offers.myhubble.money",
        breadcrumb: {
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Home",
              item: "https://offers.myhubble.money/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: title,
              item: brandExtraMetadata.categoryName
                ? "https://offers.myhubble.money" +
                  config.paths.categoryPageBasePath +
                  getCategorySlugFromCategoryName(
                    brandExtraMetadata.categoryName
                  )
                : "https://offers.myhubble.money/",
            },
            {
              "@type": "ListItem",
              position: 3,
              name: brand.voucherProductMetadata.title,
              item: `https://offers.myhubble.money/buy-gift-card/${brand.brandKey}`,
            },
          ],
        },
      },

      {
        "@type": "Organization",
        name: "Hubble",
        description:
          "Hubble is a leading provider of gift card solutions and technology, empowering businesses and users across India. Offering a seamless platform for digital payments, e-vouchers, and personalized gifting, Hubble enables businesses to engage their customers with flexible gift card options. Whether for corporate rewards, customer loyalty programs, or individual gifting, Hubble's innovative solutions simplify the process for users and organizations alike, fostering a more connected and cashless economy in India.",
        url: "https://offers.myhubble.money/",
        sameAs: [
          "https://www.wikidata.org/wiki/Q130464721",
          "https://offers.myhubble.money/",
          "https://www.linkedin.com/company/hubblemoney",
          "https://www.instagram.com/hubblemoney/",
          "https://x.com/hubblemoney",
        ],
        logo: {
          "@type": "ImageObject",
          name: "Hubble Logo",
          url: "https://uploads-ssl.webflow.com/637f32081b688877381bdd42/637f32081b6888d0131bdda3_Hubble%20Logo%20Black%20Full.webp",
        },
        address: {
          "@type": "PostalAddress",
          streetAddress:
            "Urban Vault, #591, 15th main road, 22nd Cross Rd, 3rd Sector, HSR Layout",
          addressLocality: "Bengaluru",
          addressRegion: "Karnataka",
          postalCode: "560102",
          addressCountry: "IN",
        },
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "Customer Support",
          email: "support@myhubble.money",
        },
        location: {
          "@type": "Place",
          name: "Hubble Money",
          address: {
            "@type": "PostalAddress",
            addressCountry: "IN",
            postalCode: "560102",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: "12.909162",
            longitude: "77.6368585",
          },
          description:
            "Serving Indian businesses and individuals with gift card technology solutions and management.",
        },
        areaServed: {
          "@type": "Place",
          name: "India",
          sameAs: "https://dbpedia.org/page/India",
        },
        founder: [
          {
            "@type": "Person",
            name: "Neeraj Tulsyan",
            jobTitle: "Founder & CEO",
            description:
              "A passionate and driven digital product builder with 14+ years of experience launching and scaling successful products at Hubble Money, Ola, and ClearTax. Proven track record in building user-friendly consumer products, leveraging data-driven decision making, and staying ahead of evolving trends. MBA from ISB, B.Tech from IIT, and CFA L2 certified.",
            sameAs: "https://www.linkedin.com/in/neeraj-tulsyan-04421918/",
          },
          {
            "@type": "Person",
            name: "Mayank Bishnoi",
            jobTitle: "Co-Founder & CTO",
            description:
              "Building a delightful experience for Indian consumers to plan and save for their future purchases. Strong believer in embedded finance and that the future of finance lies in merging financial products with our day-to-day life.",
            sameAs: "https://www.linkedin.com/in/mayankbish/",
          },
        ],
        knowsAbout: [
          "Gift Cards",
          "Gift vouchers",
          "Prepaid Gift Cards",
          "evouchers",
          "Corporate Gift Cards",
          "Digital Payments",
          "card",
          "cards",
          "gift",
          "gift card",
          "gift cards",
          "gift voucher",
          "gift vouchers",
          "voucher",
          "vouchers",
        ],
      },
      {
        "@type": "FAQPage",
        mainEntity: seoSchemaForBrand?.faqObjs
          ? [...faqObjs, ...seoSchemaForBrand.faqObjs]
          : faqObjs,
      },
    ],
  });
}

export function SeoSchema({
  routeData,
}: {
  routeData: () => BrandL2RouteData | undefined;
}) {
  return (
    <script
      type="application/ld+json"
      innerHTML={getSchemaStringified(routeData)}
    ></script>
  );
}

export function MetadataTags({
  routeData,
}: {
  routeData: () => BrandL2RouteData | undefined;
}) {
  return (
    <>
      <Meta name="og:site_name" content="Hubble Money" />
      <Meta name="og:locale" content="en_IN" />
      <Meta
        name="product:price:amount"
        content={`${getSimulateTransactionDetails(routeData)?.dueAmount}`}
      />
      <Meta name="product:price:currency" content="INR" />
      <Meta name="product:category" content="Gift Cards" />
      <Meta name="product:brand" content={routeData()?.brand.name} />
      <Meta
        name="product:price:standard_amount"
        content={`${getSimulateTransactionDetails(routeData)?.redemptionAmount}`}
      />
      <Meta
        name="product:sale_price:amount"
        content={`${getSimulateTransactionDetails(routeData)?.dueAmount}`}
      />
      <Meta name="product:availability" content="in stock" />
      <Meta name="product:tag" content="Instant Delivery, Secure Payment" />
      <Meta
        name="product:sale_price_dates:start"
        content={formatDateNumeric(new Date())}
      />
      <Meta
        name="product:sale_price_dates:end"
        content={formatDateNumeric(
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        )}
      />
      <Meta
        name="keywords"
        content={`${routeData()?.brand.name} Gift Cards, ${routeData()?.brand.name} Gift Vouchers, ${routeData()?.brand.name} e-gift cards, ${routeData()?.brand.name} vouchers, ${routeData()?.brand.name} e vouchers, ${routeData()?.brand.name} Gift Card Offers"`}
      ></Meta>
    </>
  );
}
