import { useNavigate } from "@solidjs/router";
import { onMount } from "solid-js";
import { DummyData } from "~/components/dummy";
import HideHtmlOverflow from "~/widgets/RestrictBodyScroll";

export default function Dummy1() {
  onMount(() => {
    window.document.body.style.overflow = "hidden";
  });

  const navigate = useNavigate();
  return (
    <div
      class="w-full"
      onClick={() => {
        navigate("/dummy/2");
      }}
    >
      <HideHtmlOverflow />
      <DummyData />
    </div>
  );
}
