import { debounce } from "@solid-primitives/scheduled";
import { cache, createAsync, useNavigate, useParams } from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Match,
  Show,
  Switch,
} from "solid-js";
import { closeIconSM, noResultsFound } from "~/assets/assets";
import {
  popularIcon,
  smartGCCategoryIcons,
} from "~/assets/smart_gc_categories_icons";
import { useSmartGreeting } from "~/context/smart_greeting_context";
import { getProductSearch } from "~/server/apis/client_apis";
import {
  getSelectBrandsRouteData,
  SelectBrandsRouteData,
} from "~/server/data/smart_greeting/select_brands_route_data";
import { Product } from "~/server/types/search";
import { pluralize } from "~/utils/common";
import { ThreeDotLoader } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";

const getSelectBrandsRouteData$C = cache(
  getSelectBrandsRouteData,
  "select_brands"
);

export default function SelectBrandsRoute() {
  const params = useParams();
  const routeData: Accessor<SelectBrandsRouteData | undefined> =
    createAsync<SelectBrandsRouteData>(
      () => {
        return getSelectBrandsRouteData$C();
      },
      {
        deferStream: true,
      }
    );
  const { smartGCDetails, setSmartGCDetails } = useSmartGreeting();

  const [selectedProducts, setSelectedProducts] = createSignal<Product[]>(
    smartGCDetails?.brandDetails?.map((brand) => ({
      id: brand.brandId,
      type: "",
      voucherProduct: {
        title: brand.name || "",
        backgroundImageUrl: "",
        logoUrl: "",
        iconImageUrl: brand.logoUrl || "",
        rdSqLogoUrl: "",
        plainLogoUrl: "",
        heroImageUrl: "",
        cardBackgroundColor: "",
        discountPercentage: 0,
        rewardType: "PREPAID",
        rewardDetails: [],
        isLandingPageAvailable: true,
        subscriptionDetails: null,
        status: "ACTIVE",
      },
      brandKey: brand.brandKey || "",
      displayTags: [],
      category: "",
      tags: [],
      filterTags: [],
      applicableCouponDetails: null,
    })) || []
  );

  const [pageProducts, setPageProducts] = createSignal<Product[] | undefined>(
    undefined
  );

  const [selectedCategory, setSelectedCategory] =
    createSignal<string>("POPULAR_BRANDS");

  const [loadingProducts, setLoadingProducts] = createSignal<boolean>(false);

  const [showSearchBar, setShowSearchBar] = createSignal<boolean>(false);

  const [searchQuery, setSearchQuery] = createSignal<string>("");

  let searchInput: HTMLInputElement | undefined;

  const [showMaxSelectionError, setShowMaxSelectionError] =
    createSignal<boolean>(false);

  const navigate = useNavigate();

  const toggleSelectedProducts = (product: Product) => {
    setSelectedProducts((prev) => {
      const exists = prev.some((p) => p.id === product.id);
      if (!exists) {
        if (prev.length == 3) {
          setShowMaxSelectionError(true);
          return [...prev];
        }
        return [...prev, product];
      }
      return prev.filter((p) => p.id !== product.id);
    });
  };

  const addBrandsToGift = async () => {
    if (selectedProducts().length === 3) {
      setSmartGCDetails(
        "brandDetails",
        selectedProducts().map((product) => ({
          brandKey: product.brandKey,
          brandId: product.id,
          name: product.voucherProduct.title,
          logoUrl: product.voucherProduct.iconImageUrl,
        }))
      );
      navigate(`/smart-gc/create/${params.giftingKey$}`, { replace: true });
    }
  };

  const getCategoryProducts = async () => {
    setLoadingProducts(true);
    try {
      const response = await getProductSearch({ category: selectedCategory() });
      if (response) {
        setPageProducts(response.data[0].products);
      }
    } catch (e) {}
    setLoadingProducts(false);
  };

  const getSearchQueryProducts = async () => {
    setLoadingProducts(true);
    try {
      const response = await getProductSearch({ query: searchQuery() });
      if (response) {
        setPageProducts(response.data[0].products);
      }
    } catch (e) {}
    setLoadingProducts(false);
  };

  createEffect(async () => {
    if (showMaxSelectionError()) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setShowMaxSelectionError(false);
    }
  });

  createEffect(() => {
    if (showSearchBar()) {
      searchInput?.focus();
    }
  });

  createEffect(() => {
    if (searchQuery() === "") {
      setPageProducts(routeData()?.popularBrands.data[0].products);
    } else {
      getSearchQueryProducts();
    }
  });

  const clearSearch = () => {
    setSearchQuery("");
    setShowSearchBar(false);
    setSelectedCategory("POPULAR_BRANDS");
  };

  const searchDebounce = debounce((e: Event) => {
    const searchQuery = e.target as HTMLInputElement;
    setSearchQuery(searchQuery.value);
  }, 300);

  return (
    <div class="noScrollbar flex h-full w-full flex-col items-center justify-start ">
      {buildTopNavigation()}

      <Show
        when={!loadingProducts()}
        fallback={
          <div class="flex h-full w-full items-center justify-center bg-black">
            <ThreeDotLoader color="#fff" />
          </div>
        }
      >
        <Switch>
          <Match
            when={searchQuery().length > 0 && pageProducts()?.length === 0}
          >
            {noSearchResultFound()}
          </Match>
          <Match when={true}>
            <div class="noScrollbar  mt-[6.5rem] grid h-min w-full grid-cols-3 gap-x-3 gap-y-6   p-4 pb-[150px]">
              <For
                each={
                  pageProducts() ?? routeData()?.popularBrands.data[0].products
                }
              >
                {(product) => {
                  return productCard(product);
                }}
              </For>
            </div>
          </Match>
        </Switch>
      </Show>

      {buildBottomSection()}
    </div>
  );

  function noSearchResultFound() {
    return (
      <div class="my-32 flex h-full w-full flex-col items-center justify-items-start">
        <HubbleImage
          src={noResultsFound}
          class="h-[80px] w-[80px]"
          alt="no results found"
        />

        <div class="mt-2  max-w-[250px] text-center text-[15px] text-normal text-textNormal">
          Please try another keyword for brand name
        </div>
      </div>
    );
  }

  function productCard(product: Product) {
    return (
      <div
        class="flex flex-col gap-2"
        onClick={() => {
          toggleSelectedProducts(product);
        }}
      >
        <img
          class="h-[118px] w-[106px] rounded-[18px] border border-[#ffffff26]"
          src={product?.voucherProduct?.iconImageUrl!}
        />
        <p class="line-clamp-1 text-ellipsis px-1 text-mediumBold text-white">
          {product.voucherProduct.title}
        </p>
        <Show
          when={selectedProducts().some((p) => p.id === product.id)}
          fallback={
            <button class="flex h-8 w-[106px] items-center justify-center rounded-[41px] border border-basePrimaryLight px-4 text-mediumBold text-[#EDEEF1]">
              Select
            </button>
          }
        >
          <button class="flex h-8 w-[106px]  items-center justify-center rounded-[41px] border border-basePrimaryLight bg-white px-4 text-mediumBold  text-black">
            <div class="flex items-center justify-center gap-1">
              <PhosphorIcon
                name="check"
                fontSize={12}
                class="text-black"
                size="bold"
              />
              <div>Selected</div>
            </div>
          </button>
        </Show>
      </div>
    );
  }

  function buildTopNavigation() {
    return (
      <div
        class={`fixed top-0 z-10 flex w-full flex-col border-b border-basePrimaryMedium bg-black text-white`}
      >
        <Show when={showSearchBar()} fallback={pageHeader()}>
          {buildSearchBar()}
        </Show>
        <Switch>
          <Match when={searchQuery().length > 0}>
            <Show
              when={
                pageProducts() &&
                pageProducts()!.length > 0 &&
                !loadingProducts()
              }
            >
              <div class="px-4 py-2 text-f12 text-textWhite">
                {pageProducts()?.length + " BRANDS"}
              </div>
            </Show>
          </Match>
          <Match when={true}>{CategoryStrip()}</Match>
        </Switch>
      </div>
    );
  }

  function pageHeader() {
    return (
      <div class="flex h-[60px] items-center justify-between px-2 py-3">
        <button
          class="cursor-pointer p-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={closeIconSM} alt="close" />
        </button>
        <p class="font-jakartaSans text-f16Bold">Select brands</p>
        <button
          class="flex cursor-pointer p-2"
          onClick={() => {
            setShowSearchBar(true);
          }}
        >
          <PhosphorIcon
            name="magnifying-glass"
            fontSize={24}
            class="text-white"
          />
        </button>
      </div>
    );
  }

  function buildSearchBar() {
    return (
      <div class="flex items-center justify-between gap-1 px-4 py-2.5">
        <div class="flex h-10 grow items-center justify-between rounded-xl bg-basePrimaryDark pl-3">
          <input
            ref={searchInput}
            class="h-full w-full bg-basePrimaryDark text-f16 placeholder:text-textNormal"
            value={searchQuery()}
            placeholder="Search for brand"
            onInput={searchDebounce}
          />
          <div
            class="flex h-10 w-10 items-center justify-center rounded-[20px]"
            onClick={() => {
              setSearchQuery("");
              setPageProducts(routeData()?.popularBrands.data[0].products);
              searchInput?.focus();
            }}
          >
            <Show when={searchQuery().length > 0}>
              <PhosphorIcon
                name="x"
                fontSize={14}
                size="bold"
                class="text-textWhite"
              />
            </Show>
          </div>
        </div>
        <div
          class="px-3 py-2 text-mediumSemiBold text-textWhite"
          onClick={() => {
            clearSearch();
          }}
        >
          Done
        </div>
      </div>
    );
  }

  function CategoryStrip() {
    return (
      <div class="noScrollbar flex gap-5 overflow-x-scroll scroll-smooth pl-4 pr-4">
        <div
          class={`flex h-11 items-center justify-center gap-2 ${selectedCategory() === "POPULAR_BRANDS" ? "border-b border-white text-textWhite" : "text-textNormal"}`}
          onClick={() => {
            if (selectedCategory() === "POPULAR_BRANDS") {
              return;
            }
            setSelectedCategory("POPULAR_BRANDS");
            getCategoryProducts();
          }}
        >
          <div
            class={`h-5 w-5 ${selectedCategory() === "POPULAR_BRANDS" ? "stroke-white" : "stroke-[#7c7c7c]"}`}
          >
            {popularIcon()}
          </div>
          <div
            class={`flex items-center text-nowrap text-mediumBold capitalize `}
          >
            Popular
          </div>
        </div>
        <For each={routeData()?.categories.data}>
          {(category) => {
            const IconComponent = smartGCCategoryIcons[category.name]?.();
            if (IconComponent)
              return (
                <div
                  class={`flex items-center justify-center gap-2 ${selectedCategory() === category.name ? "border-b border-white text-textWhite" : "text-textNormal"}`}
                  onClick={() => {
                    if (selectedCategory() === category.name) {
                      return;
                    }
                    setSelectedCategory(category.name);
                    getCategoryProducts();
                  }}
                >
                  <div
                    class={`h-5 w-5 ${selectedCategory() === category.name ? "stroke-white" : "stroke-[#7c7c7c]"}`}
                  >
                    {IconComponent}
                  </div>
                  <div
                    class={`flex h-11 items-center text-nowrap text-mediumBold capitalize `}
                  >
                    {category.metadata.title}
                  </div>
                </div>
              );
            else {
              return <></>;
            }
          }}
        </For>
      </div>
    );
  }

  function buildBottomSection() {
    return (
      <div
        class={`fixed bottom-0 z-10 flex w-full flex-col  border-t border-basePrimaryDark bg-black `}
      >
        {maxSelectionHeadsupWarning()}
        <div class="noScrollbar mt-3  w-full overflow-auto">
          {selectedBrandRow()}
        </div>
        <div class="p-4">{bottomCTA()}</div>
      </div>
    );
  }

  function bottomCTA() {
    return (
      <div class="lg:mx-auto lg:flex lg:w-[766px] lg:items-end lg:justify-between">
        <div class="flex">
          <div
            class={`flex h-[44px] w-full items-center justify-center rounded-[41px]  px-3 text-textNormal ${selectedProducts().length < 3 ? "bg-basePrimaryDark" : "bg-white"}`}
            onClick={() => {
              addBrandsToGift();
            }}
          >
            <Show
              when={selectedProducts().length < 3}
              fallback={
                <p class="text-buttonMedium font-bold text-textDark">
                  Add brands to gift
                </p>
              }
            >
              <p class="text-buttonMedium font-bold ">
                Select {3 - selectedProducts().length}{" "}
                {pluralize({
                  name: "brand",
                  count: 3 - selectedProducts().length,
                })}{" "}
                to continue
              </p>
            </Show>
          </div>
        </div>
      </div>
    );
  }

  function maxSelectionHeadsupWarning() {
    return (
      <Show when={showMaxSelectionError()}>
        <div class="flex w-full items-center justify-between bg-[#E0B827] px-2 py-2.5">
          <div class="text-mediumSemiBold">Remove one brand to add another</div>
          <PhosphorIcon
            name="x"
            size="bold"
            fontSize={24}
            onClick={() => {
              setShowMaxSelectionError(false);
            }}
          />
        </div>
      </Show>
    );
  }

  function selectedBrandRow() {
    return (
      <Show when={selectedProducts().length > 0}>
        <div
          class={`noScrollbar flex w-full flex-row gap-2  overflow-x-scroll  px-4 ${selectedProducts().length < 3 ? "justify-center" : "justify-start"}`}
        >
          <For each={selectedProducts()}>
            {(product) => {
              return (
                <div
                  class="flex items-center justify-start gap-2 rounded-[40px] border border-basePrimaryDark py-1 pl-1 pr-1.5"
                  onClick={() => {
                    toggleSelectedProducts(product);
                  }}
                >
                  <div class="h-6 w-6 rounded-full">
                    <HubbleImage
                      src={product.voucherProduct.iconImageUrl}
                      alt=""
                      class="h-6 w-6 rounded-full"
                    />
                  </div>
                  <div class="text-nowrap text-mediumSemiBold text-textWhite">
                    {product.voucherProduct.title}
                  </div>
                  <PhosphorIcon
                    name="x"
                    fontSize={12}
                    size="bold"
                    class="text-textWhite"
                  />
                </div>
              );
            }}
          </For>
        </div>
      </Show>
    );
  }
}
