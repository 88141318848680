import { createSignal, onMount, Setter } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";

export function ActivationCodeInfoModal({
  userName,
  closeActivationCodeInfoModal,
}: {
  userName: string;
  closeActivationCodeInfoModal: () => void;
}) {
  const [animate, setAnimate] = createSignal(false);

  onMount(() => {
    setAnimate(true);
  });

  return (
    <div
      class="fixed inset-0 z-[70] flex h-dvh flex-col bg-black text-white opacity-0 duration-300 "
      classList={{
        "opacity-100": animate(),
      }}
    >
      <div class=" mt-2.5 px-4 py-2" onClick={closeActivationCodeInfoModal}>
        <PhosphorIcon name="x" fontSize={24} class="text-textWhite" />
      </div>
      <div class="mb-0.5 px-4 text-h3 text-textWhite">
        {userName} is excited for you to unwrap this gift... Just ask for the
        code!
      </div>
      <div class="mt-5 px-4 text-normal text-textNormal">
        An activation code was shared with {userName} when they created this
        gift. Please ask them for it to use the gift.
      </div>
      <div class="mt-3 px-4 text-normal text-textNormal">
        If they've lost or didn't receive it, they can retrieve it via our
        hassle free support at support@myhubble.money.
      </div>
    </div>
  );
}
