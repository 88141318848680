import { createSignal, Match, onMount, Show, Switch } from "solid-js";
import "../../scroll_bar_style.scss";
import { Spacer } from "~/widgets/spacer";
import CardsMultiBrand, { GiftBrandListingData } from "./cards_list";
import {
  AnimatedHeight,
  BalanceAndTitle,
  DescriptionText,
  ExpiryDate,
  Footer,
  GiftLottie,
  giftReceivedText,
  RibbonConfettiLottie,
  UnwrapButton,
} from "./brand_collection_components";
import { BrandCollectionHeader } from "./brand_collection_header";
import CollectOtpComponent from "./collect_otp_box";
import { createJob } from "~/utils/job";
import { LandingPageData } from "~/routes/gift-box/m/[giftingKey]";
import { congratulationsText } from "~/assets/assets";
import {
  getCoinsSummary,
  sendGiftOtpV3,
  verifyGiftOtpV3,
} from "~/server/apis/client_apis";
import { showSnackBar } from "~/shared_states/snackbar";
import { VerifyOtpResponseV3 } from "~/server/types/gift";
import { storeV3GiftSessionId } from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { Header } from "~/types";
import { generateDeviceVerificationToken } from "~/utils/common";
import { APIError } from "~/utils/fetch";

export type BrandCollectionProps = {
  sessionId: string | null;
  metadata: BrandCollectionGiftMetadata;
  onClickBrand: (id: string) => void;
  landingPageData: () => LandingPageData;
  getBrandsList: (sessionId: string) => Promise<GiftBrandListingData[]>;
  setClickedUnwrap: () => void;
  giftingKey: string;
  logoUrl?: string;
};

export type BrandCollectionGiftMetadata = {
  bgImage: string;
  expiryDate: string;
  amount: number;
  description: string;
  instructions: string[];
};

export type BrandCollectionLandingPageState = {
  showCard: boolean;
  animateHeight: boolean;
  animateDescription: boolean;
  shrinkButtonVertically: boolean;
  shrinkButtonHorizontally: boolean;
  showGiftReceivedText: boolean;
  showButton: boolean;
  showDescription: boolean;
  showBalance: boolean;
  showCollectOtp: boolean;
  showExpiryOnBottom: boolean;
  showCoinsOnHeader: boolean;
  showHeader: boolean;
  showToolTip: boolean;
  state: "initial" | "final";
};

export function getInitialLandingPageState(): BrandCollectionLandingPageState {
  return {
    showCard: false,
    animateHeight: false,
    animateDescription: false,
    shrinkButtonVertically: false,
    shrinkButtonHorizontally: false,
    showGiftReceivedText: true,
    showButton: true,
    showDescription: false,
    showBalance: false,
    showCollectOtp: false,
    showExpiryOnBottom: true,
    showCoinsOnHeader: false,
    showHeader: false,
    showToolTip: false,
    state: "initial",
  };
}

export function getFinalLandingPageState(props: {
  showTooltip: boolean;
}): BrandCollectionLandingPageState {
  return {
    showCard: true,
    animateHeight: true,
    animateDescription: true,
    shrinkButtonVertically: true,
    shrinkButtonHorizontally: true,
    showGiftReceivedText: false,
    showButton: false,
    showDescription: true,
    showBalance: true,
    showCollectOtp: false,
    showExpiryOnBottom: false,
    showCoinsOnHeader: false,
    showHeader: true,
    showToolTip: props.showTooltip,
    state: "final",
  };
}

export function BrandCollectionComponent(props: BrandCollectionProps) {
  // Data signals
  const [sessionId, setSessionId] = createSignal<string | null>(
    props.sessionId
  );
  const [coinsAvailable, setCoinsAvailable] = createSignal<number | null>(
    props.landingPageData().coinsCount ?? null
  );
  const [
    automaticallyStartAnimationsAfterJob,
    setAutomaticallyStartAnimationsAfterJob,
  ] = createSignal(false);

  const [otpToken, setOtpToken] = createSignal<string | null>(null);

  const [showProfile, setShowProfile] = createSignal(
    props.landingPageData().isRtu
  );

  const fetchGiftBoxLandingPageDataJob = createJob<GiftBrandListingData[]>({
    initialJob: async () => {
      if (sessionId()) {
        return landingPageData.brands ?? props.getBrandsList(sessionId()!);
      } else {
        throw new Error("Session ID not available");
      }
    },
    successCallback: async (response) => {
      if (automaticallyStartAnimationsAfterJob()) {
        startAnimations();
      }
    },
    errorCallback: (error) => {
      console.error(error);
      if (error instanceof APIError) {
        if (error.code === 401) {
          window.location.href = "/gift-box/" + props.giftingKey;
        }
      }
    },
  });

  const unwrapGiftJob = createJob({
    initialJob: async () => {
      props.setClickedUnwrap();
      if (sessionId() === null) {
        // do verification of already linked phone number
        let { otpToken } = await sendGiftOtpV3({
          id: props.giftingKey,
          headers: {
            [Header.FpDeviceToken]: await generateDeviceVerificationToken(),
            [Header.SessionId]: "",
          },
        });
        setOtpToken(otpToken);
        setShowCollectOtp(true);
        setShowButton(false);
      } else if (sessionId() != null && coinsAvailable() == null) {
        setShowCollectOtp(false);
        setShowButton(true);
        // fetch coins, perform animations
        let coinsSummary = await getCoinsSummary({
          [Header.SessionId]: sessionId()!,
        });
        setCoinsAvailable(coinsSummary.totalAvailable);
        setShowProfile(coinsSummary.totalConsumed > 0);
        actBasedOnJobState();
      } else {
        // fetch brands, perform animations
        actBasedOnJobState();
      }
    },
    successCallback: async () => {},
    errorCallback: (error) => {
      if (error instanceof APIError) {
        if (error.code === 401) {
          window.location.href = "/gift-box/" + props.giftingKey;
        }
      }
      showSnackBar({
        message: error.message,
        level: "error",
      });
    },
  });

  let landingPageData = props.landingPageData();

  // UI signals
  const [showCard, setShowCard] = createSignal(landingPageData.state.showCard);
  const [animateHeight, setAnimateHeight] = createSignal(
    landingPageData.state.animateHeight
  );
  const [animateDescription, setAnimateDescription] = createSignal(
    landingPageData.state.animateDescription
  );
  const [shrinkButtonVertically, setShrinkButtonVertically] = createSignal(
    landingPageData.state.shrinkButtonVertically
  );
  const [shrinkButtonHorizontally, setShrinkButtonHorizontally] = createSignal(
    landingPageData.state.shrinkButtonHorizontally
  );
  const [showGiftReceivedText, setShowGiftReceivedText] = createSignal(
    landingPageData.state.showGiftReceivedText
  );
  const [showButton, setShowButton] = createSignal(
    landingPageData.state.showButton
  );
  const [showDescription, setShowDescription] = createSignal(
    landingPageData.state.showDescription
  );
  const [showBalance, setShowBalance] = createSignal(
    landingPageData.state.showBalance
  );
  const [showCollectOtp, setShowCollectOtp] = createSignal(
    landingPageData.state.showCollectOtp
  );
  const [showExpiryOnBottom, setShowExpiryOnBottom] = createSignal(
    landingPageData.state.showExpiryOnBottom
  );
  const [showCoinsOnHeader, setShowCoinsOnHeader] = createSignal(
    landingPageData.state.showCoinsOnHeader
  );
  const [showHeader, setShowHeader] = createSignal(
    landingPageData.state.showHeader
  );

  const [showToolTip, setShowToolTip] = createSignal(
    landingPageData.state.showToolTip
  );

  let observer: IntersectionObserver;
  let observerRef: Element | undefined = undefined;

  onMount(() => {
    if (sessionId()) {
      fetchGiftBoxLandingPageDataJob.run();
    }

    observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setShowCoinsOnHeader(false);
      } else {
        setShowCoinsOnHeader(true);
      }
    });
    if (observerRef) {
      observer.observe(observerRef);
    }
  });

  function playUnwrapLottie() {
    try {
      const giftUnwrapElement = document.getElementById("gift-unwrap");
      // @ts-ignore
      giftUnwrapElement?.play();
    } catch (e) {
      console.error(e);
    }
  }

  function playConfettiLottie() {
    try {
      const ribbonConfettiElement = document.getElementById("ribbon-confetti");
      // @ts-ignore
      ribbonConfettiElement?.play();
    } catch (e) {
      console.error(e);
    }
  }

  async function actBasedOnJobState() {
    switch (fetchGiftBoxLandingPageDataJob.jobState()) {
      case "running":
        setAutomaticallyStartAnimationsAfterJob(true);
        break;
      case "success":
        startAnimations();
        break;
      case "error":
        fetchGiftBoxLandingPageDataJob.run();
        setAutomaticallyStartAnimationsAfterJob(true);
        break;
      case "idle":
        fetchGiftBoxLandingPageDataJob.run();
        setAutomaticallyStartAnimationsAfterJob(true);
        break;
    }
  }

  async function startAnimations() {
    setShrinkButtonVertically(true);
    setShowExpiryOnBottom(false);
    playUnwrapLottie();
    await delay(1500);
    setShrinkButtonHorizontally(true);
    await delay(1000);
    setShowButton(false);
    setShowGiftReceivedText(false);
    await delay(1000);
    setShowDescription(true);
    await delay(100);
    setAnimateDescription(true);
    playConfettiLottie();
    await delay(900);

    setAnimateHeight(true);
    await delay(800);
    setShowHeader(true);
    setShowBalance(true);
    await delay(500);
    setShowCard(true);
    await delay(2500);
    setShowToolTip(true);
  }

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <>
      <div class="noScrollbar flex h-max min-h-screen w-full flex-col items-center justify-center  bg-baseDark px-5 font-inter text-baseTertiaryLight ">
        <img
          src={props.metadata.bgImage}
          alt=""
          class="pointer-events-none absolute top-0 z-[0]"
        />

        <div
          class="xs:top-0 relative top-[70px] z-10 flex max-h-[600px] min-h-[250px] w-full max-w-[450px] flex-col items-center justify-start "
          classList={{
            "overflow-y-hidden":
              !shrinkButtonHorizontally() &&
              fetchGiftBoxLandingPageDataJob.jobResult() != null,
          }}
        >
          <Switch>
            <Match when={landingPageData.state.state == "initial"}>
              <GiftLottie />
            </Match>
            <Match when={true}>
              <img src={congratulationsText} alt="" class="h-[45px]" />
            </Match>
          </Switch>
          <Show when={showGiftReceivedText()}>
            <Spacer height={20} />
            {giftReceivedText({
              shrinkButtonHorizontally,
            })}
          </Show>
          <Show when={showDescription()}>
            <DescriptionText
              text={props.metadata.description}
              animateDescription={animateDescription}
            />
          </Show>
          {buildCollectOtpComponent()}
          <Show when={showButton()}>
            <UnwrapButton
              onClickUnwrapGift={unwrapGiftJob.run}
              shrinkButtonVertically={shrinkButtonVertically}
              shrinkButtonHorizontally={shrinkButtonHorizontally}
              isLoading={
                (fetchGiftBoxLandingPageDataJob.jobState() === "running" &&
                  automaticallyStartAnimationsAfterJob()) ||
                unwrapGiftJob.jobState() === "running"
              }
            />
          </Show>
          <div ref={observerRef}></div>
          <Show when={fetchGiftBoxLandingPageDataJob.jobResult() != null}>
            {buildBalanceAndTitle()}
            {buildCards(fetchGiftBoxLandingPageDataJob.jobResult()!)}
          </Show>
          <Show when={animateHeight()}>
            <AnimatedHeight />
          </Show>
        </div>
        <BrandCollectionHeader
          showBalance={showCoinsOnHeader}
          showProfile={showProfile}
          isAbsoluteHeader={false}
          showHeader={showHeader}
          coinsAvailable={coinsAvailable}
          giftingKey={props.giftingKey}
          logoUrl={props.logoUrl}
        />
        <ExpiryDate
          showExpiryOnBottom={showExpiryOnBottom}
          expiryDate={props.metadata.expiryDate}
        />
      </div>
      <div class="pointer-events-none absolute left-[50%] top-[200px]  flex w-full max-w-[400px] -translate-x-[50%] overflow-hidden ">
        <RibbonConfettiLottie />
      </div>
    </>
  );

  function buildCollectOtpComponent() {
    if (otpToken() === null && showCollectOtp()) {
      window.location.reload();
      throw new Error("OTP token is null");
    }
    return (
      <div
        class="w-full transition-all duration-1000"
        classList={{
          "h-[450px]": showCollectOtp(),
          "h-0": !showCollectOtp(),
          "opacity-0": !showCollectOtp(),
          "opacity-100": showCollectOtp(),
        }}
      >
        <Show when={showCollectOtp()}>
          <div class="mt-6"></div>
          <CollectOtpComponent<VerifyOtpResponseV3>
            onVerificationSuccess={(response) => {
              setSessionId(response.sessionId);
              storeV3GiftSessionId({
                giftingKey: props.giftingKey,
                sessionId: response.sessionId,
              });
              unwrapGiftJob.run();
            }}
            otpToken={otpToken()!}
            phoneNumber={props.landingPageData().phoneNumber ?? ""}
            resendOtp={async (number) => {
              let response = await sendGiftOtpV3({
                id: props.giftingKey,
                headers: {
                  [Header.FpDeviceToken]:
                    await generateDeviceVerificationToken(),
                  [Header.SessionId]: "",
                },
              });
              return {
                otpToken: response.otpToken,
              };
            }}
            submitOtp={async (otp) => {
              return verifyGiftOtpV3({
                id: props.giftingKey,
                otp: otp.otp,
                otpToken: otp.otpToken,
                headers: {
                  [Header.FpDeviceToken]:
                    await generateDeviceVerificationToken(),
                  [Header.SessionId]: "",
                },
              });
            }}
          />
          <div class="mt-3"></div>
          <Footer spacedOut={false} />
        </Show>
      </div>
    );
  }

  function buildCards(brands: GiftBrandListingData[]) {
    return (
      <div
        class="w-full "
        classList={{
          "h-0": !showCard(),
        }}
      >
        <CardsMultiBrand
          onClickBrand={props.onClickBrand}
          brands={brands}
          showCards={showCard}
          animate={landingPageData.state.state === "initial"}
        />
        <div class="mt-7"></div>
        <div
          classList={{
            "opacity-0": !showCard(),
            "opacity-100": showCard(),
          }}
        >
          <Footer spacedOut={true} />
        </div>
        <div class="mt-8"></div>
        &nbsp;
      </div>
    );
  }

  function buildBalanceAndTitle() {
    return (
      <div
        class="w-full transition-all duration-300"
        classList={{
          "h-0": !showBalance(),
          "opacity-0": !showBalance(),
          "opacity-100": showBalance(),
        }}
      >
        <BalanceAndTitle
          coinsAvailable={coinsAvailable() ?? 0}
          giftingKey={props.giftingKey}
          instructions={props.metadata.instructions}
          showToolTip={showToolTip}
          setShowToolTip={setShowToolTip}
          isSmartGC={landingPageData.isSmartGC}
        />
      </div>
    );
  }
}
