import { Show, createEffect, onCleanup, createSignal, onMount } from "solid-js";
import { placeholderCardBig } from "../assets/assets";
import { classNames } from "../utils/etc";
import styles from "./hubble_image.module.scss";

export type ImageProps = {
  src: string;
  alt: string;
  class: string;
  errorImage?: string;
  imageBorderStyleClass?: string;
};

export default function HubbleImage(props: ImageProps) {
  let img!: HTMLImageElement;
  let div!: HTMLDivElement;

  const [imageLoaded, setImageLoaded] = createSignal(false);
  const [errorImage, setErrorImage] = createSignal(false);

  onMount(async () => {
    img.onload = () => {
      if (!imageLoaded()) {
        setImageLoaded(true);
      }
    };

    img.onerror = () => {
      setImageLoaded(true);
      setErrorImage(true);
    };

    // check if image is already loaded and onLoad event is not triggered
    if (
      img.complete &&
      img.naturalWidth >= 0 &&
      img.naturalHeight >= 0 &&
      !imageLoaded()
    ) {
      setImageLoaded(true);
    }

    if (getComputedStyle(div).getPropertyValue("border-radius") === "0px") {
      div.style.borderRadius = "20px";
    }
  });

  const timer = setTimeout(() => {
    if (!imageLoaded()) {
      setImageLoaded(true);
      setErrorImage(true);
    }
  }, 10000);

  createEffect(async () => {
    timer;
  });

  onCleanup(() => {
    clearTimeout(timer);
  });

  return (
    <div class={classNames("relative", props.class)}>
      <Show when={!errorImage()}>
        {
          <img
            ref={img}
            src={props.src}
            alt={props.alt}
            class={`absolute left-0 top-0 ${props.class} ${
              imageLoaded() ? "opacity-100" : "opacity-10"
            } transition-opacity duration-300 ease-in-out ${props.imageBorderStyleClass}`}
          />
        }
      </Show>
      <div class={`absolute h-full w-full`}>
        <Show when={!imageLoaded()}>
          {
            <div
              ref={div}
              class={`absolute left-0 top-0 h-full w-full ${styles.skeleton} ${props.class}`}
            />
          }
        </Show>
        <Show when={errorImage()}>
          <img
            class={props.class}
            src={props.errorImage ?? placeholderCardBig}
            alt="Image load fail"
          />
        </Show>
      </div>
    </div>
  );
}
