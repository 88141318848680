import { For, Match, Show, Switch } from "solid-js";
import { getRedemptionTransactionDetail } from "../../data/products";
import {
  RefundStatusType,
  TransactionDetailV4Response,
} from "../../data/transaction";
import { classNames } from "../../utils/etc";
import { toRupees } from "../../utils/number";
import { BackButton, ThreeDotLoader } from "../../widgets/button";
import { ResourceLoader } from "../../widgets/resource_loader";
import { VoucherCardPlain } from "../voucher_edit/voucher_card";
import styles from "./transaction_detail.module.scss";
import { PhosphorIcon } from "../../widgets/icons";
import { goBack, HubbleRoute, pushPage } from "../../shared_states/modal";
import { VoucherGenSuccess } from "../payment_options/voucher_gen_success";
import { showSnackBar } from "../../shared_states/snackbar";
import { openIntercom } from "~/utils/third_party/intercom";
import {
  copyText,
  getCoinLabelCapitalized,
  getCoinLabelCapitalizedPluralized,
} from "~/utils/common";
import { useClassicContext } from "~/data/classic_context";
import { Divider } from "~/widgets/divider";
import { defaultCoin } from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import { DonationDetails, getDonationDetails } from "~/data/donation";

export function TransactionDetail(props: { transactionId: string }) {
  const { isClientDonationType } = useClassicContext();
  const getTransactionDetailData = async () => {
    const transactionDetail = await getRedemptionTransactionDetail(
      props.transactionId
    );
    if (isClientDonationType()) {
      const donationDetails = await getDonationDetails(props.transactionId);
      return {
        transactionDetail,
        donationDetails,
      };
    }
    return {
      transactionDetail,
    };
  };

  return (
    <ResourceLoader fetch={() => getTransactionDetailData()}>
      {(detail) => {
        const transactionDetail = detail.transactionDetail;
        return (
          <div class={`${styles.transactionDetail} no-scrollbar`}>
            <BackButton />
            <div class={styles.content}>
              <Show when={transactionDetail.orderStatus == "COMPLETED"}>
                <p class="font-['Inter'] font-semibold text-baseDark text-center">
                  Tap card to view card details
                </p>
              </Show>
              <VoucherCardPlain
                backgroundColor={
                  transactionDetail.brandDetails.cardBackgroundColor
                }
                displayText={"Card worth"}
                plainLogoUrl={transactionDetail.brandDetails.plainLogoUrl}
                value={toRupees(transactionDetail.amount)}
                class={styles.voucherCard}
                onClick={() => {
                  if (transactionDetail.orderStatus == "COMPLETED") {
                    pushPage(
                      () => (
                        <VoucherGenSuccess
                          orderId={transactionDetail.orderId}
                          transactionId={props.transactionId}
                          onClose={() => goBack()}
                          refreshProfile={false}
                        />
                      ),
                      undefined,
                      HubbleRoute.VOUCHER_GENERATION_SUCCESS
                    );
                  }
                }}
              />

              <Show
                when={
                  transactionDetail.refundStatus === "SUCCESS" ||
                  transactionDetail.refundStatus === "INITIATED" ||
                  transactionDetail.refundStatus === "PENDING"
                }
              >
                <RefundStatus
                  status={transactionDetail.refundStatus}
                  amount={transactionDetail.amountBreakup.paidAmount}
                  timestampStr={transactionDetail.refundStatusLastUpdatedAt}
                />
              </Show>
              <ItemDetailCard transaction={transactionDetail} />
              <DonationDetailCard
                transaction={transactionDetail}
                donationDetails={detail.donationDetails}
              />
              <BillDetailsCard transaction={transactionDetail} />
              <OrderDetailsCard transaction={transactionDetail} />
              <InvoiceCard transaction={transactionDetail} />
            </div>
          </div>
        );
      }}
    </ResourceLoader>
  );
}

function ItemDetailCard(props: { transaction: TransactionDetailV4Response }) {
  // const showSeeAllButton = props.transaction.orderStatus == "COMPLETED";
  const showLoader =
    props.transaction.orderStatus == "INITIALISED" ||
    props.transaction.orderStatus == "PROCESSING";

  return (
    <div class={styles.itemDetailCard}>
      <span class={styles.heading}>
        Item details
        {/* <Switch>
          <Match when={showSeeAllButton}>
            <span
              class={styles.seeAllButton}
              onClick={() => {
                pushPage(() => (
                  <VoucherGenSuccess
                    orderId={props.transaction.orderId}
                    onClose={() => popWithAnimation()}
                  />
                ));
              }}
            >
              See all <PhosphorIcon name="caret-right" size="bold" />
            </span>
          </Match>
        </Switch> */}
        <Show when={showLoader}>
          <ThreeDotLoader color="#000" />
        </Show>
      </span>
      <div class={styles.itemTable}>
        <div class={classNames(styles.item, styles.itemHeading)}>
          <span>Item</span>
          <span>Quantity</span>
        </div>
        <For each={props.transaction.denominationDetails}>
          {(denomination) => (
            <div class={styles.item}>
              <span>
                {toRupees(denomination.denomination)}{" "}
                {props.transaction.brandDetails.brandName} card
              </span>
              <span>{denomination.quantity}</span>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

function DonationDetailCard(props: {
  transaction: TransactionDetailV4Response;
  donationDetails?: DonationDetails;
}) {
  return (
    <div>
      <Show when={props.donationDetails}>
        <div class="flex flex-col gap-3 bg-white p-5 border rounded-2xl">
          <div class="text-bold">Donation details</div>
          <Divider />
          <div class="flex justify-between text-mediumBold text-successDark">
            <div>
              Total donation (
              {
                props.transaction.amountBreakup.discountDetails.hubbleDiscount
                  .percentage
              }
              %)
            </div>
            <div>
              {toRupees(
                props.transaction.amountBreakup.discountDetails.hubbleDiscount
                  .amount
              )}
            </div>
          </div>
          <div class="text-medium">{props.donationDetails?.name}</div>
        </div>
      </Show>
    </div>
  );
}

function BillDetailsCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class={styles.billDetailsCard}>
      <span class={styles.heading}>Bill details</span>
      <div class={styles.billTable}>
        <div class={styles.brandCardWorth}>
          <span>Brand card worth</span>
          <span>{toRupees(props.transaction.amount)}</span>
        </div>
        <Show when={props.transaction.useCoinsToFundBaseDiscount}>
          <CoinsWhenUseCoinsToFundBaseDiscount
            transaction={props.transaction}
          />
        </Show>
        <Show
          when={
            props.transaction.amountBreakup.discountDetails.couponDiscount
              .amount > 0
          }
        >
          <div class={styles.brandCardWorth}>
            <span>
              Coupon discount (
              {props.transaction.amountBreakup.discountDetails.code})
            </span>
            <span>
              {"-" +
                toRupees(
                  props.transaction.amountBreakup.discountDetails.couponDiscount
                    .amount
                )}
            </span>
          </div>
        </Show>
        <Divider />
        <div class={styles.bill}>
          <span class={styles.method}>
            You paid
            <span class={styles.methodName}>
              {props.transaction.paymentMode}
            </span>
          </span>
          <span>{toRupees(props.transaction.amountBreakup.paidAmount)}</span>
        </div>
      </div>
    </div>
  );
}

type CoinsWhenUseCoinsToFundBaseDiscountProps = {
  transaction: TransactionDetailV4Response;
};

function CoinsWhenUseCoinsToFundBaseDiscount(
  props: CoinsWhenUseCoinsToFundBaseDiscountProps
) {
  const { data } = useClassicContext();
  const clientId =
    data.isLoggedIn && data.mode == "sdk" ? data.initParams.clientId : "hubble";
  const coinIconUrl = data.isLoggedIn
    ? data.clientConfig.coinIconUrl
    : defaultCoin;

  const coinToInrConversionRatio =
    props.transaction.coinToInrConversionRatio ?? 1;

  const coinLabel = getCoinLabelCapitalized(clientId);
  const coinLabelPluralized = getCoinLabelCapitalizedPluralized(clientId);

  const discountAmount =
    props.transaction.amountBreakup.discountDetails.hubbleDiscount.amount;
  const coinsUsed =
    props.transaction.coinsDebited ?? discountAmount / coinToInrConversionRatio;
  return (
    <div class="flex flex-col py-2">
      <div class="flex flex-row justify-between py-1 text-mediumBolder text-successDark space-between">
        <span class="flex flex-row items-center gap-1">
          {coinLabel} discount (
          <img src={coinIconUrl} class="w-4 h-4" alt="coin icon" /> {coinsUsed})
        </span>
        <span>-{toRupees(discountAmount)}</span>
      </div>

      <div class="flex flex-row items-center gap-1 text-medium text-textNormal">
        <img src={coinIconUrl} class="w-4 h-4" alt="coin icon" />{" "}
        {1 / coinToInrConversionRatio} {coinLabelPluralized} = ₹1
      </div>
    </div>
  );
}

function OrderDetailsCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class={styles.orderDetailsCard}>
      <span class={styles.heading}>Order details</span>
      <div class={styles.orderTable}>
        <div
          class={styles.orderItem}
          onClick={async () => {
            await copyText({
              text: props.transaction.orderId,
            });
          }}
        >
          <span class={styles.itemName}>Order ID</span>
          <span class={styles.itemValue}>
            {props.transaction.orderId} <PhosphorIcon name="copy-simple" />
          </span>
        </div>
        <div
          class={styles.orderItem}
          onClick={async () => {
            await copyText({
              text: props.transaction.id,
            });
          }}
        >
          <span class={styles.itemName}>Transaction ID</span>
          <span class={styles.itemValue}>
            {props.transaction.id} <PhosphorIcon name="copy-simple" />
          </span>
        </div>
        <div class={styles.orderItem}>
          <span class={styles.itemName}>Purchase date</span>
          <span class={styles.itemValue}>
            {formatDate(new Date(props.transaction.dateTime))}
          </span>
        </div>
      </div>
    </div>
  );
}

function InvoiceCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class={styles.invoiceCard}>
      <div
        class={styles.invoiceItem}
        onClick={() => {
          pushPage(
            () => <InvoicePage url={props.transaction.receiptLink} />,
            {
              isModal: true,
            },
            HubbleRoute.INVOICE
          );
        }}
      >
        <PhosphorIcon name="file-text" />
        <span class={styles.itemValue}>View invoice</span>
      </div>

      <div class={styles.invoiceItem} onClick={openIntercom}>
        <PhosphorIcon name="chat-dots" />
        <span class={styles.itemValue}>Contact support</span>
      </div>
    </div>
  );
}

function InvoicePage(props: { url: string }) {
  return (
    <div class={styles.invoicePage}>
      <iframe src={props.url} />
    </div>
  );
}

/// format date to 12 Jun 2023 · 4:00 PM
function formatDate(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

type RefundSuccessfulProps = {
  amount: number;
  timestampStr: string;
  status: RefundStatusType;
};

function RefundStatus(props: RefundSuccessfulProps) {
  const formattedDateTime = formatDate(new Date(props.timestampStr));

  return (
    <div class="flex flex-col bg-baseTertiaryLight p-5 rounded-2xl">
      <div class="flex flex-row justify-between">
        <div class="inline-flex flex-row items-center gap-1">
          <Switch>
            <Match when={props.status == "SUCCESS"}>
              <PhosphorIcon
                class="text-successDark"
                name="check"
                size="bold"
                fontSize={13}
              />

              <span class="text-mediumBold text-successDark">
                Refund successful
              </span>
            </Match>
            <Match when={true}>
              <span class="text-mediumBold text-successDark">
                Refund initiated
              </span>
            </Match>
          </Switch>
        </div>

        <div class="text-f12 text-textNormal">{formattedDateTime}</div>
      </div>

      <Spacer height={16} />
      <Divider />
      <Spacer height={12} />
      <div class="text-medium text-textDark">
        <Switch>
          <Match when={props.status == "SUCCESS"}>
            ₹{props.amount} credit back to your source account
          </Match>
          <Match when={true}>
            Takes around 3-5 business days for refund amount to credit back to
            your source account
          </Match>
        </Switch>
      </div>
    </div>
  );
}
