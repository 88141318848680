"use server";

import { getCategories, getProductSearch } from "../apis/server_apis";
import { CategoryData, CategoryResult } from "../types/category";
import { Product } from "../types/search";

export type CatalogueRouteData = {
  popularBrands: Product[];
  allBrands: Product[];
  allCategories: Array<CategoryData>;
};

export const getCatalogueRouteData = async (): Promise<CatalogueRouteData> => {
  const allBrands$P = getProductSearch({
    query: "",
    pagination: {
      limit: 10 * 6, // because squid products are 6 or 3 per row
      pageNo: 0,
    },
  });

  const allCategories$P = getCategories();

  const popularBrands$P = getProductSearch({ category: "POPULAR_BRANDS" });

  const resolved = await Promise.all([
    allBrands$P,
    allCategories$P,
    popularBrands$P,
  ]);

  const allBrands = resolved[0];
  const allCategories = resolved[1];
  const popularBrands = resolved[2];

  const sortedPopularBrands = popularBrands.data[0].products.sort((a, b) => {
    return (
      (b.voucherProduct.discountPercentage || 0) -
      (a.voucherProduct.discountPercentage || 0)
    );
  });

  let routeData = {
    allBrands: allBrands.data[0].products,
    allCategories: allCategories.data,
    popularBrands: sortedPopularBrands,
  };

  return routeData;
};
