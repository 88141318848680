import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  createSignal,
  For,
  Match,
  Show,
  Suspense,
  Switch,
  useTransition,
} from "solid-js";
import {
  emptyGiftsDraft,
  emptyGiftsToppedUp,
  logoutRedIcon,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import { GiftCard } from "~/components/pluto/gift-card/gift_card";
import { LogoutModal } from "~/components/pluto/modals/logout_modal";
import { usePluto } from "~/context/pluto_context";
import {
  getPlutoProfileRouteData,
  PlutoProfileRouteData,
} from "~/server/data/pluto_profile_route_data";
import { formatDateLong } from "~/utils/date";
import { toRupees } from "~/utils/number";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getProfileRouteData$C = cache(getPlutoProfileRouteData, "pluto_profile");

export default function PlutoProfile() {
  const routeData: Accessor<PlutoProfileRouteData | undefined> = createAsync(
    () => getProfileRouteData$C(),
    {
      deferStream: true,
    }
  );

  const { toggleSidebar } = usePluto();
  const { openModal } = useModal()!;

  const [tab, setTab] = createSignal("drafts");
  const [pending, start] = useTransition();

  const updateTab = (side: string) => () => start(() => setTab(side));

  const logoutModal = () => {
    openModal(
      () => <LogoutModal />,
      "dark lg:w-[400px]",
      "!bg-black !border-t !border-basePrimaryDark !justify-center"
    );
  };

  return (
    <Suspense
      fallback={
        <div class="w-screen lg:w-[81%]">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="flex h-full w-full flex-col overflow-y-auto bg-plutoLoginBgMob bg-cover bg-no-repeat text-white md:w-[800px] lg:w-[81%] lg:bg-none">
        <div class="flex items-center justify-between p-4 lg:hidden">
          <button
            class="flex items-center justify-center"
            onClick={toggleSidebar}
          >
            <PhosphorIcon
              name={"list"}
              size="bold"
              fontSize={24}
              class="text-white"
            />
          </button>

          <button
            class="flex cursor-pointer items-center justify-center gap-1 text-subtitleSmall text-errorDark underline"
            onClick={logoutModal}
          >
            <img src={logoutRedIcon} alt="logout" /> Logout
          </button>
        </div>
        <div class="p-4 py-0 lg:pt-4">
          <div class="mb-8 lg:flex lg:items-start lg:justify-between">
            <div>
              <h1 class="text-[28px] font-semibold text-white">
                {routeData()?.userProfile?.firstName}{" "}
                {routeData()?.userProfile?.lastName}
              </h1>
              <p class=" text-medium text-baseSecondaryLight">
                {routeData()?.userProfile?.phoneNumber}
              </p>
            </div>
            <button
              class="hidden cursor-pointer items-center gap-1 text-subtitleSmall text-errorDark underline lg:flex"
              onClick={logoutModal}
            >
              <img src={logoutRedIcon} alt="logout" /> Logout
            </button>
          </div>
          <p class="font-jakartaSans text-h5 text-white">My gifts</p>
          <div class="border-b border-basePrimaryMedium">
            <nav class="flex gap-x-3">
              <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white": tab() === "drafts",
                  "text-baseSecondaryLight": tab() !== "drafts",
                }}
                onClick={updateTab("drafts")}
              >
                Drafts
              </button>
              <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white":
                    tab() === "topped-up",
                  "text-baseSecondaryLight": tab() !== "topped-up",
                }}
                onClick={updateTab("topped-up")}
              >
                Topped up
              </button>
              {/* <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white":
                    tab() === "received",
                  "text-baseSecondaryLight": tab() !== "received",
                }}
                onClick={updateTab("received")}
              >
                Received
              </button> */}
            </nav>
          </div>
        </div>
        <div
          class="lg:ml-2"
          classList={{
            pending: pending(),
          }}
        >
          <Suspense
            fallback={
              <div class="flex w-full items-center justify-center p-8">
                <ThreeDotLoader />
              </div>
            }
          >
            <Switch>
              <Match when={tab() === "drafts"}>
                <div
                  classList={{
                    "lg:grid lg:grid-cols-3 lg:gap-2":
                      (routeData()?.notPaidGifts?.length ?? 0) > 0,
                    "flex flex-col items-center":
                      (routeData()?.notPaidGifts?.length ?? 0) === 0,
                  }}
                >
                  <Show
                    when={routeData()?.notPaidGifts?.length ?? 0 > 0}
                    fallback={
                      <div class="mx-auto mt-16 flex w-[194px] flex-col gap-4">
                        <img src={emptyGiftsDraft} alt="no drafts gifts" />
                        <p class="text-center text-medium text-textNormal">
                          Gifts without balance will appear here
                        </p>
                      </div>
                    }
                  >
                    <For each={routeData()?.notPaidGifts}>
                      {(gift) => {
                        return (
                          <div class="mt-4">
                            <GiftCard
                              giftDetails={() => gift}
                              flipOnCard={true}
                              showActions={true}
                              showNavigationDots={true}
                              showHeader={true}
                              generatedOnDate={formatDateLong(
                                new Date(gift.generatedOn)
                              )}
                            />
                          </div>
                        );
                      }}
                    </For>
                  </Show>
                </div>
              </Match>
              <Match when={tab() === "topped-up"}>
                <div
                  classList={{
                    "lg:grid lg:grid-cols-3 lg:gap-2":
                      (routeData()?.paidGifts?.length ?? 0) > 0,
                    "flex flex-col items-center":
                      (routeData()?.paidGifts?.length ?? 0) === 0,
                  }}
                >
                  <Show
                    when={routeData()?.paidGifts?.length ?? 0 > 0}
                    fallback={
                      <div class="mx-auto mt-16 flex w-[194px] flex-col gap-4">
                        <img
                          src={emptyGiftsToppedUp}
                          alt="no topped up gifts"
                        />
                        <p class="text-center text-medium text-textNormal">
                          Ready to share gifts will appear here
                        </p>
                      </div>
                    }
                  >
                    <For each={routeData()?.paidGifts}>
                      {(gift) => {
                        return (
                          <div class="mt-4">
                            <GiftCard
                              giftDetails={() => gift}
                              flipOnCard={true}
                              showActions={true}
                              showNavigationDots={true}
                              showHeader={true}
                              generatedOnDate={formatDateLong(
                                new Date(gift.generatedOn)
                              )}
                              budget={toRupees(gift.budget)}
                            />
                          </div>
                        );
                      }}
                    </For>
                  </Show>
                </div>
              </Match>
              {/* <Match when={tab() === "received"}>
                <div class="text-white">received gifts</div>
              </Match> */}
            </Switch>
          </Suspense>
        </div>
      </div>
    </Suspense>
  );
}
