"use server";

import { getSquidTransactions } from "../apis/server_apis";
import { SquidTransactionResponse } from "../types/order";

export type SquidPurchaseRouteData = {
  purchaseOrders: SquidTransactionResponse;
};

export const getSquidPurchaseRouteData =
  async (): Promise<SquidPurchaseRouteData> => {
    const purchaseOrders = await getSquidTransactions();
    return { purchaseOrders };
  };
