import { config } from "~/data/config";
import { ThreeDotLoader } from "./button";
import "./loader.css";
import { Show } from "solid-js";

type DottedLoaderProps = {
  color?: string;
  from?: string;
};

export function DottedLoader(props: DottedLoaderProps) {
  let c = "";
  if (props.color === undefined) {
    c = "!bg-baseDark";
  }
  return (
    <div class="left-0 top-0 z-20 flex h-screen w-full flex-col items-center justify-center gap-2">
      <Show when={config.uiLogging.loaderFromContext === 'enabled'}>
        <span class="text-h5">{props.from}</span>
      </Show>
      <ThreeDotLoader color={props.color ?? "#000"} class={c} />
    </div>
  );
}

type CircularLoaderProps = {
  size: number;
};

export function CircularLoader(props: CircularLoaderProps) {
  const { size } = props;

  return (
    <div
      class={`loader`}
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
    ></div>
  );
}
