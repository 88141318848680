import { For, Match, Show, Switch } from "solid-js";
import {
  RedemptionTransaction,
  getRedemptionTransactions,
} from "../../data/products";
import { ResourceLoader } from "../../widgets/resource_loader";
import styles from "./transactions.module.scss";
import { PhosphorIcon } from "../../widgets/icons";
import { BackButton } from "../../widgets/button";
import { TransactionDetail } from "./transaction_detail";
import { HubbleRoute, pushPage } from "../../shared_states/modal";
import { formatDate } from "~/utils/date";
import { emptyStateDecoration } from "~/assets/assets";
import { EmptyState } from "~/widgets/empty_state";

type CollatedTransactionStatus =
  | "generating"
  | "generated"
  | "refundIntiaited"
  | "refundFailed"
  | "refunded";

type UIRedemptionTransaction = RedemptionTransaction & {
  collatedStatus: CollatedTransactionStatus;
  formattedDateTime: string;
};

function getTransactionStatus(
  transaction: RedemptionTransaction
): CollatedTransactionStatus {
  switch (transaction.refundStatus) {
    case "INITIATED":
    case "PENDING":
      return "refundIntiaited";
    case "FAILED":
      return "refundFailed";
    case "SUCCESS":
      return "refunded";
    default:
      break;
  }

  switch (transaction.orderStatus) {
    case "INITIALISED":
    case "PROCESSING":
      return "generating";
    default:
      return "generated";
  }
}

export function RedemptionTransactions() {
  return (
    <ResourceLoader
      fetch={() =>
        getRedemptionTransactions()
          .then((e) => e.data)
          .then((transactions) => {
            return transactions.map((transaction: RedemptionTransaction) => {
              return {
                ...transaction,
                collatedStatus: getTransactionStatus(transaction),
                formattedDateTime: formatDate(new Date(transaction.dateTime)),
              } as UIRedemptionTransaction;
            });
          })
      }
    >
      {(transactions) => {
        return (
          <div class={styles.transactions}>
            <span class={styles.pageTitle}>Transactions</span>
            <BackButton />
            <div class={`${styles.transactionList} no-scrollbar`}>
              <Show
                when={transactions.length > 0}
                fallback={
                  <EmptyState
                    primaryText="No transactions yet"
                    secondaryText="Brand card you purchase will appear here."
                    classList="h-screen"
                  />
                }
              >
                <For each={transactions}>
                  {(transaction) => {
                    return (
                      <div
                        class={styles.transactionCard}
                        onClick={() => {
                          pushPage(
                            () => (
                              <TransactionDetail
                                transactionId={transaction.id}
                              />
                            ),
                            undefined,
                            HubbleRoute.TRANSACTION_L2
                          );
                        }}
                      >
                        <img
                          class={styles.brandLogo}
                          src={transaction.logoImageUrl}
                        />
                        <div class={styles.content}>
                          <span class={styles.title}>
                            {transaction.description}{" "}
                            <PhosphorIcon
                              name="caret-right"
                              class={styles.tapArrow}
                              size="bold"
                            />
                          </span>
                          <span class={styles.subtitle}>
                            {transaction.formattedDateTime}
                            <TransactionStatusMessage
                              transaction={transaction}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  }}
                </For>
              </Show>
            </div>
          </div>
        );
      }}
    </ResourceLoader>
  );
}

function TransactionStatusMessage(props: {
  transaction: UIRedemptionTransaction;
}) {
  return (
    <span class={styles.transactionStatusMessage}>
      <Switch>
        <Match when={props.transaction.collatedStatus == "generating"}>
          <span class={styles.generatingVoucher}>Generating ... </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refundIntiaited"}>
          <span class={styles.refundIntiaited}> Refund Initiated </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refunded"}>
          <span class={styles.refunded}>
            <PhosphorIcon name="check" size="bold" class={styles.icon} />{" "}
            Refunded{" "}
          </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refundFailed"}>
          <span class={styles.refundFailed}> Refund Failed </span>
        </Match>
      </Switch>
    </span>
  );
}
