import { A, useLocation, useNavigate } from "@solidjs/router";
import { createMemo, For, Match, Show, Switch } from "solid-js";
import {
  giftIcon,
  giftWhiteIcon,
  novaLinkGradient,
  plutoMainLogoV2,
  supportWhiteIcon,
  userWhiteCircle,
} from "~/assets/assets";
import { usePluto } from "~/context/pluto_context";
import { PhosphorIcon } from "~/widgets/icons";
import { Appbar } from "./app_bar";
import { openIntercom } from "~/utils/third_party/intercom";

export const Sidebar = () => {
  const location = useLocation();

  const { plutoGifts, clearNewGift, toggleSidebar, closeSidebar } = usePluto();

  const isProfilePage = () => location.pathname.includes("profile");

  const conversationsDetails = createMemo(() => {
    return plutoGifts?.conversations;
  });

  const navigate = useNavigate();

  return (
    <>
      <Switch>
        <Match when={!isProfilePage()}>
          <div class="fixed left-0 top-0 z-20 flex w-full items-center justify-center bg-black p-4 text-white md:hidden">
            <button
              onClick={toggleSidebar}
              class={`left-4 top-4 z-50 md:hidden ${plutoGifts.isSidebarOpen ? "hidden" : "fixed"} `}
            >
              <PhosphorIcon
                name={"list"}
                size="bold"
                fontSize={24}
                class="text-white"
              />
            </button>

            <div class="flex items-center gap-2">
              <img src={plutoMainLogoV2} alt="Star Logo" class="w-[133px]" />
            </div>
          </div>
        </Match>
      </Switch>

      <div
        class={`fixed inset-y-0 left-0 z-40 w-[280px] transform bg-[#030203] transition-transform duration-300 ease-in-out lg:bg-plutoSidebarBg lg:bg-auto lg:bg-no-repeat ${
          plutoGifts.isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0`}
      >
        <div class="flex h-full w-full flex-col border-r border-[#232323] px-3 py-2">
          <div class="flex h-12 items-center justify-between">
            <Appbar class="w-[133px]" />
            <button onClick={toggleSidebar} class="md:hidden">
              <PhosphorIcon
                name={"x"}
                size="bold"
                fontSize={18}
                class="text-white"
              />
            </button>
          </div>
          <div class="border-b border-basePrimaryDark pb-4 pt-3">
            <A
              href="/pluto/create/new"
              onClick={() => {
                clearNewGift();
                closeSidebar();
              }}
              class="flex h-10 w-full items-center justify-center gap-1 rounded-[41px] bg-baseTertiaryMedium px-3 lg:border lg:border-white lg:bg-transparent"
            >
              <img src={giftIcon} alt="gift icon" class="lg:hidden" />
              <img
                src={giftWhiteIcon}
                alt="gift icon"
                class="hidden lg:block"
              />
              <p class="pt-0.5 text-buttonMedium font-bold text-textDark lg:text-white">
                Create new gift
              </p>
            </A>
          </div>
          <nav class="flex-1">
            <p class="px-3 py-4 text-smallBold uppercase tracking-[1.1px] text-textNormal">
              My gifts
            </p>
            <Show when={conversationsDetails()}>
              <ul class="overflow-y-auto">
                <Show
                  when={conversationsDetails()?.length ?? 0 > 0}
                  fallback={
                    <div
                      onClick={() => {
                        clearNewGift();
                        closeSidebar();
                        navigate("/pluto/create/new");
                      }}
                    >
                      <Show
                        when={!isProfilePage()}
                        fallback={
                          <div
                            class={`relative flex cursor-pointer rounded-lg px-3 py-2.5 text-mediumBold text-[#96869E] hover:text-white`}
                          >
                            Gift 1
                          </div>
                        }
                      >
                        <div class="relative flex cursor-pointer rounded-lg bg-[#281D2D] px-3 py-2.5 text-mediumBold text-white hover:text-white">
                          <img
                            src={novaLinkGradient}
                            class="absolute left-0 top-0 h-full"
                            alt=""
                          />
                          Gift 1
                        </div>
                      </Show>
                    </div>
                  }
                >
                  <For each={conversationsDetails()}>
                    {(chat, index) => {
                      return (
                        <A
                          href={`/pluto/create/chat/${chat.id}`}
                          onClick={() => {
                            clearNewGift();
                            closeSidebar();
                          }}
                          class={`relative flex rounded-lg px-3 py-2.5 text-mediumBold hover:text-white`}
                          activeClass="bg-[#281D2D] text-white"
                          inactiveClass="text-[#96869E]"
                        >
                          <Show
                            when={
                              chat.id ===
                              location.pathname.split("/pluto/create/chat/")[1]
                            }
                          >
                            <img
                              src={novaLinkGradient}
                              class="absolute left-0 top-0 h-full"
                              alt=""
                            />
                          </Show>
                          {chat.status === "COMPLETED"
                            ? chat.title
                            : `Gift ${index() + 1}`}
                        </A>
                      );
                    }}
                  </For>
                </Show>
              </ul>
            </Show>
          </nav>
          <div class="sticky bottom-0 bg-[#030203] pt-4">
            <div class="flex cursor-pointer items-center gap-1 px-2 py-2.5">
              <img src={supportWhiteIcon} alt="" />
              <p
                class="text-mediumBold text-white"
                onClick={() => {
                  openIntercom();
                }}
              >
                Help & support
              </p>
            </div>
            <A
              href="/pluto/create/profile"
              onClick={closeSidebar}
              class="flex cursor-pointer items-center gap-1 px-2 py-2.5"
            >
              <img src={userWhiteCircle} alt="profile" class="h-5 w-5" />
              <Show when={plutoGifts?.userName}>
                <p class="text-mediumBold text-white">{plutoGifts?.userName}</p>
              </Show>
            </A>
          </div>
        </div>
      </div>

      {plutoGifts.isSidebarOpen && (
        <div
          class="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};
