"use server";

import { redirect } from "@solidjs/router";
import { getCookie } from "vinxi/http";
import { getPlutoReceiverSessionIdStorageKey } from "~/components/gifting/pluto-receiver/pluto-receiver-landing/pluto_reveal_route_data";
import {
  getCoinsSummary,
  getGiftBoxV3,
  getPlutoGiftPublic,
} from "~/server/apis/server_apis";
import { GetGiftBoxV3Response } from "~/server/types/gift";
import { Header } from "~/types";
import { APIError } from "~/utils/fetch";
import { toRupees } from "~/utils/number";

export type PlutoRedeeemRouteDataResponse = {
  heading: string;
  description: string;
  balance: number;
  totalAmount: number;
  brands: PlutoRedeemBrandsData[];
  isRtu: boolean;
};

type PlutoRedeemBrandsData = {
  imageUrl: string;
  heading: string;
  subHeading: string;
  brandId: string;
};

export default async function PlutoGiftDetailsRouteData(props: {
  giftingKey: string;
  isPreview: boolean;
  amount?: number;
}): Promise<PlutoRedeeemRouteDataResponse> {
  if (props.isPreview) {
    let plutoGiftDetails = await getPlutoGiftPublic(props.giftingKey);
    let giftMetadata = {
      plutoGiftDetails: plutoGiftDetails,
      amount: props.amount,
    } as GetGiftBoxV3Response;
    return {
      isRtu: false,
      heading: getHeading(giftMetadata),
      description: getDescription(giftMetadata),
      balance: props.amount,
      totalAmount: giftMetadata.amount,
      brands: getBrands(giftMetadata),
    } as PlutoRedeeemRouteDataResponse;
  }

  let sessionId = getCookie(
    getPlutoReceiverSessionIdStorageKey(props.giftingKey)
  );
  if (!sessionId) {
    throw redirect("/pluto/reveal/" + props.giftingKey);
  }

  let giftMetadata$P = getGiftBoxV3(
    props.giftingKey,
    {
      [Header.SessionId]: sessionId,
      [Header.FpDeviceToken]: "",
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([coinsCount$P, giftMetadata$P]);

    let coinsSummary = resolved[0];
    let giftMetadata = resolved[1];

    return {
      isRtu: coinsSummary.totalConsumed > 0,
      heading: getHeading(giftMetadata),
      description: getDescription(giftMetadata),
      balance: coinsSummary.totalAvailable,
      totalAmount: giftMetadata.amount,
      brands: getBrands(giftMetadata),
    } as PlutoRedeeemRouteDataResponse;
  } catch (error) {
    if (error instanceof APIError) {
      if (error.code === 401) {
        throw redirect("/pluto/reveal/" + props.giftingKey);
      }
    }
    throw error;
  }
}

function getBrands(giftMetadata: GetGiftBoxV3Response) {
  let list = (
    giftMetadata.plutoGiftDetails?.activities
      ? Object.values(giftMetadata.plutoGiftDetails?.activities)
      : []
  )
    .map((item) => {
      let brand = giftMetadata.plutoGiftDetails?.brandDetails.find(
        (brand) => brand.brandKey === item.brandKey
      );
      return {
        ...item,
        ...brand,
      };
    })
    .filter((item) => item.logoUrl);

  return list.map((item) => {
    return {
      imageUrl: item.logoUrl,
      heading: item.description,
      subHeading: item.usageIdea,
      brandId: item.brandId,
    };
  });
}

function getHeading(giftMetadata: GetGiftBoxV3Response) {
  const title = giftMetadata.plutoGiftDetails?.title.title;
  const amount = giftMetadata.amount;
  const senderName = giftMetadata.plutoGiftDetails?.senderName;

  const titleText = title ?? "A gift for you";
  const amountText = amount ? ` worth ${toRupees(amount)}` : "";
  const senderText = senderName ? ` by ${senderName}` : "";

  return `${titleText}${amountText}${senderText}`;
}

function getDescription(giftMetadata: GetGiftBoxV3Response) {
  const receipientName = giftMetadata.plutoGiftDetails?.occasion.recipientName;
  const senderName = giftMetadata.plutoGiftDetails?.senderName;
  const title = giftMetadata.plutoGiftDetails?.title.title;
  const amount = giftMetadata.amount;

  const nameText = receipientName ? `Hey ${receipientName},\n\n` : "";
  const senderText = senderName
    ? `${senderName} has gifted you`
    : "You have been gifted";
  return (
    nameText +
    senderText +
    ` "${title}" worth ${toRupees(amount)} (Gift balance).\n\nYou can use the gift balance however you like among the brands they have curated for you.`
  );
}
