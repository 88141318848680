import { JSX } from "solid-js";

export default function GradientText(props: {
  gradient?: string;
  children: JSX.Element;
}) {
  return (
    <span
      class="bg-clip-text text-center text-transparent"
      style={{
        "background-image":
          props.gradient ?? "linear-gradient(to right, #C9FFBF, #FFAFBD)",
      }}
    >
      {props.children}
    </span>
  );
}
