type CopyIconProps = {
  class?: string;
};

/**
 * copy icon SVG
 *
 * @example
 * <CopyIcon class="stroke-basePrimaryDark" />
 *
 * @param {Object} props
 * @prop {string} [class] - use class to give stroke.
 */
export default function CopyIcon(props: CopyIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="5.83154"
        y="5.8316"
        width="11.6715"
        height="11.6715"
        rx="2"
        class={props.class ?? "stroke-basePrimaryDark dark:stroke-white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83155 14.1684H4.16419C3.24333 14.1684 2.49683 13.4219 2.49683 12.5011V4.16425C2.49683 3.24339 3.24333 2.49689 4.16419 2.49689H12.501C13.4219 2.49689 14.1684 3.24339 14.1684 4.16425V5.83161"
        class={props.class ?? "stroke-basePrimaryDark dark:stroke-white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
