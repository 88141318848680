import { hubbleFetch } from "~/utils/fetch";
import { config } from "./config";
import { getCompleteHeaders } from "./products";

export type DonationDetails = {
  name: string;
  imageUrl: string;
};

export async function getDonationDetails(
  transactionId: string
): Promise<DonationDetails> {
  const path = `partner/ketto/campaign-details?redemptionTransactionId=${transactionId}`;
  return await hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: {
      ...getCompleteHeaders(),
    },
    method: "GET",
  });
}
