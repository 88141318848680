import { For } from "solid-js";

export function DummyData() {
  // an array of 100 items
  const arr = Array.from({ length: 50 }, (_, i) => i);
  return (
    <>
      <div class="flex h-[500px] w-full flex-col overflow-scroll bg-blue-400">
        <For each={arr}>{(item) => <div class="my-6">{item}</div>}</For>
      </div>
      <div class="mt-2"></div>
    </>
  );
}
