import { A, useNavigate } from "@solidjs/router";
import { createSignal, onCleanup, onMount, Show } from "solid-js";
import { ButtonDark } from "~/components/gifting/verification/components";
import { CloseModalButton, useModal } from "~/components/modal";
import {
  SmartGCDetails,
  useSmartGreeting,
} from "~/context/smart_greeting_context";
import { payOrderStandardCheckout } from "~/data/products";
import {
  getUserProfile,
  initiateSmartGCPayment,
  updateSmartGCBrandSelection,
  updateSmartGCBudget,
  updateSmartGCSenderName,
  updateSmartGCWishMessage,
} from "~/server/apis/client_apis";
import { showSnackBar } from "~/shared_states/snackbar";
import { createJob } from "~/utils/job";
import { toRupees } from "~/utils/number";
import { openRazorpayCheckout } from "~/utils/third_party/razorpay";
import { encodeBase64 } from "~/utils/encoding";

export function ConfirmationsModal(props: {
  smartGCDetails: SmartGCDetails;
  giftingKey: string;
  setSmartGCDetailsToLocal: () => void;
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = createSignal(false);
  const [error, setError] = createSignal<string | undefined>(undefined);
  const { closeModal } = useModal()!;

  const updateSmartGCDataJob = createJob({
    initialJob: async () => {
      setError(undefined);
      await updateSmartGCSenderName(
        props.smartGCDetails.curationId!,
        props.smartGCDetails.senderName!
      );
      await updateSmartGCBudget(
        props.smartGCDetails.curationId!,
        Number(props.smartGCDetails.budget!)
      );
      await updateSmartGCBrandSelection(
        props.smartGCDetails.curationId!,
        props.smartGCDetails.brandDetails?.map((brands) => brands.brandKey)!
      );
      if (props.smartGCDetails.config.wishMessageEnabled) {
        await updateSmartGCWishMessage(
          props.smartGCDetails.curationId!,
          props.smartGCDetails.wishMessage!
        );
      }
    },
    successCallback: async () => {
      initiatePayment();
    },
    errorCallback: (error) => {
      showSnackBar(error.message);
      setError(error.message);
    },
  });

  async function initiatePayment() {
    setIsLoading(true);
    try {
      const initiatePaymentResponse = await initiateSmartGCPayment(
        props.smartGCDetails.curationId!
      );
      setIsLoading(false);
      if (initiatePaymentResponse.paymentReferenceId) {
        const order = await payOrderStandardCheckout(
          initiatePaymentResponse.paymentReferenceId
        );
        const profile = await getUserProfile();
        openRazorpayCheckout({
          amount: props.smartGCDetails.budget!,
          email: profile.email ?? "",
          mode: "CARD_AND_UPI",
          orderId: order.providerOrderId,
          phone: profile.phoneNumber ?? "",
          onSuccess: () => {
            closeModal();
            navigate(
              `/smart-gc/create/${props.giftingKey}/share?curationId=${props.smartGCDetails.curationId}`
            );
          },
          onFailure: () => {
            closeModal();
            navigate(
              `/smart-gc/create/${props.giftingKey}/share?curationId=${props.smartGCDetails.curationId}&paymentFailed=true`
            );
          },
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message);

      showSnackBar(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  onCleanup(() => {
    setError(undefined);
  });

  return (
    <div class="w-full rounded-t-2xl border border-basePrimaryDark ">
      <div class="p-4">
        <div class="flex flex-col  gap-2">
          <div class="flex justify-between">
            <p class="font-jakartaSans text-h3 text-white">
              Confirm & <br /> proceed to pay
            </p>
            <CloseModalButton />
          </div>
          <p class="text-medium text-textNormal">
            Please note: Changes cannot be made after payment is completed.
          </p>
        </div>
        <div class="mt-4">
          <div class="flex justify-between border-y border-basePrimaryDark py-4">
            <p class="text-medium text-textNormal">Gift balance</p>
            <p class="text-mediumBold text-white">
              {toRupees(props.smartGCDetails.budget!)}
            </p>
          </div>
          <div class="flex justify-between gap-24 border-b border-basePrimaryDark py-4">
            <p class="text-medium text-textNormal">Brands</p>
            <p class="text-end text-mediumBold text-white">
              {props.smartGCDetails.brandDetails
                ?.map((brand) => brand.name)
                ?.reduce((acc, brand, index, arr) => {
                  if (index === 0) return brand;
                  if (index === arr.length - 1) return `${acc} & ${brand}`;
                  return `${acc}, ${brand}`;
                }, "")}
            </p>
          </div>
          <div class="flex justify-between border-b border-basePrimaryDark py-4">
            <p class="text-medium text-textNormal">Your name</p>
            <p class="text-mediumBold text-white">
              {props.smartGCDetails.senderName}
            </p>
          </div>
        </div>
      </div>
      <Show when={error()}>
        <div class="my-1 px-4 text-medium text-errorDark">{error()}</div>
      </Show>
      <div class="flex items-center justify-between gap-3 p-4">
        <div
          class="flex h-11 w-[167px] items-center justify-center rounded-[41px] border border-baseSecondaryDark  px-3 text-buttonMedium"
          onClick={() => {
            closeModal();
            props.setSmartGCDetailsToLocal();
            const state = {
              senderName: props.smartGCDetails.senderName,
              budget: props.smartGCDetails.budget,
              brandDetails: props.smartGCDetails.brandDetails,
              receiverCardImageUrl: props.smartGCDetails.receiverCardImageUrl,
              receiverCardBackgroundColour:
                props.smartGCDetails.receiverCardBackgroundColour,
              ...(props.smartGCDetails.config.wishMessageEnabled && {
                wishMessage: props.smartGCDetails.wishMessage,
                revealMusicUrl: props.smartGCDetails.revealMusicUrl,
                wishMessageBackgroundImageUrl:
                  props.smartGCDetails.wishMessageBackgroundImageUrl,
              }),
            };
            navigate(
              "/smart-gc/reveal/" +
                props.giftingKey +
                `?isPreview=true&data=${encodeBase64(JSON.stringify(state))}`
            );
          }}
        >
          <p class="text-nowrap text-buttonMedium text-white">
            Receiver’s preview
          </p>
        </div>
        <ButtonDark
          class="flex h-11 w-[167px] cursor-pointer items-center justify-center !rounded-[41px] bg-baseTertiaryMedium px-3"
          isLoading={
            updateSmartGCDataJob.jobState() === "running" || isLoading()
          }
          onClick={() => {
            updateSmartGCDataJob.run();
          }}
        >
          <p class="text-buttonMedium font-bold text-textDark">
            Pay {toRupees(props.smartGCDetails.budget!)}
          </p>
        </ButtonDark>
      </div>
    </div>
  );
}
