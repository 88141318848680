"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getSquidWalletBalance,
  getProductSearch,
  getUserProfile,
  getCategories,
} from "../apis/server_apis";
import { SearchResults } from "../types/search";
import { deleteCookie, getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";
import { APIError } from "~/utils/fetch";
import { CategoryData } from "../types/category";

export type SquidContextData = {
  userProfile: {
    name: string;
    logo: string;
    email: string;
    phone: string;
  };
  balance: number;
};

export const getSquidContextData = async (): Promise<SquidContextData> => {
  const requestEvent = getRequestEvent();

  if (requestEvent) {
    let sid =
      requestEvent?.locals?.sid ??
      getCookie(requestEvent?.nativeEvent, Cookie.SessionId);
    if (!sid) {
      throw redirect("/login");
    }
  }

  try {
    const userProfile$P = getUserProfile(undefined, { throwAuthError: true });
    const balance$P = getSquidWalletBalance({ throwAuthError: true });

    const resolved = await Promise.all([userProfile$P, balance$P]);

    const userProfile = resolved[0];
    const { balance } = resolved[1];

    let routeData: SquidContextData = {
      userProfile: {
        name:
          userProfile.firstName && userProfile.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : "Team",
        logo: requestEvent?.locals.clientLogoUrl,
        email: userProfile.email ?? "",
        phone: userProfile.phoneNumber ?? "",
      },

      balance,
    };

    return routeData;
  } catch (error) {
    if (error instanceof APIError) {
      if (requestEvent)
        deleteCookie(requestEvent.nativeEvent, Cookie.SessionId);

      throw redirect("/login");
    } else {
      throw error;
    }
  }
};
