import { Cookie } from "~/types";
import { isSafari } from "./detectDevice";

export function setCookie(params: {
  key: Cookie | string;
  value: string;
  expiryInDays?: number;
  expiryInMinutes?: number;
}) {
  let expiryInSeconds = 0;
  if (params.expiryInDays) {
    expiryInSeconds = expiryInSeconds + params.expiryInDays * 24 * 60 * 60;
  }
  if (params.expiryInMinutes) {
    expiryInSeconds = expiryInSeconds + params.expiryInMinutes * 60;
  }
  if (!expiryInSeconds) {
    expiryInSeconds = 60 * 60; // default expiry time is 1 hour
  }

  let expiryTime = new Date().getTime() + expiryInSeconds * 1000;
  let d = new Date(expiryTime);
  let expires = "expires=" + d.toUTCString();

  const isSafariBrowser = isSafari();
  const cookieSettings = isSafariBrowser
    ? { sameSite: "Lax", secure: false }
    : { sameSite: "Strict", secure: true };

  const cookieString = `${params.key}=${params.value}; ${expires}; path=/; SameSite=${cookieSettings.sameSite}${cookieSettings.secure ? "; Secure" : ""}`;
  document.cookie = cookieString;

  const cookieValue = getCookie(params.key);

  // If cookie wasn't set and we're not in localhost, try with domain
  if (!cookieValue && window.location.hostname !== "localhost") {
    const hostname = window.location.hostname;
    const cookieWithDomain = `${params.key}=${params.value}; ${expires}; path=/; domain=${hostname}; SameSite=${cookieSettings.sameSite}${cookieSettings.secure ? "; Secure" : ""}`;
    document.cookie = cookieWithDomain;
  }
}

export function getCookie(key: Cookie | string): string | undefined {
  let name = key + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export function checkCookie(key: Cookie): boolean {
  let cookie = getCookie(key);
  if (cookie && cookie != "") {
    return true;
  }
  return false;
}

export function clearCookie(key: Cookie | string) {
  const isSafariBrowser = isSafari();
  const sameSite = isSafariBrowser ? "Lax" : "Strict";
  const secure = !isSafariBrowser;

  document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=${sameSite}${secure ? "; Secure" : ""}`;
}
