import { cache, createAsync, useNavigate, useParams } from "@solidjs/router";
import { Accessor, createSignal, Match, onMount, Show, Switch } from "solid-js";
import SmartGreetingLoginComponent from "~/components/smart_greeting/login";
import { TopUpComponent } from "~/components/smart_greeting/top_up/top_up_component";
import { useSmartGreeting } from "~/context/smart_greeting_context";
import {
  getSmartGCRouteData,
  TopUpRouteData,
} from "~/server/data/smart_greeting/top_up_route_data";
import { Cookie } from "~/types";
import { getCookie } from "~/utils/client_cookie";

export type ViewState = "top_up" | "login";

const getSmartGCRouteData$C = cache(
  getSmartGCRouteData,
  "smart_greeting_top_up"
);

export default function TopUpRoute() {
  const params = useParams();
  const [view, setView] = createSignal<ViewState>("login");
  const { setSmartGCDetails } = useSmartGreeting();
  const navigate = useNavigate();

  const routeData: Accessor<TopUpRouteData | undefined> =
    createAsync<TopUpRouteData>(
      async () => {
        return await getSmartGCRouteData$C(params.giftingKey$);
      },
      { deferStream: true }
    );

  onMount(() => {
    //Once activate need to redirect to receiver's side
    if (routeData()?.giftingMetadata.status != "NOT_ACTIVATED") {
      navigate(`/smart-gc/reveal/${params.giftingKey$}`, {
        replace: true,
      });
    }
    if (routeData()?.isLoggedIn || getCookie(Cookie.SessionId)) {
      setView("top_up");
    }
  });

  onMount(() => {
    if (routeData()) {
      setSmartGCDetails(
        "curationId",
        routeData()?.giftingMetadata.metadata.curationId!
      );
      setSmartGCDetails(
        "senderCardImageUrl",
        routeData()?.giftingMetadata.metadata.senderCardImageUrl!
      );
      setSmartGCDetails(
        "receiverCardImageUrl",
        routeData()?.giftingMetadata.metadata.receiverCardImageUrl!
      );
      setSmartGCDetails(
        "receiverCardBackgroundColour",
        routeData()?.giftingMetadata.metadata.receiverCardBackgroundColour!
      );
    }
  });

  return (
    <Show when={routeData()}>
      <Switch>
        <Match when={view() === "login"}>
          <SmartGreetingLoginComponent setView={setView} />
        </Match>
        <Match when={view() === "top_up"}>
          <TopUpComponent giftingKey={params.giftingKey$} />
        </Match>
      </Switch>
    </Show>
  );
}
