import { JSX } from "solid-js";

export const squidCategoryIcons: Partial<Record<string, () => JSX.Element>> = {
  FOOD: FoodIcon,
  TRAVEL_AND_TRANSPORT: TravelIcon,
  JEWELLERY: JewelleryIcon,
  FASHION: FashionIcon,
  ELECTRONICS: ElectronicsIcon,
  FURNISHING: FurnishingIcon,
  BEAUTY: BeautyIcon,
  HOTELS: HotelsIcon,
  LUXURY: LuxuryIcon,
  FITNESS: FitnessIcon,
};

function FoodIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M14.8341 4.99731V5.52539C14.8341 5.83602 14.9064 6.14237 15.0453 6.4202L15.6233 7.5761C15.7622 7.85393 15.8345 8.16028 15.8345 8.4709V9.9994"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0842 4.99728H4.57983"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.82988 4.99731V5.52539C4.82988 5.83602 4.75756 6.14237 4.61865 6.4202L4.04071 7.5761C3.90179 7.85393 3.82947 8.16028 3.82947 8.4709V18.0027C3.82947 19.6603 5.17317 21.004 6.83072 21.004H7.83113"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8345 8.99899H3.82947"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.83197 18.4037V16.9006C9.83197 15.8662 10.2428 14.8742 10.9742 14.1427C11.7056 13.4113 12.6977 13.0005 13.7321 13.0005H17.9368C18.9712 13.0005 19.9633 13.4113 20.6947 14.1427C21.4261 14.8741 21.837 15.8662 21.837 16.9006V18.4037C21.8372 19.0934 21.5633 19.7548 21.0756 20.2425C20.588 20.7301 19.9265 21.004 19.2369 21.0038H12.4321C11.7424 21.004 11.081 20.7301 10.5933 20.2425C10.1057 19.7548 9.83178 19.0934 9.83197 18.4037Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.8374 17.5023L8.8316 17.5025"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.83197 4.9971L10.1514 2.84921C10.2241 2.35886 10.6451 1.99579 11.1408 1.99585H13.3334"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function TravelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.0554 15V9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.05542 9C9.05542 5.7 10.3554 3 12.0554 3C13.7554 3 15.0554 5.7 15.0554 9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.15542 18L6.05542 21"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9554 18L17.0554 21"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.55542 17.9999H15.6554C16.7554 17.9999 17.7554 17.5999 18.6554 16.9999L20.4554 15.5999C20.9554 15.1999 21.1554 14.4999 20.9554 13.8999V13.8999C20.6554 12.8999 19.4554 12.5999 18.6554 13.1999L16.8554 14.5999C16.5554 14.8999 16.0554 14.9999 15.6554 14.9999H4.55542C3.75542 14.9999 3.05542 15.6999 3.05542 16.4999V16.4999C3.05542 17.2999 3.75542 17.9999 4.55542 17.9999Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.05542 9H20.0554C20.0554 6 16.0554 3 12.0554 3C8.05542 3 4.05542 6 4.05542 9Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function JewelleryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2638 14C14.1098 14 14.6778 14.745 14.6778 15.439C14.6778 16.847 12.3208 18 12.2778 18C12.2348 18 9.87781 16.847 9.87781 15.439C9.87781 14.745 10.4458 14 11.2918 14C11.7758 14 12.0938 14.226 12.2778 14.428C12.4618 14.226 12.7798 14 13.2638 14Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2778 12V14.43"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2778 20.9969C7.11083 20.9969 2.85983 17.0779 2.33383 12.0509C2.27683 11.5039 2.66283 11.0089 3.20783 10.9429C6.49783 10.5419 8.93283 7.98294 9.24283 3.90194C9.28183 3.38994 9.72483 3.00194 10.2378 3.00394L12.2778 3.00994L14.3178 3.00294C14.8318 3.00094 15.2748 3.38994 15.3128 3.90194C15.6228 7.98294 18.0578 10.5409 21.3468 10.9429C21.8928 11.0099 22.2798 11.5049 22.2218 12.0529C21.6958 17.0789 17.4438 20.9969 12.2778 20.9969Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4968 9.10303C16.2588 10.857 14.3958 12 12.2778 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.05884 9.10303C8.29684 10.857 10.1598 12 12.2778 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function FashionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M18.49 12L18.4901 19.5462C18.5001 20.4446 17.9025 21.2364 17.0359 21.4733C14.045 22.1811 10.9301 22.1811 7.93922 21.4733C7.0726 21.2365 6.47501 20.4446 6.48492 19.5462L6.48501 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0015 3.01636V3.01636C16.0015 3.80707 15.6873 4.56539 15.1282 5.12451C14.5691 5.68362 13.8108 5.99773 13.0201 5.99773H11.9799C11.1892 5.99773 10.4309 5.68362 9.87176 5.12451C9.31264 4.56539 8.99854 3.80707 8.99854 3.01636V3.01636"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.48489 19.003H3.49627C2.94375 19.003 2.49585 18.5551 2.49585 18.0026V12.5187C2.49585 12.1744 2.54029 11.8315 2.62806 11.4986L3.86611 6.80256C4.16781 5.65818 4.96059 4.70604 6.03139 4.20204L7.78351 3.37736C8.3166 3.12644 8.89851 2.99634 9.48769 2.99634H15.607C16.1962 2.99634 16.7781 3.12644 17.3112 3.37736L19.0353 4.18884C20.1221 4.70041 20.9217 5.67317 21.2132 6.83852L22.3846 11.522C22.464 11.8395 22.5042 12.1656 22.5042 12.4929V18.0026C22.5042 18.5551 22.0563 19.003 21.5038 19.003H18.4901"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function ElectronicsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M13.7222 17H4.72217C3.6176 17 2.72217 16.1046 2.72217 15V6C2.72217 4.89543 3.6176 4 4.72217 4H18.7222C19.8267 4 20.7222 4.89543 20.7222 6V8"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.72217 20H10.7222"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="13.7222"
        y="8"
        width="9"
        height="13"
        rx="1.29601"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="18.2222"
        cy="17"
        r="1.75"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4722 11.5003C18.472 11.6383 18.36 11.7501 18.2221 11.75C18.0841 11.7499 17.9722 11.6381 17.9722 11.5001C17.9721 11.3621 18.0839 11.2502 18.2218 11.25C18.2883 11.2499 18.352 11.2763 18.3989 11.3232C18.4459 11.3702 18.4723 11.4339 18.4722 11.5003"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function FurnishingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9443 14H6.94434V11.5C6.94434 10.672 6.27234 10 5.44434 10H4.44434C3.61634 10 2.94434 10.672 2.94434 11.5V16C2.94434 17.105 3.83934 18 4.94434 18H20.9443C22.0493 18 22.9443 17.105 22.9443 16V11.5C22.9443 10.672 22.2723 10 21.4443 10H20.4443C19.6163 10 18.9443 10.672 18.9443 11.5V14Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.94434 18V20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9443 18V20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.94434 10V8C4.94434 6.343 6.28734 5 7.94434 5H17.9443C19.6013 5 20.9443 6.343 20.9443 8V10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function BeautyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16387 10.3823H9.16554C9.71805 10.3823 10.166 10.8302 10.166 11.3827V19.0029C10.166 20.1079 9.27015 21.0037 8.16512 21.0037H6.16429C5.05926 21.0037 4.16345 20.1079 4.16345 19.0029V11.3827C4.16345 10.8302 4.61135 10.3823 5.16387 10.3823Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.16382 10.3824V4.99713C5.16402 4.6183 5.37818 4.27209 5.71705 4.10275L7.71788 3.10234C8.02791 2.94741 8.39604 2.96399 8.69089 3.14616C8.98574 3.32833 9.1653 3.65013 9.16548 3.99671V10.3814"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1684 12H18.1693C19.2743 12 20.1701 12.8958 20.1701 14.0008V20.0033C20.1701 20.5558 19.7222 21.0037 19.1697 21.0037H15.168C14.6155 21.0037 14.1676 20.5558 14.1676 20.0033V14.0008C14.1676 12.8958 15.0634 12 16.1684 12Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.166 15.0014H4.16345"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function HotelsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 21V4C17 3.448 16.552 3 16 3H8C7.448 3 7 3.448 7 4V21"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 9H3C2.448 9 2 9.448 2 10V21"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 21V10C22 9.448 21.552 9 21 9H17"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 17H14.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 17V21"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 21V17"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 10.5H14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 13.5H14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 7.5H14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 13.5H7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 17H7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 13.5H19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 17H19"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 21H1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function LuxuryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0802 7.38857C11.2529 7.03837 11.6095 6.81665 12 6.81665C12.3905 6.81665 12.7471 7.03837 12.9199 7.38857L13.6702 8.90967C13.8196 9.21246 14.1084 9.42235 14.4425 9.47092L16.1208 9.71484C16.507 9.77098 16.828 10.0415 16.9487 10.4128C17.0693 10.784 16.9688 11.1915 16.6894 11.4641L15.4747 12.6488C15.2331 12.8845 15.1229 13.2239 15.18 13.5565L15.4667 15.2277C15.5326 15.6125 15.3745 16.0013 15.0587 16.2308C14.7429 16.4603 14.3241 16.4906 13.9786 16.309L12.4772 15.52C12.1784 15.363 11.8216 15.363 11.5228 15.52L10.0214 16.3091C9.67587 16.4907 9.25715 16.4604 8.94133 16.2309C8.62551 16.0014 8.46736 15.6125 8.53337 15.2277L8.82001 13.5565C8.87707 13.2239 8.76686 12.8845 8.52527 12.6488L7.31064 11.4641C7.0312 11.1915 6.9307 10.784 7.05137 10.4128C7.17205 10.0415 7.49298 9.77098 7.87927 9.71484L9.5575 9.47092C9.89163 9.42235 10.1804 9.21246 10.3298 8.90967L11.0802 7.38857Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0327 9.06496L20.0291 9.39109"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.97099 14.6087L2.96729 14.9348"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 21.5001V20.4446"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 2.5V3.55556"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5784 19.678L16.9587 18.8249"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.42156 4.32197L7.04133 5.1751"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.96729 9.06496L3.97099 9.39109"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0328 14.935L20.0291 14.6089"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5784 4.32197L16.9587 5.1751"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.42156 19.678L7.04133 18.8249"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function FitnessIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4779 7.88452L17.1151 10.5217L10.5214 17.1154L7.88425 14.4782L14.4779 7.88452Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.79676 11.972C4.23352 11.5352 4.94165 11.5352 5.37841 11.972L13.0276 19.6212C13.4644 20.058 13.4644 20.7661 13.0276 21.2029L12.6314 21.5991C12.1946 22.0359 11.4865 22.0359 11.0497 21.5991L3.40052 13.9499C2.96376 13.5131 2.96376 12.805 3.40052 12.3682L3.79676 11.972Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.79694 15.929C4.2337 15.4922 4.94183 15.4922 5.3786 15.929L9.07203 19.6224C9.5088 20.0592 9.50879 20.7673 9.07203 21.2041L8.67579 21.6003C8.23903 22.0371 7.5309 22.0371 7.09414 21.6003L3.4007 17.9069C2.96394 17.4701 2.96394 16.762 3.4007 16.3252L3.79694 15.929Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.203 13.0285C20.7662 13.4653 20.0581 13.4653 19.6213 13.0285L11.9721 5.37928C11.5354 4.94252 11.5354 4.23439 11.9721 3.79763L12.3684 3.40139C12.8051 2.96463 13.5132 2.96463 13.95 3.40139L21.5992 11.0506C22.036 11.4874 22.036 12.1955 21.5992 12.6323L21.203 13.0285Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.2033 9.07123C20.7665 9.50799 20.0584 9.50799 19.6216 9.07123L15.9282 5.3778C15.4914 4.94103 15.4914 4.2329 15.9282 3.79614L16.3244 3.3999C16.7612 2.96314 17.4693 2.96314 17.9061 3.3999L21.5995 7.09334C22.0363 7.5301 22.0363 8.23823 21.5995 8.67499L21.2033 9.07123Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function SquidCategoryFallbackSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.8073 8.19274C15.9521 8.33763 15.9521 8.57253 15.8073 8.71741C15.6624 8.8623 15.4275 8.8623 15.2826 8.71741C15.1377 8.57253 15.1377 8.33763 15.2826 8.19274C15.4275 8.04786 15.6624 8.04786 15.8073 8.19274"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.512 4H17.897C19.059 4 20 4.941 20 6.102V10.487C20 11.045 19.778 11.579 19.384 11.974L10.974 20.384C10.153 21.205 8.82199 21.205 8.00099 20.384L3.61599 15.999C2.79499 15.178 2.79499 13.847 3.61599 13.026L12.026 4.616C12.42 4.222 12.955 4 13.512 4Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.81006 10.8401L13.1601 18.1901"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function AllCategoriesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <rect
        x="1.36108"
        y="0.75"
        width="7.3"
        height="7.3"
        rx="1.25"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <rect
        x="1.36108"
        y="11.95"
        width="7.3"
        height="7.3"
        rx="1.25"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <rect
        x="12.561"
        y="0.75"
        width="7.3"
        height="7.3"
        rx="1.25"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <rect
        x="12.561"
        y="11.95"
        width="7.3"
        height="7.3"
        rx="1.25"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </svg>
  );
}
