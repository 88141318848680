import { toRupees } from "~/utils/number";
import { Meta, Title } from "@solidjs/meta";
import VerticalDragReveal from "~/components/vertical_drag_reveal";
import { ButtonDark } from "~/components/gifting/verification/components";
import { useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { createJob } from "~/utils/job";
import {
  getCoinsSummary,
  getGiftBoxV3,
  getGiftMetaData,
  getProductSearch,
  sendOtpForGiftBox,
  verifyGiftOtpV3,
} from "~/server/apis/client_apis";
import {
  GetGiftBoxV3Response,
  GiftBoxMetadata,
  SmartGCBrandDetails,
} from "~/server/types/gift";
import {
  Accessor,
  createMemo,
  createSignal,
  For,
  Match,
  onMount,
  Setter,
  Show,
  Switch,
} from "solid-js";
import { DottedLoader } from "~/widgets/loader";
import HubbleImage from "~/widgets/hubble_image";
import { useModal } from "~/components/modal";
import { AuthenticationModal } from "~/components/smart_greeting/modals/authentication_modal";
import LocalStorageUtil from "~/utils/local_storage";
import {
  getV3GiftingDataStorageKey,
  gift_v3cache_duration_in_minutes,
  storeV3GiftSessionId,
} from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { showSnackBar } from "~/shared_states/snackbar";
import { PhosphorIcon } from "~/widgets/icons";
import { clearCookie, getCookie } from "~/utils/client_cookie";
import { generateDeviceVerificationToken } from "~/utils/common";
import { Cookie, Header } from "~/types";
import { GiftBrandListingData } from "~/components/gifting/brand-collection-landing/cards_list";
import { APIError } from "~/utils/fetch";
import { HubbleFullSvg, hubbleLogoSmartGCCover } from "~/assets/assets";
import { ActivationCodeInfoModal } from "~/components/smart_greeting/modals/activation_code_info_modal";
import { collectOtp } from "~/components/gifting/verification/verification";
import { popPage } from "~/shared_states/modal";

type PreviewData = {
  senderName: string;
  budget: number;
  brandDetails: SmartGCBrandDetails[];
  receiverCardImageUrl: string;
  receiverCardBackgroundColour: string;
};

export default function SmartGcRevealLandingRoute() {
  const params = useParams();
  const giftingKey = params.giftingKey as string;

  const [errorMessage, setErrorMessage] = createSignal<string | undefined>(
    undefined
  );
  const [queryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  let previewData: PreviewData | undefined = undefined;
  const dataParam = queryParams.data;

  if (isPreview) {
    if (!dataParam) {
      throw new Error("Preview data is not present");
    }

    previewData = JSON.parse(atob(queryParams.data as string)) as PreviewData;
  }

  const [isActivationCodeInfoModalOpen, setActivationCodeInfoModalOpen] =
    createSignal(false);
  const [showLoginState, setShowLoginState] = createSignal<boolean>(false);
  const [showViewCardsState, setShowViewCardsState] =
    createSignal<boolean>(false);
  const [showLoader, setShowLoader] = createSignal<boolean>(false);

  const getGreetingDataJob = createJob<GiftBoxMetadata>({
    initialJob: async () => {
      return await getGiftMetaData(giftingKey);
    },
    errorCallback: (error) => {
      if (error instanceof APIError && error.code == 401) {
        clearCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));
        window.location.reload();
        return;
      }
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
    },
  });

  const getGiftAndManageStates = async () => {
    try {
      let deviceVerificationToken: string | undefined = undefined;
      let sessionId = getCookie(
        getV3GiftingDataStorageKey(giftingKey, "sessionId")
      );

      deviceVerificationToken = await getDeviceToken();

      let giftBoxMetadata = await getGiftBoxV3(
        giftingKey,
        {
          [Header.FpDeviceToken]: deviceVerificationToken ?? "",
          [Header.SessionId]: sessionId ?? "",
        },
        {
          isUnauthenticated: sessionId ? false : true,
        }
      );

      if (giftBoxMetadata.sessionId || sessionId) {
        sessionId = giftBoxMetadata.sessionId ?? sessionId;

        try {
          const coinsResponse = await getCoinsSummary({
            [Header.SessionId]: sessionId!,
          });
          if (coinsResponse.totalConsumed > 0) {
            setShowViewCardsState(true);
          }
        } catch (e) {}
        await setupGiftingData({
          giftingKey: giftingKey,
          sessionId: sessionId!,
          giftBoxMetadata,
        });
      } else {
        if (
          giftBoxMetadata.hasVerifiedPhoneNumber &&
          !giftBoxMetadata.sessionId
        ) {
          setShowLoginState(true);
        }
      }
    } catch (e: any) {
      console.log("Error in getGift function", e.message);
    }
  };

  const pageSetup = createJob({
    initialJob: async () => {
      await getGiftAndManageStates();
    },
  });

  onMount(() => {
    if (isPreview && previewData) {
      return;
    }
    getGreetingDataJob.run();
    pageSetup.run();
  });

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <Show when={isPreview}>
        <PreviewHeader />
      </Show>
      <div class={`h-full w-full ${isPreview ? "relative pt-[52px]" : ""}`}>
        <Show
          when={
            (getGreetingDataJob.jobState() == "success" &&
              getGreetingDataJob.jobResult() != null &&
              (pageSetup.jobState() == "success" ||
                pageSetup.jobState() == "error")) ||
            (isPreview && previewData)
          }
          fallback={<DottedLoader />}
        >
          <>
            <MetaTags />
            <VerticalDragReveal
              greetingMessage="Hey there 👋"
              senderName={
                isPreview && previewData
                  ? previewData.senderName
                  : getGreetingDataJob.jobResult()?.metadata.senderName
              }
              brandingImageSrc={hubbleLogoSmartGCCover}
            >
              <Show
                when={!isPreview}
                fallback={<RevealCard previewData={previewData!}></RevealCard>}
              >
                <RevealCard
                  data={getGreetingDataJob.jobResult()!}
                  setActivationCodeInfoModalOpen={
                    setActivationCodeInfoModalOpen
                  }
                  showLoginState={showLoginState}
                  showViewCardsState={showViewCardsState}
                ></RevealCard>
              </Show>
            </VerticalDragReveal>
          </>
        </Show>
      </div>
      <Show when={isActivationCodeInfoModalOpen()}>
        <ActivationCodeInfoModal
          closeActivationCodeInfoModal={() => {
            setActivationCodeInfoModalOpen(false);
          }}
          userName={
            !isPreview && getGreetingDataJob.jobResult()
              ? getGreetingDataJob.jobResult()!.metadata.senderName
              : ""
          }
        />
      </Show>
    </>
  );

  function PreviewHeader() {
    const navigate = useNavigate();
    return (
      <div class="fixed left-0 right-0 top-0 z-50 flex h-[56px] flex-row  items-center justify-start text-nowrap border-b border-basePrimaryMedium bg-black px-1  text-white">
        <div
          class="flex items-center py-1.5 pl-4 pr-1"
          onClick={() => {
            navigate(-1);
          }}
        >
          <PhosphorIcon
            name="x"
            fontSize={18}
            size="bold"
            class="cursor-pointer"
          />
        </div>
        <span class="p-2 text-bold">Receiver's preview</span>
      </div>
    );
  }

  function RevealCard(
    props:
      | {
          data: GiftBoxMetadata;
          setActivationCodeInfoModalOpen: Setter<boolean>;
          showLoginState: Accessor<boolean>;
          showViewCardsState: Accessor<boolean>;
        }
      | {
          previewData: PreviewData;
        }
  ) {
    const navigate = useNavigate();
    const { openModal } = useModal()!;
    const params = useParams();
    const [queryParams, setQueryParams] = useSearchParams();
    const giftingKey = params.giftingKey as string;
    const isPreviewData = "previewData" in props;

    function sessionId() {
      return getCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));
    }

    return (
      <div class="flex flex-col gap-5 overflow-y-auto">
        <div
          class={`flex w-full flex-col gap-4 rounded-3xl p-4`}
          style={{
            "background-color":
              (isPreviewData
                ? props.previewData.receiverCardBackgroundColour
                : props.data.metadata.receiverCardBackgroundColour) ??
              "#004D57",
          }}
        >
          <div>
            <img
              src={
                isPreviewData
                  ? props.previewData?.receiverCardImageUrl
                  : props.data?.metadata.receiverCardImageUrl
              }
              class="h-[255px] w-full rounded-xl"
            />
          </div>
          <div class="inline-flex flex-col gap-3 text-center">
            <div class="text-h3">
              You have received <br />
              {toRupees(
                isPreviewData
                  ? props.previewData?.budget
                  : props.data.metadata.budget
              )}{" "}
              as gift
            </div>
            <div class="text-normal">
              Shop on brands thoughtfully curated for <br />
              you by{" "}
              {isPreviewData
                ? props.previewData?.senderName
                : props.data.metadata.senderName}
            </div>
          </div>
          <div class="flex justify-between gap-1">
            <For
              each={
                isPreviewData
                  ? props.previewData.brandDetails
                  : props.data.metadata.brandDetails
              }
            >
              {(brandData) => (
                <div class="h-[108px] w-[97px] rounded-[16px]">
                  <HubbleImage
                    src={brandData.iconImageUrl ?? brandData.logoUrl}
                    alt={brandData.name}
                    class="h-[108px] w-[97px] rounded-[16px]"
                    imageBorderStyleClass="border border-white border-opacity-20"
                  />
                </div>
              )}
            </For>
          </div>
        </div>
        <Show when={!isPreviewData && props.showViewCardsState()}>
          <ButtonDark
            onClick={() => {
              navigate("/gift-box/m/" + giftingKey + "/profile");
            }}
            children={<div>My gift cards</div>}
            class="w-full !rounded-full border border-white bg-transparent text-textWhite"
          />
        </Show>
        <ButtonDark
          onClick={() => {
            if (isPreview) {
              showSnackBar({
                level: "info",
                message: "Gift receiver will be able to shop",
              });
              return;
            } else {
              if (sessionId()) {
                return navigate("/gift-box/m/" + giftingKey);
              }
              if (!isPreviewData && props.showLoginState()) {
                return verifyUserMobile({
                  giftingKey: giftingKey,
                  onSuccess: () => {
                    popPage();
                    return navigate("/gift-box/m/" + giftingKey);
                  },
                });
              }
              openModal(() => {
                return AuthenticationModal({
                  giftingKey: giftingKey,
                  fetchGiftBoxAndSetDataInLocalStorage:
                    fetchGiftBoxAndSetDataInLocalStorage,
                  openActivationCodeInfoModal: () => {
                    if ("setActivationCodeInfoModalOpen" in props) {
                      props.setActivationCodeInfoModalOpen(true);
                    }
                  },
                });
              }, "dark");
            }
          }}
          children={
            <Switch>
              <Match when={!isPreview && sessionId() && showViewCardsState()}>
                <div>Shop more</div>
              </Match>
              <Match
                when={!isPreviewData && !sessionId() && props.showLoginState()}
              >
                <div>Login to continue</div>
              </Match>
              <Match when={true}>
                <div>Shop now</div>
              </Match>
            </Switch>
          }
          isLoading={showLoader()}
          class="w-full !rounded-full"
        />
        <div class="mb-2 flex flex-col items-center gap-1">
          <p class="text-center text-[10px] font-medium uppercase tracking-[1.8px] text-textWhite">
            Powered by
          </p>
          <HubbleFullSvg class="h-[17.6px] w-[81px]" fill="#fff" />
        </div>
      </div>
    );
  }

  function MetaTags() {
    return (
      <>
        <Title>{`A gift for you`}</Title>
        <Meta name="title" content={`A gift for you`} />
      </>
    );
  }

  async function verifyUserMobile(props: {
    giftingKey: string;
    onSuccess: () => void;
  }) {
    setShowLoader(true);
    let deviceVerificationToken: string | undefined = undefined;
    deviceVerificationToken = await getDeviceToken();

    if (deviceVerificationToken) {
      const response = await sendOtpForGiftBox({
        id: props.giftingKey,
        deviceVerificationToken: deviceVerificationToken,
      });
      setShowLoader(false);
      if (response) {
        collectOtp({
          submitOtp: async (submitOtpProps) => {
            return await verifyGiftOtpV3({
              id: props.giftingKey,
              otp: submitOtpProps.otp,
              otpToken: submitOtpProps.otpToken,
              headers: {
                [Header.FpDeviceToken]: (await getDeviceToken()) ?? "",
              },
            });
          },
          onVerificationSuccess: async (response) => {
            await fetchGiftBoxAndSetDataInLocalStorage({
              sessionId: response.sessionId,
              giftingKey: props.giftingKey,
            });
            props.onSuccess();
          },
          phoneNumber: "",
          otpToken: response.otpToken,
          resendOtp: async (number) => {
            return await sendOtpForGiftBox({
              id: props.giftingKey,
              deviceVerificationToken: deviceVerificationToken,
            });
          },
          buildSubtitle: OtpSubtitle,
        });
      }
    }
  }

  function OtpSubtitle() {
    return (
      <span class="text-medium text-textNormal underline-offset-2">
        Sent to verified mobile number
      </span>
    );
  }

  async function fetchGiftBoxAndSetDataInLocalStorage(props: {
    giftingKey: string;
    activationCode?: string;
    sessionId?: string;
  }) {
    let deviceVerificationToken: string | undefined = undefined;
    let sessionId = props.sessionId;

    deviceVerificationToken = await getDeviceToken();

    let giftingPath = props.giftingKey;

    if (props.activationCode) {
      giftingPath = giftingPath + "?activationCode=" + props.activationCode;
    }

    let giftBoxMetadata = await getGiftBoxV3(
      giftingPath,
      {
        [Header.FpDeviceToken]: deviceVerificationToken ?? "",
        [Header.SessionId]: sessionId ?? "",
      },
      {
        isUnauthenticated: sessionId ? false : true,
      }
    );

    if (giftBoxMetadata.sessionId) sessionId = giftBoxMetadata.sessionId;

    if (sessionId) {
      await setupGiftingData({
        giftingKey: props.giftingKey,
        activationCode: props.activationCode,
        sessionId,
        giftBoxMetadata,
      });
    }
  }

  async function setupGiftingData(props: {
    giftingKey: string;
    activationCode?: string;
    sessionId: string;
    giftBoxMetadata: GetGiftBoxV3Response;
  }) {
    let productSearchResponse = await getProductSearch({
      query: "",
      headers: {
        [Header.SessionId]: props.sessionId,
      },
    });

    let brands: GiftBrandListingData[] =
      productSearchResponse.data[0].products.map((product) => {
        return {
          id: product.id,
          plainLogoUrl: product.voucherProduct.plainLogoUrl,
          cardBackgroundColor: product.voucherProduct.cardBackgroundColor,
          title: product.voucherProduct.title,
          heroImageUrl: product.voucherProduct.heroImageUrl ?? undefined,
          status: product.voucherProduct.status,
        };
      });

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "brands"),
      JSON.stringify(brands),
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "isSmartGC"),
      true,
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "showTooltip"),
      false,
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "getGiftV3"),
      JSON.stringify(props.giftBoxMetadata),
      gift_v3cache_duration_in_minutes
    );
    storeV3GiftSessionId({
      giftingKey: props.giftingKey,
      sessionId: props.sessionId,
    });
    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "clickedUnwrap"),
      true,
      gift_v3cache_duration_in_minutes
    );
  }

  async function getDeviceToken() {
    let deviceVerificationToken: string | undefined = undefined;
    try {
      deviceVerificationToken = await generateDeviceVerificationToken();
    } catch (error) {
      console.log("Error getting device verification token", error);
    }

    return deviceVerificationToken;
  }
}
