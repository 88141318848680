import { Accessor, createSignal, For } from "solid-js";
import { FAQ } from "~/data/static_faqs/types";
import { PhosphorIcon } from "~/widgets/icons";

export function FAQs(props: { faqs: FAQ[] }) {
  const [openFaqSerialNumber, setOpenFaqSerialNumber] = createSignal<number>(1);

  return (
    <div class="inline-flex h-fit w-full flex-col items-start justify-start">
      <For each={props.faqs}>
        {(faq, index) => (
          <FAQ
            sNo={index() + 1}
            question={faq.question.replace(/^\d\.\s/, "")}
            answer={faq.answer}
            openFaqSerialNumber={openFaqSerialNumber}
            setOpenFaqSerialNumber={setOpenFaqSerialNumber}
            isLast={index() === props.faqs.length - 1}
          />
        )}
      </For>
    </div>
  );

  function FAQ(props: {
    sNo: number;
    question: string;
    answer: string;
    openFaqSerialNumber: Accessor<number | undefined>;
    setOpenFaqSerialNumber: (sNo: number | undefined) => void;
    isLast: boolean;
  }) {
    const toggleIsOpen = () => {
      if (isOpen()) {
        props.setOpenFaqSerialNumber(undefined);
      } else {
        props.setOpenFaqSerialNumber(props.sNo);
      }
    };

    function isOpen() {
      return props.openFaqSerialNumber() === props.sNo;
    }

    return (
      <div
        class={`flex h-fit cursor-pointer flex-row items-start justify-start gap-1 self-stretch border-t  border-basePrimaryDark py-4 text-baseTertiaryDark`}
        classList={{
          "border-b": props.isLast,
        }}
        onClick={toggleIsOpen}
      >
        <div class="flex w-full flex-col justify-start transition-all duration-300">
          <div class="inline-flex flex-row justify-start gap-2 self-stretch">
            <h3 class="shrink grow basis-0 text-medium">{props.question} </h3>
            <div class="relative h-4 w-4 self-start justify-self-end">
              <PhosphorIcon name={isOpen() ? "minus" : "plus"} />
            </div>
          </div>
          <div
            class="flex flex-col justify-start self-stretch overflow-hidden  text-medium  text-textNormal transition-all duration-200"
            classList={{
              "max-h-[340px]": isOpen(),
              "max-h-[0px]": !isOpen(),
              "pt-3": isOpen(),
            }}
          >
            {props.answer}
          </div>
        </div>
      </div>
    );
  }
}
