import { loadMixpanel } from "~/data/mixpanel_loader";
import { areMixpanelEventsEnabledFor } from "~/utils/common";
import { HubbleRoute } from "~/shared_states/modal";
import { hostOnAnalyticsEvent } from "~/utils/sdk/host_actions";
import { Mode } from "~/types";

function getMixpanel() {
  try {
    return loadMixpanel();
  } catch (e) {
    // Loading Mixpanel can fail due to Ad-blockers
    return null;
  }
}

export abstract class EventManager {
  clientId?: string;
  mode?: Mode;

  constructor(mode: Mode) {
    this.mode = mode;
  }

  setIdentity(userId: string) {
    getMixpanel()?.identify(userId);
  }

  sendEventToMixpanel(
    event: string,
    properties?: object,
    eventPrefix: string = ""
  ) {
    getMixpanel()?.track(eventPrefix + event, {
      clientId: this.clientId,
      mode: this.mode,
      ...properties,
    });
  }

  sendEvent(event: EventName, properties?: object) {}

  static logout() {
    getMixpanel()?.reset();
  }
}

class SdkEventManager extends EventManager {
  constructor() {
    super("sdk");
  }

  sendEvent(event: EventName, properties?: object) {
    if (areMixpanelEventsEnabledFor(this.mode)) {
      this.sendEventToMixpanel(event, properties, "web_sdk_");
    }

    hostOnAnalyticsEvent(event, properties);
  }

  setClientId(clientId: string) {
    this.clientId = clientId;
  }
}

class WebEventManager extends EventManager {
  constructor() {
    super("hubble");
  }

  sendEvent(event: EventName, properties?: object) {
    if (areMixpanelEventsEnabledFor(this.mode)) {
      this.sendEventToMixpanel(event, properties, "web_");
    }
  }
}

class RNREventManager extends EventManager {
  constructor() {
    super("rnr");
  }

  sendEvent(event: EventName, properties?: object) {
    if (areMixpanelEventsEnabledFor(this.mode)) {
      this.sendEventToMixpanel(event, properties, "web_rnr_");
    }
  }

  setClientId(clientId: string) {
    this.clientId = clientId;
  }
}

export enum HubbleEvent {
  VISIT_SEARCH = "visit_search",
  SEARCH_QUERY = "search_query",
  VISIT_CATEGORY = "visit_category",
  CLICK_SEARCH_RESULT = "click_search_result",
  PAYMENT_INITIATED = "payment_initiated",
  PAYMENT_SUCCESS = "payment_success",
  PAYMENT_FAIL = "payment_fail",
  LOGIN = "login",
  CLICK_GET_THIS_CARD = "click_get_this_card",
  PAGE_VISIT = "page_visit",
  INIT_ERROR = "init_error",
  CLICK_UNWRAP_GIFT = "click_unwrap_gift",
  VISIT_BALANCE_CHECK = "visit_balance_check",
  CHECK_GIFT_CARD_BALANCE_STATUS = "check_gift_card_balance_status",
  COIN_PAYMENT = "coin_payment",
  CLICK_QUICK_COMMERCE_STRIP = "click_quick_commerce_strip",
  CLICK_PLUTO_STRIP = "click_pluto_strip",

  // Gifting
  CLICK_GIFT_FOOTER = "click_gift_footer",
  CLICK_REVEAL_GIFT = "click_reveal_gift",
  GIFT_VERIFICATION_SUBMIT_IDENTIFIER = "gift_verification_submit_identifier",
  GIFT_VERIFICATION_SUBMIT_OTP = "gift_verification_submit_otp",
  GIFT_VERIFICATION_SUCCESS = "gift_verification_success",
  CLICK_NOT_RECEIVED_GIFT_DELIVERY = "click_not_received_gift_delivery",
  GIFT_CONTACT_SUPPORT = "gift_contact_support",
  ATTEMPT_GIFT_OPEN = "attempt_gift_open",
  RETRY_GIFT_OPEN = "retry_gift_open",
  GIFT_CLICK_ADD_TO_BRAND = "gift_click_add_to_brand",
  GIFT_CLICK_COPY = "gift_click_copy",
  SCAN_GIFT_BOX = "scan_gift_box",
  VISIT_GIFT_LANDING_PAGE = "visit_gift_landing_page",
}

export enum RNREvent {
  VERIFY_OTP_FAILURE = "verify_otp_failure",
  VERIFY_OTP_SUCCESS = "verify_otp_success",
  GENERATE_OTP_SUCCESS = "generate_otp_success",
  GENERATE_OTP_FAILURE = "generate_otp_failure",
  GENERATE_OTP_LIMIT_FAILURE = "generate_otp_limit_failure",
  VISIT_HOME_PAGE = "visit_home_page",
  VISIT_CATEGORY_PAGE = "visit_category_page",
  VISIT_PROFILE_PAGE = "visit_profile_page",
  VISIT_BRANDL2_PAGE = "visit_brandL2_page",
  ORDER_SUCCEEDED = "order_succeeded",
  ORDER_FAILED = "order_failed",
}

export type EventName = `visit_${HubbleRoute}` | HubbleEvent | RNREvent;

export const sdkEventManager = new SdkEventManager();
export const webEventManager = new WebEventManager();
export const rnrEventManager = new RNREventManager();
