import { config } from "~/data/config";
import { PayUCustomData } from "~/server/types/order";

export async function openPayUCheckout(
  props: PayUCustomData,
  procurementId: string,
  mode: string
) {
  console.log("Opening PayU Checkout");

  const enforcePaymethodMethods =
    mode === "UPI" ? "UPI" : "creditcard|debitcard";

  const options = {
    key: props.key || "",
    txnid: props.txnId || "",
    amount: props.amount || "",
    productinfo: props.productInfo || "",
    firstname: props.firstName || "",
    email: props.email || "",
    phone: props.phone || "",
    lastname: props.lastName || "",
    surl: window.location.origin + "/payu/" + procurementId,
    furl: window.location.origin + "/payu/" + procurementId,
    hash: props.hash || "",
    enforce_paymethod: enforcePaymethodMethods,
  };

  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", config.payUUrl);
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", options[key as keyof typeof options]);
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  form.submit();
}
