"use server";

import { getSquidOrderDetail } from "../apis/server_apis";
import { SquidOrderDetailResponse } from "../types/order";

export type ProcurementOrderProcessingRouteData = {
  details?: SquidOrderDetailResponse;
};

export type ProcurementProcessingProps = {
  orderId: string;
};

export const getSquidOrderProcessingRouteData = async (
  orderId: string
): Promise<ProcurementOrderProcessingRouteData> => {
  let details = await getSquidOrderDetail({ orderId: orderId });
  return { details };
};
