import { RouteSectionProps, useIsRouting } from "@solidjs/router";
import { Show, Suspense } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { SmartGreetingProvider } from "~/context/smart_greeting_context";
import { ThreeDotLoader } from "~/widgets/button";

export default function (props: RouteSectionProps) {
  const isRouting = useIsRouting();

  return (
    <Suspense
      fallback={
        <div class="flex h-full w-full items-center justify-center bg-black">
          <ThreeDotLoader color="#fff" />
        </div>
      }
    >
      <SmartGreetingProvider>
        <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
        <Show when={isRouting()}>
          <div class="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-60">
            <ThreeDotLoader />
          </div>
        </Show>
        {props.children}
      </SmartGreetingProvider>
    </Suspense>
  );
}
