import {
  A,
  cache,
  createAsync,
  useLocation,
  useNavigate,
  useParams,
} from "@solidjs/router";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  onMount,
  Show,
} from "solid-js";
import { greenCheck } from "~/assets/assets";
import { useModal } from "~/components/modal";
import { GiftCard } from "~/components/pluto/gift-card/gift_card";
import { CopyLinkModal } from "~/components/pluto/modals/copy_link_modal";
import { FeedbackModal } from "~/components/pluto/modals/feedback_modal";
import { ShareAppTray } from "~/components/pluto/modals/share_app_tray";
import { PaymentFailure } from "~/components/pluto/payment_failure";
import { getGiftById, getShareableGiftLink } from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { GiftCardDetails } from "~/server/types/pluto";
import { showSnackBar } from "~/shared_states/snackbar";
import { toRupees } from "~/utils/number";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

interface PaymentStatusState {
  status: "success" | "failure";
  budget?: number;
}

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftCardShare() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation<PaymentStatusState>();

  const { openModal } = useModal()!;

  const [shareLink, setShareLink] = createSignal("");

  const [loadingState, setLoadingState] = createSignal({
    copy: false,
    share: false,
  });

  const [latestGiftCardDetails, setLatestGiftCardDetails] =
    createSignal<GiftCardDetails | null>(null);

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  createEffect(async () => {
    if (latestGiftCardDetails()?.paymentStatus === "PAID" && !shareLink()) {
      const response = await getShareableGiftLink(latestGiftCardDetails()!.id!);
      if (response.link) {
        setShareLink(response.link);
      }
    }
  });

  const paymentStatus = location.state?.status;

  if (!location.state?.status) {
    return navigate(`/pluto/create/${params.giftId}/budget`);
  }

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  if (paymentStatus === "failure") {
    return <PaymentFailure closeLink="/pluto/create/new" />;
  }

  const getShareableLink = async (action: "share" | "copy") => {
    setLoadingState((prev) => ({ ...prev, [action]: true }));
    try {
      const response = await getShareableGiftLink(params.giftId);
      if (response.link) {
        setShareLink(response.link);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState((prev) => ({ ...prev, [action]: false }));
    }
  };

  const shareNowModal = async (copy?: boolean) => {
    const [isCopied, setIsCopied] = createSignal(false);

    async function copylink() {
      try {
        if (!shareLink()) {
          await getShareableLink(copy ? "copy" : "share");
        }
        navigator.clipboard.writeText(shareLink());
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (error) {
        showSnackBar({
          level: "error",
          message: "Failed to copy link",
        });
      }
    }

    if (copy) {
      copylink();
    }

    openModal(
      () => {
        const handleShareClick = async () => {
          if (!isCopied()) {
            copylink();
          }
        };

        return (
          <div class="relative w-full p-4">
            <Show when={!copy} fallback={<CopyLinkModal />}>
              <ShareAppTray
                isCopied={isCopied}
                shareLink={shareLink}
                handleShareClick={handleShareClick}
              />
            </Show>
          </div>
        );
      },
      "dark lg:w-[400px]",
      "!bg-black !border-t !border-basePrimaryDark"
    );
  };

  const openFeedbackModal = () => {
    openModal(
      () => <FeedbackModal giftId={params.giftId} />,
      "w-screen max-h-screen dark lg:w-[400px] lg:rounded-b-3xl lg:rounded-3xl lg:!border lg:border-basePrimaryMedium",
      "border-none dark:!bg-black"
    );
  };

  return (
    <div class="flex h-full  w-full flex-col overflow-y-auto md:w-[800px] lg:w-screen">
      <div class="relative items-center justify-center bg-cover bg-no-repeat px-4 py-2 lg:flex lg:min-h-14 lg:border-b lg:border-basePrimaryMedium lg:bg-plutoHeaderBg">
        <A
          href={`/pluto/create/new`}
          class="absolute left-4 top-4 flex h-10 w-10 items-center justify-center rounded-full border border-basePrimaryMedium bg-[#010101] lg:top-2"
        >
          <PhosphorIcon
            name="x"
            fontSize={20}
            size="bold"
            class={`cursor-pointer text-baseTertiaryLight`}
          />
        </A>
      </div>
      <Show
        when={routeData()?.giftDetails}
        fallback={<DottedLoader color="#999" />}
      >
        <div class="lg:mx-auto lg:mt-10 lg:flex lg:w-[766px] lg:items-start lg:gap-20">
          <div class="mt-12 lg:mt-0 ">
            <div class="flex flex-col items-center justify-center gap-3 pb-6 lg:border-b lg:border-basePrimaryDark">
              <img class="h-10 w-10" src={greenCheck} alt="brand card" />
              <div class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-successDark">
                Payment successful
              </div>
              <p class="w-[70%] text-center font-jakartaSans text-h3 text-white">
                Gift worth ₹{location.state?.budget} is ready to share
              </p>
            </div>
            <div class="hidden lg:mt-3 lg:block">
              <SecondaryBottomCta className="justify-center gap-6" />
            </div>
          </div>
          <div class="mx-auto w-[343px]">
            <GiftCard
              giftDetails={() => routeData()?.giftDetails!}
              flipOnCard={true}
              showActions={false}
              showActionsBg={false}
              isEditable={false}
              showNavigationDots={true}
            />
            <div class="hidden lg:block">{buildPrimaryBottomCta()}</div>
          </div>
        </div>

        <div class="mx-4 lg:hidden">{buildPrimaryBottomCta()}</div>

        <div class="lg:hidden">
          <SecondaryBottomCta className="justify-between" />
        </div>
      </Show>
    </div>
  );

  function buildPrimaryBottomCta() {
    return (
      <div class="my-6 flex flex-col gap-4">
        <button
          onClick={() => shareNowModal()}
          class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
        >
          {loadingState().share ? (
            <ThreeDotLoader color="#000" />
          ) : (
            <p class="text-buttonMedium font-bold text-textDark">Share now</p>
          )}
        </button>

        <button
          onClick={() => shareNowModal(true)}
          class="flex h-11 w-full items-center justify-center rounded-[41px] border border-baseSecondaryDark px-4"
        >
          {loadingState().copy ? (
            <ThreeDotLoader color="#fff" />
          ) : (
            <p class="text-buttonMedium text-white">Copy gift link</p>
          )}
        </button>
      </div>
    );
  }

  function SecondaryBottomCta(props: { className?: string }) {
    return (
      <div class={`${props.className} mx-4 mb-4 flex items-center`}>
        <A href={`/pluto/create/${routeData()?.giftDetails?.id}`}>
          <p class="text-mediumBold text-white underline">View gift details</p>
        </A>
        <button onClick={openFeedbackModal}>
          <p class="text-mediumBold text-white underline">Share feedback</p>
        </button>
      </div>
    );
  }
}
