import { JSX } from "solid-js";

export const smartGCCategoryIcons: Record<string, () => JSX.Element> = {
  FOOD: foodIcon,
  FASHION: fashionIcon,
  BEAUTY: beautyIcon,
  ELECTRONICS: electronicsIcons,
  HOME_DECOR: homeDecorIcon,
  FITNESS: fitnessIcon,
  HOTELS: hotelsIcon,
  TRAVEL_AND_TRANSPORT: travelIcon,
  WATCHES: watchesIcon,
  FURNISHING: furnishingIcon,
  GROCERY: groceryIcon,
  FOOTWARE: footwearIcon,
  ONE_STOP_SHOPS: oneStopShopsIcon,
  ACCESSORIES: accessoriesIcon,
  JEWELLERY: jewelleryIcon,
  ENTERTAINMENT: entertainmentIcon,
  LUXURY: luxuryIcon,
};

function foodIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.14389 2.39866C12.2945 2.80718 17.1928 7.70549 17.6013 13.8561C17.6224 14.327 17.2926 14.7412 16.8289 14.8261L3.73602 17.3848C3.424 17.4457 3.10209 17.3475 2.87729 17.1227C2.65249 16.8979 2.55425 16.576 2.61523 16.264L5.17391 3.17111C5.25881 2.70743 5.67297 2.37761 6.14389 2.39866Z"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_2"
          d="M14.5176 15.2221C14.708 12.5852 13.7432 9.99564 11.8738 8.12621C10.0044 6.25679 7.41485 5.29199 4.77795 5.48247"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_3"
          d="M10.6442 12.2129C10.644 12.328 10.5507 12.4211 10.4357 12.4211C10.3206 12.421 10.2274 12.3278 10.2273 12.2128C10.2273 12.0977 10.3205 12.0044 10.4355 12.0043C10.4909 12.0042 10.544 12.0262 10.5831 12.0653C10.6223 12.1045 10.6443 12.1576 10.6442 12.2129"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_4"
          d="M6.67195 13.537C6.67181 13.6521 6.57849 13.7452 6.46345 13.7452C6.34841 13.7451 6.25516 13.6519 6.25511 13.5369C6.25506 13.4218 6.34823 13.3285 6.46327 13.3284C6.51863 13.3283 6.57175 13.3502 6.6109 13.3894C6.65006 13.4285 6.67202 13.4817 6.67195 13.537"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_5"
          d="M7.99605 9.5648C7.99591 9.67984 7.90258 9.77301 7.78754 9.77297C7.6725 9.77292 7.57926 9.67967 7.57921 9.56463C7.57916 9.44959 7.67233 9.35627 7.78737 9.35613C7.84274 9.35606 7.89585 9.37802 7.935 9.41717C7.97415 9.45632 7.99612 9.50944 7.99605 9.5648"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export function popularIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M4.75368 11.6892C3.43656 11.9768 2.28067 12.5679 1.49977 13.3833C2.63014 14.5637 4.54626 15.2741 6.59977 15.2741C7.86258 15.2741 9.07343 15.0054 10.0815 14.5241"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.3782 11.6892C16.6953 11.9768 17.8512 12.5679 18.6321 13.3833C17.5017 14.5637 15.5856 15.2741 13.5321 15.2741C12.2693 15.2741 11.0584 15.0054 10.0503 14.5241"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0964 14.351C11.7348 14.3574 13.577 13.4744 14.9996 12.0006C16.4221 10.5269 17.2347 8.65964 17.1623 7.03072C15.5238 7.02428 13.6816 7.90733 12.259 9.38108C12.1591 9.48465 12.0621 9.59016 11.9683 9.69739"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.93636 14.351C8.29787 14.3574 6.45569 13.4744 5.03314 12.0006C3.6106 10.5269 2.79806 8.65964 2.87046 7.03072C4.50895 7.02428 6.35113 7.90733 7.77367 9.38108C7.87364 9.48465 7.9706 9.59016 8.06444 9.69739"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0001 14.4779C8.81394 13.353 8.1001 11.4463 8.1001 9.40276C8.1001 7.35926 8.81394 5.45249 10.0001 4.32764C11.1863 5.45249 11.9001 7.35926 11.9001 9.40276C11.9001 11.4463 11.1863 13.353 10.0001 14.4779Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="1.82924"
        cy="10.62"
        rx="0.301404"
        ry="0.299934"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="18.1808"
        cy="10.62"
        rx="0.301404"
        ry="0.299934"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="13.6759"
        cy="5.64442"
        rx="0.301404"
        ry="0.299934"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="6.22914"
        cy="5.64442"
        rx="0.301404"
        ry="0.299934"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function groceryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M7.49896 5.83158C7.49896 4.45029 8.61872 3.33054 10 3.33054C11.3813 3.33054 12.501 4.45029 12.501 5.83158C12.8327 5.49992 13.2825 5.31359 13.7516 5.31359C14.2206 5.31359 14.6704 5.49992 15.0021 5.83158"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.49896 10.8337C7.49896 9.45237 8.61872 8.33261 10 8.33261C11.3813 8.33261 12.501 9.45237 12.501 10.8337"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 8.33263C9.99987 7.06573 9.05254 5.999 7.79454 5.84915C6.53653 5.6993 5.36513 6.51365 5.06737 7.74506C5.03953 7.86095 4.96099 7.95814 4.85353 8.00968C4.74607 8.06122 4.62111 8.06163 4.51331 8.0108C3.86743 7.74872 3.12632 7.97789 2.74112 8.55881C2.35592 9.13973 2.43325 9.9116 2.92603 10.4046L3.4935 10.972"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0021 5.83157C15.5464 6.37097 15.678 7.20111 15.3272 7.88243"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5027 15.5023V15.5023C15.5581 16.7618 14.0755 17.5031 12.5011 17.5031H7.49897C5.92453 17.5031 4.44197 16.7618 3.4973 15.5023V15.5023C2.84792 14.6364 2.49689 13.5833 2.49689 12.501V12.501C2.49689 11.5802 3.24339 10.8337 4.16425 10.8337H15.8358C16.7566 10.8337 17.5031 11.5802 17.5031 12.501V12.501C17.5031 13.5833 17.1521 14.6364 16.5027 15.5023V15.5023Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6552 11.0572C17.15 10.3793 17.4172 9.56212 17.4186 8.72289V8.22268C17.4186 7.94642 17.1946 7.72247 16.9184 7.72247H16.4181C14.5532 7.7243 12.9374 9.01524 12.5239 10.8337"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function electronicsIcons() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M10.8333 14.1667H3.33332C2.41285 14.1667 1.66666 13.4205 1.66666 12.5V5.00001C1.66666 4.07954 2.41285 3.33334 3.33332 3.33334H15C15.9205 3.33334 16.6667 4.07954 16.6667 5.00001V6.66668"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 16.6667H8.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="10.8333"
        y="6.66666"
        width="7.5"
        height="10.8333"
        rx="1.29601"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="14.5833"
        cy="14.1667"
        rx="1.45833"
        ry="1.45833"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7917 9.58356C14.7915 9.69855 14.6982 9.79167 14.5832 9.79163C14.4682 9.79158 14.375 9.69837 14.375 9.58338C14.3749 9.46839 14.4681 9.37511 14.5831 9.37496C14.6384 9.37489 14.6915 9.39684 14.7306 9.43598C14.7698 9.47511 14.7917 9.52821 14.7917 9.58356"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function travelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path d="M10 12.5V7.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.5 7.5C7.5 4.75 8.58333 2.5 10 2.5C11.4167 2.5 12.5 4.75 12.5 7.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.91667 15L5 17.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.25 15L14.1667 17.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.75 15H13C13.9167 15 14.75 14.6667 15.5 14.1667L17 13C17.4167 12.6667 17.5833 12.0833 17.4167 11.5833V11.5833C17.1667 10.75 16.1667 10.5 15.5 11L14 12.1667C13.75 12.4167 13.3333 12.5 13 12.5H3.75C3.08333 12.5 2.5 13.0833 2.5 13.75V13.75C2.5 14.4167 3.08333 15 3.75 15Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33334 7.5H16.6667C16.6667 5 13.3333 2.5 10 2.5C6.66668 2.5 3.33334 5 3.33334 7.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function hotelsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M5.8316 17.5031V4.99789H2.49687C2.03644 4.99789 1.66319 5.37115 1.66319 5.83157V17.5031"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3368 17.5031V5.83157C18.3368 5.37115 17.9636 4.99789 17.5031 4.99789H14.1684V17.5031"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33264 15.0021H11.6674"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.74948 17.5031V15.0021H11.2505V17.5031"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.9158 12.501H12.0842"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.9158 9.99997H12.0842"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.74739 11.2505H5.83159"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.74739 14.1684H5.83159"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.74739 8.33261H5.83159"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1684 11.2505H16.2526"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1684 14.1684H16.2526"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1684 8.33261H16.2526"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1684 17.5031V3.36388C14.1772 2.89439 13.8042 2.50643 13.3347 2.49686H6.66529C6.19581 2.50643 5.82277 2.89439 5.83161 3.36388V17.5031"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1705 17.5031H0.829514"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="10"
        cy="6.24841"
        rx="1.25052"
        ry="1.25052"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function fashionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M10.5558 5.73897L12.5438 6.40162C12.9806 6.54722 13.4528 6.31116 13.5984 5.87436L14.1684 4.16422L12.9894 2.98522C12.6767 2.67253 12.2526 2.49686 11.8104 2.49686H8.18961C7.7474 2.49686 7.3233 2.67253 7.0106 2.98522L5.8316 4.16422L6.40165 5.87436C6.54726 6.31115 7.01936 6.54723 7.45617 6.40168L9.4442 5.73897"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 6.66525V18.3368"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9917 10L14.9918 16.2885C15 17.0371 14.502 17.697 13.7799 17.8945C11.2874 18.4843 8.69175 18.4843 6.19933 17.8945C5.47714 17.6971 4.97915 17.0371 4.98741 16.2885L4.9875 10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83135 4.16422L4.14966 4.72477C3.63585 4.89605 3.23724 5.30622 3.08072 5.82471L1.80549 10.0491C1.6499 10.5645 1.62141 11.1098 1.72244 11.6386L2.39495 15.1585C2.47003 15.5515 2.81373 15.8357 3.21384 15.8357H4.99767"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1686 4.16422L15.8503 4.72477C16.3641 4.89605 16.7628 5.30622 16.9193 5.82471L18.1945 10.0491C18.3501 10.5645 18.3786 11.1098 18.2776 11.6386L17.605 15.1585C17.53 15.5515 17.1863 15.8357 16.7862 15.8357H15.0023"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.56482 2.60608L10 6.66527L12.4352 2.60608"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function beautyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M17.5031 9.99998C17.5031 14.1438 14.1439 17.5031 10 17.5031C5.85614 17.5031 2.49688 14.1438 2.49688 9.99998C2.49688 5.85612 5.85614 2.49686 10 2.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2855 1.98999C15.3954 1.78874 15.6065 1.66354 15.8358 1.66354C16.0651 1.66354 16.2761 1.78874 16.386 1.98999L16.872 2.87786C16.9297 2.98348 17.0165 3.0703 17.1221 3.12797L18.01 3.61317C18.2117 3.72294 18.3372 3.93419 18.3372 4.16382C18.3372 4.39344 18.2117 4.60469 18.01 4.71446L17.1221 5.2005C17.0163 5.25783 16.9294 5.34473 16.872 5.4506L16.386 6.33764C16.2761 6.53889 16.0651 6.66409 15.8358 6.66409C15.6065 6.66409 15.3954 6.53889 15.2855 6.33764L14.7995 5.44977C14.7422 5.34389 14.6553 5.25699 14.5494 5.19966L13.6615 4.71446C13.4598 4.60469 13.3343 4.39344 13.3343 4.16382C13.3343 3.93419 13.4598 3.72294 13.6615 3.61317L14.5494 3.12797C14.655 3.0703 14.7418 2.98348 14.7995 2.87786L15.2855 1.98999Z"
      />
      <path
        d="M7.49896 7.77405V8.60773"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.501 7.77405V8.60773"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7028 12.2159C11.21 13.7076 8.79087 13.7076 7.29804 12.2159"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function furnishingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 11.6666H5V9.58331C5 8.89331 4.44 8.33331 3.75 8.33331H2.91667C2.22667 8.33331 1.66667 8.89331 1.66667 9.58331V13.3333C1.66667 14.2541 2.4125 15 3.33333 15H16.6667C17.5875 15 18.3333 14.2541 18.3333 13.3333V9.58331C18.3333 8.89331 17.7733 8.33331 17.0833 8.33331H16.25C15.56 8.33331 15 8.89331 15 9.58331V11.6666Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16667 15V16.6667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8333 15V16.6667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.33334 8.33332V6.66666C3.33334 5.28582 4.4525 4.16666 5.83334 4.16666H14.1667C15.5475 4.16666 16.6667 5.28582 16.6667 6.66666V8.33332"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function fitnessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6483 6.15373L13.8459 8.35139L8.35117 13.8461L6.15351 11.6485L11.6483 6.15373Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.74729 9.56012C3.11126 9.19615 3.70137 9.19615 4.06533 9.56012L10.4397 15.9345C10.8037 16.2984 10.8037 16.8885 10.4397 17.2525L10.1095 17.5827C9.74552 17.9467 9.15541 17.9467 8.79144 17.5827L2.41709 11.2084C2.05312 10.8444 2.05312 10.2543 2.41709 9.89031L2.74729 9.56012Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.74747 12.8574C3.11144 12.4934 3.70155 12.4934 4.06552 12.8574L7.14338 15.9353C7.50735 16.2992 7.50735 16.8893 7.14338 17.2533L6.81318 17.5835C6.44921 17.9475 5.8591 17.9475 5.49514 17.5835L2.41727 14.5056C2.0533 14.1417 2.0533 13.5516 2.41727 13.1876L2.74747 12.8574Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2525 10.4405C16.8885 10.8044 16.2984 10.8044 15.9344 10.4405L9.56007 4.06613C9.1961 3.70216 9.1961 3.11205 9.56007 2.74808L9.89027 2.41788C10.2542 2.05391 10.8443 2.05391 11.2083 2.41788L17.5827 8.79223C17.9466 9.1562 17.9466 9.74631 17.5827 10.1103L17.2525 10.4405Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2528 7.14261C16.8888 7.50658 16.2987 7.50658 15.9347 7.14261L12.8569 4.06475C12.4929 3.70078 12.4929 3.11067 12.8569 2.7467L13.1871 2.4165C13.551 2.05254 14.1411 2.05254 14.5051 2.4165L17.583 5.49437C17.9469 5.85834 17.9469 6.44845 17.583 6.81241L17.2528 7.14261Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function homeDecorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M8.33261 16.6694V12.501C8.33261 12.0406 8.70586 11.6674 9.16629 11.6674H10.8337"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.49686 8.8164L8.91486 3.31513C9.53928 2.77991 10.4607 2.77991 11.0851 3.31513L17.5031 8.8164"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99998 16.6694H2.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0209 4.97442V3.12213C13.0209 2.77681 13.3008 2.49687 13.6462 2.49687H15.8062C16.1515 2.49687 16.4314 2.77681 16.4314 3.12213V7.89778"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.56854 7.89778V16.6694"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4189 17.5031C15.4189 15.8916 14.1125 14.5852 12.501 14.5852C14.1125 14.5852 15.4189 13.2789 15.4189 11.6674C15.4189 13.2789 16.7253 14.5852 18.3368 14.5852C16.7253 14.5852 15.4189 15.8916 15.4189 17.5031Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function oneStopShopsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M3.33333 5.83333V4.16666C3.33333 3.70642 3.70642 3.33333 4.16666 3.33333H15.8333C16.2936 3.33333 16.6667 3.70642 16.6667 4.16666V5.83333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6667 5.83333H3.33333C2.8731 5.83333 2.5 6.20642 2.5 6.66666V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V6.66666C17.5 6.20642 17.1269 5.83333 16.6667 5.83333Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 9.16667C13.3333 11.0076 11.841 12.5 10 12.5C8.15906 12.5 6.66667 11.0076 6.66667 9.16667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function watchesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M13.3333 5.71417V3.33333C13.3333 2.87333 12.96 2.5 12.5 2.5H7.5C7.04 2.5 6.66666 2.87333 6.66666 3.33333V5.71417"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66666 14.2858V16.6667C6.66666 17.1267 7.04 17.5 7.5 17.5H12.5C12.96 17.5 13.3333 17.1267 13.3333 16.6667V14.2858"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66667 14.2858H13.3333C14.2542 14.2858 15 13.54 15 12.6192V7.49999C15 6.57916 14.2542 5.83333 13.3333 5.83333H6.66667C5.74583 5.83333 5 6.57916 5 7.49999V12.6192C5 13.5392 5.74583 14.2858 6.66667 14.2858Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16667 10.8333V8.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 10.8333H9.16666"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function footwearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M8.25844 6.11922H10L11.3572 5.29221C11.5741 5.16012 11.8393 5.13404 12.0777 5.22135C12.3161 5.30866 12.5017 5.49985 12.5819 5.74073L14.5019 11.5006L17.203 15.1021C17.544 15.5568 17.5989 16.1652 17.3447 16.6736C17.0905 17.182 16.5709 17.5031 16.0025 17.5031H12.7261C11.7996 17.5037 10.9148 17.1182 10.2843 16.4394L3.16382 8.77532C2.7014 8.27658 2.46204 7.61102 2.50089 6.932C2.53974 6.25299 2.85345 5.61907 3.36974 5.17633L6.22343 2.73114C6.50994 2.48571 6.91309 2.42948 7.25583 2.58714C7.59856 2.7448 7.81819 3.08752 7.81826 3.46478V6.11922H10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.49688 14.5853H4.16424"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66528 17.5031H2.49688"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.1255 15.0021H12.9371C12.2431 15.004 11.5799 14.7156 11.108 14.2068L3.04294 5.52147"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8337 10.8337L14.0892 10.2701"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 8.74948L13.3881 8.1634"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function accessoriesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M3.74744 5.75656H7.08216"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.74744 8.44686V4.52856C3.74651 3.60611 4.49235 2.85692 5.4148 2.8537V2.8537C6.33566 2.8537 7.08216 3.6002 7.08216 4.52106V4.52106V8.33682"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.37476 8.33264V1.66319H13.3347V8.33264"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1684 14.1684H15.4189C16.57 14.1684 17.5031 13.2353 17.5031 12.0842V10.8795C17.5031 9.95868 16.7566 9.21217 15.8358 9.21217H14.0292"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16425 8.33264H13.1955C13.6559 8.33264 14.0292 8.70589 14.0292 9.16632V14.446C14.0292 16.1341 12.661 17.5027 10.9729 17.5031H6.38767C4.69928 17.5031 3.33057 16.1344 3.33057 14.446V9.16632C3.33057 8.70589 3.70382 8.33264 4.16425 8.33264Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7078 4.03418H11.4573"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7078 6.11838H12.2909"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function jewelleryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8217 11.6667C11.5267 11.6667 12 12.2875 12 12.8658C12 14.0392 10.0358 15 10 15C9.96417 15 8 14.0392 8 12.8658C8 12.2875 8.47333 11.6667 9.17833 11.6667C9.58167 11.6667 9.84667 11.855 10 12.0233C10.1533 11.855 10.4183 11.6667 10.8217 11.6667Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10 10V12.025" stroke-linecap="round" stroke-linejoin="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99999 17.4975C5.69416 17.4975 2.15166 14.2317 1.71333 10.0425C1.66583 9.58668 1.98749 9.17418 2.44166 9.11918C5.18333 8.78501 7.21249 6.65251 7.47083 3.25168C7.50333 2.82501 7.87249 2.50168 8.29999 2.50334L9.99999 2.50834L11.7 2.50251C12.1283 2.50084 12.4975 2.82501 12.5292 3.25168C12.7875 6.65251 14.8167 8.78418 17.5575 9.11918C18.0125 9.17501 18.335 9.58751 18.2867 10.0442C17.8483 14.2325 14.305 17.4975 9.99999 17.4975Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3492 7.58585C13.3175 9.04751 11.765 10 10 10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.65082 7.58585C6.68248 9.04751 8.23498 10 9.99998 10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function entertainmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="inherit"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9325 2.99999V13C15.9325 13.9208 15.1867 14.6667 14.2658 14.6667H5.73417C4.81334 14.6667 4.0675 13.9208 4.0675 13V2.99999C4.0675 2.07916 4.81334 1.33333 5.73417 1.33333H14.2667C15.1867 1.33333 15.9325 2.07916 15.9325 2.99999Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9325 3H17.2675C17.9575 3 18.5175 3.56 18.5175 4.25V11.75C18.5175 12.44 17.9575 13 17.2675 13H15.9325"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.06752 13H2.73169C2.04169 13 1.48169 12.44 1.48169 11.75V4.25C1.48169 3.56 2.04169 3 2.73169 3H4.06669"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.50333 6.19667L11.8125 7.5625C12.145 7.75917 12.145 8.24083 11.8125 8.4375L9.50333 9.80333C9.16416 10.0042 8.73666 9.75917 8.73666 9.36583V6.63417C8.73666 6.24 9.165 5.99583 9.50333 6.19667V6.19667Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function luxuryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit"
    >
      <path
        d="M2 7L4.75 3.75M2 7L10 16.25M2 7H7.25M4.75 3.75H10M4.75 3.75L7.25 7M15.25 3.75L17.75 7M15.25 3.75L12.5 7M15.25 3.75H10M17.75 7L10 16.25M17.75 7H12.5M10 16.25L12.5 7M10 16.25L7.25 7M12.5 7L10 3.75M12.5 7H7.25M10 3.75L7.25 7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
