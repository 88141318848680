import { useLocation, useNavigate } from "@solidjs/router";
import { createEffect, createSignal, onMount, Show } from "solid-js";
import { loginAsset, squidLogo } from "~/assets/assets";
import { OtpInput } from "~/components/login-rnr/login_form";
import { verifySquidOnboarding } from "~/server/apis/client_apis";
import { goBack } from "~/shared_states/modal";
import { ButtonRegular } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";

type formData = {
  id: string;
  email: string;
  emailToken: string;
  phone: string;
  phoneToken: string;
};

type otpSubError = {
  fieldName: string;
  message: string;
};

export default function Verify() {
  const [emailOTP, setEmailOTP] = createSignal<string | null>(null);
  const [phoneOTP, setPhoneOTP] = createSignal<string | null>(null);
  const [error, setError] = createSignal<string | null>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const location = useLocation();
  const formData = location.state as formData;

  onMount(() => {
    if (!formData.email) {
      return navigate("/register");
    }
  });

  const verifyOTP = async () => {
    setIsLoading(true);
    try {
      const verify = await verifySquidOnboarding({
        id: formData?.id,
        params: {
          emailId: formData?.email,
          emailVerificationToken: formData?.emailToken,
          emailOtp: emailOTP()!,
          phoneNumber: formData?.phone,
          phoneVerificationToken: formData?.phoneToken,
          phoneOtp: phoneOTP()!,
        },
      });
      if (verify) {
        navigate("/register/complete", {
          replace: true,
          state: {
            id: verify.id,
          },
        });
      }
    } catch (e: any) {
      const subErrors: otpSubError[] = e.subErrors ?? [];
      if (subErrors.length > 0) {
        const detailedErrors = subErrors
          .map((subError) => `${subError.fieldName}`)
          .join(" & ");
        if (detailedErrors.toLowerCase().includes("phone")) {
          setPhoneOTP(null);
        }
        if (detailedErrors.toLowerCase().includes("email")) {
          setEmailOTP(null);
        }
        setError(`${detailedErrors} : ${e.defaultMessage}`);
      } else {
        setError(e.message);
      }
    }
    setIsLoading(false);
  };

  createEffect(() => {
    if (emailOTP() || phoneOTP()) {
      setError(null);
    }
  });

  createEffect(() => {
    if (emailOTP()?.length == 6 && phoneOTP()?.length == 6) {
      verifyOTP();
    }
  });

  const errorStrip = (condition: boolean | undefined) => {
    return (
      <Show when={condition}>
        <div class="py-1 text-f12Bold text-errorDark">
          {error()?.split(":").pop()}
        </div>
      </Show>
    );
  };

  return (
    <div class="flex h-screen">
      <div class=" max-h-screen w-[50%] bg-gray-400">
        <HubbleImage
          src={loginAsset}
          alt="login-asset"
          errorImage=""
          class="h-full w-full object-cover"
        />
        <div class="absolute left-14 top-10 flex flex-col items-start justify-start ">
          <div class="flex">
            <HubbleImage
              src={squidLogo}
              alt="logo-image"
              errorImage=""
              class="mr-1 h-10 w-10"
            />
            <div class="flex flex-col items-start justify-start">
              <div class="text-bold text-white">SQUID</div>
              <div class="text-f12Bold text-white">By Hubble</div>
            </div>
          </div>
          <div class="mt-11 w-[330px] text-h1 text-white">
            For all your gift card needs
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              450+ brands
            </div>
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              Best deals & prices
            </div>
          </div>
        </div>
      </div>
      <div class="flex max-h-screen w-[50%] flex-col items-center justify-center overflow-y-auto  align-middle">
        <div class="flex w-[440px] flex-col items-start justify-start">
          <div
            class=" mb-3 flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-[100%] border bg-white"
            style={{ "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02)" }}
            onClick={() => {
              goBack();
            }}
          >
            <PhosphorIcon
              name="arrow-left"
              fontSize={20}
              class="text-baseTertiary"
            />
          </div>
          <div class="mb-6 text-h2 text-textDark">Please verify</div>
          <div class=" text-mediumBold text-textDark">
            Enter OTP sent to {formData?.email}
          </div>
          <OtpInput
            otp={emailOTP}
            setOTP={setEmailOTP}
            handleOTPChange={(e) => {
              const value = (e.currentTarget as HTMLInputElement).value;
              setEmailOTP(value);
            }}
            isError={error() && !emailOTP() ? true : false}
            removeFocus={false}
          />
          {errorStrip(error() && !emailOTP() ? true : false)}

          <div class=" mt-4 text-mediumBold text-textDark">
            Enter OTP sent to {formData?.phone}
          </div>
          <OtpInput
            otp={phoneOTP}
            setOTP={setPhoneOTP}
            handleOTPChange={(e) => {
              const value = (e.currentTarget as HTMLInputElement).value;
              setPhoneOTP(value);
            }}
            isError={error() && !phoneOTP() ? true : false ? true : false}
            removeFocus={false}
          />
          <div class="mt-1">
            {errorStrip(
              error()?.toLowerCase().includes("phone") ||
                !error()?.toLowerCase().includes("email")
            )}
          </div>
          <ButtonRegular
            class="mt-6 h-12 w-full rounded-[8px]"
            isEnabled={
              emailOTP()?.length == 6 && phoneOTP()?.length == 6 ? true : false
            }
            onClick={async () => {
              await verifyOTP();
            }}
            isRectangular={true}
            isLoading={isLoading()}
          >
            Continue
          </ButtonRegular>
        </div>
      </div>
    </div>
  );
}
