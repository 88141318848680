"use server";
import {
  getBrand,
  getCoinsSummary,
  getUserProfile,
} from "../../apis/server_apis";
import { Header } from "~/types";
import { getCookie } from "vinxi/http";
import { getV3GiftingDataStorageKey } from "../../../components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { redirect } from "@solidjs/router";
import { VoucherProduct } from "~/server/types/brand";
import { APIError } from "~/utils/fetch";
import { getRequestEvent } from "solid-js/web";

export type GiftBoxV3RedeemRouteData = {
  coinsCount: number;
  brand: VoucherProduct;
  isPhoneNumberLinked: boolean;
  isRtu: boolean;
  logoUrl?: string;
};

export type GiftBoxV3RedeemRouteDataProps = {
  giftingKey: string;
  brandKey: string;
};

export async function getGiftBoxV3RedeemRouteData(
  props: GiftBoxV3RedeemRouteDataProps
): Promise<GiftBoxV3RedeemRouteData> {
  let sessionId = getCookie(
    getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
  );
  if (!sessionId) {
    throw redirect("/gift-box/" + props.giftingKey);
  }

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let getVoucherProduct$P = getBrand(
    props.brandKey,
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let userProfile$P = getUserProfile(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([
      coinsCount$P,
      getVoucherProduct$P,
      userProfile$P,
    ]);

    let coinsSummary = resolved[0];
    let brand = resolved[1];
    let profile = resolved[2];
    let logoUrl: string | undefined = getRequestEvent()?.locals.clientLogoUrl;

    return {
      coinsCount: coinsSummary.totalAvailable,
      brand: brand,
      isPhoneNumberLinked:
        profile.phoneNumber !== null && profile.phoneNumber !== "",
      isRtu: coinsSummary.totalConsumed > 0,
      logoUrl: logoUrl,
    };
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/gift-box/" + props.giftingKey);
    }
    throw error;
  }
}
