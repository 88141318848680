"use server";
import {
  getBrand,
  getCoinsSummary,
  getGiftBoxV3,
  getLastNVouchers,
  getUserProfile,
} from "../../apis/server_apis";
import { Header } from "~/types";
import { getCookie } from "vinxi/http";
import { redirect } from "@solidjs/router";
import { getV3GiftingDataStorageKey } from "../../../components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { APIError } from "~/utils/fetch";
import { VoucherDetails } from "~/server/types/order";
import { getRequestEvent } from "solid-js/web";

export type GiftBoxV3ProfileRouteData = {
  coinsCount: number;
  cardsList: VoucherDetails[];
  phoneNumber: string;
  logoUrl?: string;
};

export type GiftBoxV3ProfileRouteDataProps = {
  giftingKey: string;
};

export async function getGiftBoxV3ProfileRouteData(
  props: GiftBoxV3ProfileRouteDataProps
) {
  let sessionId = getCookie(
    getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
  );
  if (!sessionId) {
    throw redirect("/gift-box/" + props.giftingKey);
  }

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let getVouchers$P = getLastNVouchers(
    100,
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let giftMetadata$P = getGiftBoxV3(
    props.giftingKey,
    {
      [Header.SessionId]: sessionId,
      [Header.FpDeviceToken]: "",
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([
      coinsCount$P,
      getVouchers$P,
      giftMetadata$P,
    ]);

    let coinsSummary = resolved[0];
    let cardsList = resolved[1];
    let giftMetdata = resolved[2];

    let logoUrl: string | undefined = getRequestEvent()?.locals.clientLogoUrl;

    return {
      coinsCount: coinsSummary.totalAvailable,
      cardsList: cardsList,
      phoneNumber: giftMetdata.customer?.phoneNumber,
      logoUrl: logoUrl,
    };
  } catch (error) {
    if (error instanceof APIError) {
      if (error.code === 401) {
        throw redirect("/gift-box/" + props.giftingKey);
      }
    }
  }
}
