import { Accessor, Show } from "solid-js";
import {
  emailIcon,
  telegram_logo,
  whatsappIconTransparentBg,
} from "~/assets/assets";
import { CloseModalButton } from "~/components/modal";

export const ShareAppTray = ({
  shareLink,
  isCopied,
  handleShareClick,
  code,
}: {
  code?: boolean;
  shareLink: Accessor<string>;
  isCopied: Accessor<boolean>;
  handleShareClick: () => Promise<void>;
}) => {
  function shareMessageWithWishMessage() {
    if (code) {
      return (
        "<Add wish message>\n\n Please use this code to unwrap your gift.\n\n" +
        shareLink()
      );
    } else {
      return (
        "<Add wish message>\n\n" + "Tap the link for your gift:\n" + shareLink()
      );
    }
  }

  return (
    <>
      <div class="mb-7 flex items-center justify-between ">
        <p class="font-jakartaSans text-[28px] font-semibold text-white">
          Share gift
        </p>
        <CloseModalButton />
      </div>
      <div class="flex flex-col gap-8">
        <div class="flex gap-4">
          <a
            target="_blank"
            href={`https://wa.me/?text=${encodeURIComponent(shareMessageWithWishMessage())}`}
            class="flex h-14 w-14 items-center justify-center rounded-full border border-baseSecondaryDark pb-[7px] pl-[9px] pr-[7px] pt-[9px]"
          >
            <img src={whatsappIconTransparentBg} alt="whatsapp" />
          </a>
          <a
            target="_blank"
            href={`https://telegram.me/share/url?url=${encodeURIComponent(shareMessageWithWishMessage())}`}
            class="flex h-14 w-14 items-center justify-center rounded-full border border-baseSecondaryDark pb-[8.2px] pl-[9px] pr-[8px] pt-[9px]"
          >
            <img src={telegram_logo} alt="telegram" />
          </a>
          <a
            target="_blank"
            href={`mailto:?body=${encodeURIComponent(shareMessageWithWishMessage())}`}
            class="flex h-14 w-14 items-center justify-center rounded-full border border-baseSecondaryDark pb-[7px] pl-[9px] pr-[7px] pt-[9px]"
          >
            <img src={emailIcon} alt="mail" />
          </a>
        </div>
        <div class="flex h-12 items-center justify-between gap-2 rounded-[12px] border border-baseSecondaryDark py-2 pl-4 pr-1.5 [box-shadow:0px_2px_10px_0px_rgba(0,_0,_0,_0.40)]">
          <p class="text-nowrap py-1.5 text-medium text-white">
            {shareLink()?.length > 30
              ? `${shareLink().slice(0, 30)}...`
              : shareLink()}
          </p>
          <button
            onClick={handleShareClick}
            class={`h-9 text-nowrap rounded-lg px-4 text-mediumBolder ${isCopied() ? "text-[#00AF54]" : "bg-baseTertiaryMedium text-textDark"} `}
          >
            <Show
              when={code}
              fallback={<> {isCopied() ? "Link copied!" : "Copy link"}</>}
            >
              {isCopied() ? "Code copied!" : "Copy code"}
            </Show>
          </button>
        </div>
        <div class="flex flex-col gap-2 rounded-lg bg-[#2C2419] p-4">
          <p class="text-bold text-white">
            Please make sure the {code ? "code" : "link"} goes to the right
            person
          </p>
          <p class="text-medium text-baseSecondaryLight">
            Anyone with this {code ? "code" : "link"} can use the gift balance
            for the included experiences
          </p>
        </div>
      </div>
    </>
  );
};
