import { debounce } from "@solid-primitives/scheduled";
import { cache, createAsync, useLocation } from "@solidjs/router";
import {
  Accessor,
  createSignal,
  For,
  Match,
  Show,
  Suspense,
  Switch,
  createEffect,
  createMemo,
  Setter,
  onMount,
  onCleanup,
} from "solid-js";
import {
  oneGiftCard,
  physicalGiftCard,
  placeholderCard,
  thematicGiftCard,
  visaDebitCard,
} from "~/assets/assets";
import { useModal } from "~/components/modal";
import { DenominationDetailsModal } from "~/components/squid/denominations_details_modal";
import { useSquidContext } from "~/context/squid_context";
import {
  getProductSearch,
  getUserConfig,
  updateUserConfig,
} from "~/server/apis/client_apis";
import { Search } from "~/components/search/search";
import {
  CatalogueRouteData,
  getCatalogueRouteData,
} from "~/server/data/catalogue_route_data";
import { Product } from "~/server/types/search";
import HubbleImage from "~/widgets/hubble_image";
import InfiniteScroll from "~/widgets/infinite_scroll";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";
import { Array, QueryParam } from "~/types";
import {
  AllCategoriesIcon,
  squidCategoryIcons,
  SquidCategoryFallbackSvg,
} from "~/assets/squid_categories_icons";
import { CategoryData } from "~/server/types/category";
import { zIndexes } from "~/z_indexes";
import HubbleCodeAnnouncementModal from "~/components/squid/hubble_code_announcement_modal";
import { BrandDetailDenominationModal } from "~/components/squid/brand_detail_denomination_modal";

const SQUID_TOP_CATEGORIES = [
  "FOOD",
  "TRAVEL_AND_TRANSPORT",
  "JEWELLERY",
  "FASHION",
  "ELECTRONICS",
  "FURNISHING",
  "BEAUTY",
  "HOTELS",
  "LUXURY",
  "FITNESS",
] as Array<string>;

const PRODUCTS_PER_PAGE = 3 * 6; // because squid products are 6 or 3 per row

const getCatalogueRouteData$C = cache(getCatalogueRouteData, "catalogue");

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M10.5 3.5L3.5 10.5M10.5 10.5L3.5 3.50001"
        stroke="#212427"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
}

function BrandLoadingPlaceholder() {
  return (
    <div class="items-left flex flex-col gap-[6px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="134"
        viewBox="0 0 120 134"
        fill="none"
      >
        <g clip-path="url(#clip0_89_2487)">
          <rect width="120" height="134" rx="20" fill="#EDEEF1" />
        </g>
        <defs>
          <clipPath id="clip0_89_2487">
            <rect width="120" height="134" rx="18.5241" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div class="flex flex-col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="92"
          height="18"
          viewBox="0 0 92 18"
          fill="none"
        >
          <rect y="3" width="92" height="12" rx="6" fill="#EDEEF1" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="92"
          height="17"
          viewBox="0 0 92 17"
          fill="none"
        >
          <rect y="3" width="54" height="12" rx="6" fill="#EDEEF1" />
        </svg>
      </div>
    </div>
  );
}

type SquidHomeBrandCardProps = {
  brand: Product;
  onClick: (params: {
    brandId: string;
    discountPercentage: number;
    brandKey: string;
  }) => void;
};

function SquidHomeBrandCard(props: SquidHomeBrandCardProps) {
  return (
    <div
      class="group flex cursor-pointer flex-col gap-2"
      onClick={() =>
        props.onClick({
          brandId: props.brand.id,
          discountPercentage: props.brand.voucherProduct?.discountPercentage,
          brandKey: props.brand.brandKey,
        })
      }
    >
      <HubbleImage
        src={props.brand.voucherProduct?.iconImageUrl}
        class="inline-block aspect-[53/59] h-fit w-full rounded-2xl"
        alt={props.brand.voucherProduct?.title + " logo image"}
        errorImage={placeholderCard}
      />
      <div class="flex w-full flex-col px-1">
        <div class="flex flex-col gap-[0.5]">
          <span class="line-clamp-2 text-subtitleSmall text-textDark">
            {props.brand.voucherProduct?.title}
          </span>
          <Show when={props.brand.voucherProduct?.discountPercentage}>
            <div class="flex flex-row items-end">
              <div class="flex grow flex-col items-start justify-start">
                <div class="flex grow items-baseline text-f12 text-textNormal">
                  {props.brand.voucherProduct?.discountPercentage}% off
                </div>
              </div>
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
}

function HubbleProductBanners(props: { onHubbleOneClick: () => void }) {
  const bannerList = [
    { img: oneGiftCard, path: "" },
    {
      img: thematicGiftCard,
      path: "https://universe.myhubble.money/thematic-gift-cards",
    },
    {
      img: visaDebitCard,
      path: "https://universe.myhubble.money/prepaid-visa-card",
    },
    {
      img: physicalGiftCard,
      path: "https://universe.myhubble.money/gift",
    },
  ];
  return (
    <div class="mb-10 flex flex-col gap-3">
      <div class="flex items-center gap-3 px-5">
        <p class="text-nowrap text-subtitleSmall text-textDark">
          Hubble exclusive
        </p>
        <hr class="h-px w-full bg-baseTertiaryMedium" />
      </div>
      <div class="px-5">
        <div class="flex w-full flex-wrap gap-3">
          <For each={bannerList}>
            {(banner) => (
              <div
                class="h-[234px] w-[188px] cursor-pointer"
                onclick={() => {
                  if (banner.img === oneGiftCard) {
                    return props.onHubbleOneClick();
                  }
                  window.open(banner.path, "_blank");
                }}
              >
                <HubbleImage
                  src={banner.img}
                  class="aspect-[188/234] h-fit w-full rounded-2xl"
                  alt="banner image"
                />
              </div>
            )}
          </For>
        </div>
      </div>
    </div>
  );
}

function PopularBrands(props: {
  products?: Product[];
  onBrandClick: ({
    brandId,
  }: {
    brandId: string;
    discountPercentage: number;
    brandKey: string;
  }) => void;
}) {
  return (
    <div class="mb-4 flex flex-col gap-3">
      <div class="flex items-center gap-3 px-5">
        <p class="text-nowrap text-subtitleSmall text-textDark">
          Popular brands
        </p>
        <hr class="h-px w-full bg-baseTertiaryMedium" />
      </div>

      <Show when={props.products?.length}>
        <div
          style={{
            "grid-template-columns": `repeat(${Math.ceil(props.products!.length / 2)}, minmax(80px, 1fr))`,
          }}
          class={`grid grid-flow-row grid-rows-2 gap-x-5 gap-y-3 px-5 pb-3`}
        >
          <For each={props.products}>
            {(product) => (
              <div
                class="flex cursor-pointer flex-col items-center"
                onClick={() =>
                  props.onBrandClick({
                    brandId: product?.id,
                    discountPercentage:
                      product.voucherProduct.discountPercentage,
                    brandKey: product.brandKey,
                  })
                }
              >
                <HubbleImage
                  class="h-20 w-20 rounded-3xl"
                  src={
                    "https://app.myhubble.money/static/brand-assets/" +
                    product.brandKey +
                    "/top_brand.png"
                  }
                  alt={product.voucherProduct.title}
                  errorImage={placeholderCard}
                />
                <Show when={product.voucherProduct.discountPercentage}>
                  <div class="mt-2.5 text-f12 text-textNormal">
                    {product.voucherProduct.discountPercentage}% off
                  </div>
                </Show>
              </div>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
}

const fetchNextPage = async ({
  page,
  setPage,
  setHasMore,
  setProducts,
}: {
  page: () => number;
  setPage: (page: number) => void;
  setHasMore: (hasMore: boolean) => void;
  setProducts: (fn: (prev: Product[]) => Product[]) => void;
}) => {
  try {
    const newPage = page() + 1;
    const result = await getProductSearch({
      query: "",
      pagination: {
        limit: PRODUCTS_PER_PAGE,
        pageNo: newPage,
      },
    });

    if (!result?.data?.[0]?.products?.length) {
      setHasMore(false);
      return;
    }

    setPage(newPage);
    setProducts((prev) => [...prev, ...result.data[0].products]);

    // Check if we got less than the requested limit
    setHasMore(result.data[0].products.length >= PRODUCTS_PER_PAGE);
  } catch (error) {
    console.error("Failed to fetch products:", error);
    setHasMore(false);
  }
};

const fetchCategoryProducts = async ({
  categoryName,
  setCurrentCategoryProducts,
}: {
  categoryName: string;
  setCurrentCategoryProducts: (products: Product[]) => void;
}) => {
  try {
    const result = await getProductSearch({
      category: categoryName,
    });

    setCurrentCategoryProducts(result.data[0].products);
  } catch (error) {
    console.error("Failed to fetch category products:", error);
    setCurrentCategoryProducts([]);
  }
};

const CategorySectionTitle = (props: {
  title: string;
  brandsCount: number;
}) => {
  return (
    <div class="flex items-center gap-3 px-5">
      <p class="text-nowrap">
        <span class="text-bold text-textDark">{props.title}</span>{" "}
        <svg
          class="inline"
          xmlns="http://www.w3.org/2000/svg"
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
        >
          <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
        </svg>{" "}
        <span class="text-medium text-textNormal">
          {props.brandsCount} brands
        </span>
      </p>
      <hr class="h-px w-full bg-baseTertiaryMedium" />
    </div>
  );
};

type CatalogueRouteState = {
  from?: string;
};

export default function Catalogue() {
  const routeData: Accessor<CatalogueRouteData | undefined> =
    createAsync<CatalogueRouteData>(
      () => {
        return getCatalogueRouteData$C();
      },
      {
        deferStream: true,
      }
    );

  const { openModal, closeModal, setInnerFrameClass } = useModal()!;
  const { addToCart } = useSquidContext();
  const [page, setPage] = createSignal(0);
  const [hasMore, setHasMore] = createSignal(true);
  const [products, setProducts] = createSignal<Array<Product>>([]);

  const categoryNameToCategory: Accessor<Record<string, CategoryData>> =
    createMemo(() => {
      if (!routeData()) return {};

      return routeData()!.allCategories.reduce(
        (map: Record<string, CategoryData>, category) => {
          map[category.name] = category;
          return map;
        },
        {}
      );
    });
  const location = useLocation<CatalogueRouteState>();

  createEffect(() => {
    // setup initial data for products
    if (routeData() && products().length === 0 && routeData()?.allBrands) {
      setProducts(routeData()!.allBrands);
      setHasMore(routeData()!.allBrands.length >= PRODUCTS_PER_PAGE);
    }
  });

  const [currentCategoryProducts, setCurrentCategoryProducts] = createSignal<
    Product[] | null
  >(null);

  const [viewMode, setViewMode] = createSignal<
    "default" | "category" | "loading"
  >("default");
  const [selectedCategory, setSelectedCategory] = createSignal<string | null>(
    null
  );

  const [showCategoryOverlay, setShowCategoryOverlay] = createSignal(false);

  let allCategoriesPillRef: HTMLDivElement;
  let allCategoriesOverlayRef: HTMLDivElement;

  onMount(() => {
    if (location.state?.from === "login") {
      // clear location state, do not show it again
      window.history.replaceState({}, "");
      checkUserConfigAndDisplayCodeAnnouncementModal();
    }
    // showHubbleCodeAnnouncementModal();

    const handleClickOutside = (event: MouseEvent) => {
      console.log("handleClickOutside");

      // if we click on a category item in the overlay, don't do anything
      // let the category item handle the click
      if (
        (event.target as HTMLElement).id.includes("category-item-in-overlay")
      ) {
        console.log("clicked on category item in overlay, returning");
        return;
      }

      event.stopPropagation();
      console.log("consuming the event");

      if (
        // @ts-ignore
        allCategoriesPillRef &&
        allCategoriesPillRef.contains(event.target as Node)
      ) {
        console.log("clicked on allCategoriesPillRef");
        setShowCategoryOverlay(!showCategoryOverlay());
        return;
      }

      if (
        // @ts-ignore
        allCategoriesOverlayRef &&
        !allCategoriesOverlayRef.contains(event.target as Node) &&
        showCategoryOverlay()
      ) {
        console.log("clicked outside of allCategoriesOverlayRef");
        setShowCategoryOverlay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    onCleanup(() => {
      document.removeEventListener("mousedown", handleClickOutside);
    });
  });

  const openDenominationModal = ({
    discountPercentage,
    brandKey,
  }: {
    discountPercentage: number;
    brandKey: string;
  }) => {
    if (brandKey === "hubble-one") {
      openModal(() => {
        return (
          <BrandDetailDenominationModal
            brandKey={brandKey}
            discountPercentage={discountPercentage}
            addToCart={addToCart}
          />
        );
      }, "lg:max-w-[900px] lg:w-[900px] lg:max-h-[714px] lg:h-[714px] h-fit rounded-[28px]");
    } else {
      openModal(() => {
        return (
          <DenominationDetailsModal
            discountPercentage={discountPercentage}
            addToCart={addToCart}
            brandKey={brandKey}
          />
        );
      }, "lg:w-[360px] lg:h-[480px] h-fit rounded-[20px]");
    }
  };

  const debouncedFetchNextPage = debounce(
    () =>
      fetchNextPage({
        page,
        setPage,
        setHasMore,
        setProducts,
      }),
    300
  );
  const [isSearchActive, setIsSearchActive] = createSignal(false);
  const [clearSearch, setClearSearch] = createSignal(false);

  const [selectedNonTopCategory, setSelectedNonTopCategory] = createSignal<
    string | null
  >(null);

  const displayCategories = createMemo(() => {
    const nonTopCategory = selectedNonTopCategory();
    const displayCategories = nonTopCategory
      ? [nonTopCategory, ...SQUID_TOP_CATEGORIES]
      : SQUID_TOP_CATEGORIES;
    return displayCategories.slice(0, SQUID_TOP_CATEGORIES.length);
  });

  const handleCategoryClick = async (categoryName: string) => {
    if (categoryName === selectedCategory()) {
      setSelectedCategory(null);
      setSelectedNonTopCategory(null);
      setViewMode("default");
      return;
    }

    if (!SQUID_TOP_CATEGORIES.includes(categoryName)) {
      setSelectedNonTopCategory(categoryName);
    } else {
      setSelectedNonTopCategory(null);
    }

    setSelectedCategory(categoryName);
    setViewMode("loading");
    await fetchCategoryProducts({
      categoryName,
      setCurrentCategoryProducts,
    });
    setViewMode("category");
  };

  async function checkUserConfigAndDisplayCodeAnnouncementModal() {
    const _codeAnnouncementModalDisplayCountKey =
      "hubble_code_announcement_modal_display_count";

    const _maxCodeAnnouncementModalDisplayCount = 3;

    let _showHubbleCodeAnnouncementModal = false;

    let userConfig = await getUserConfig();

    const codeAnnouncementModalDisplayCount =
      userConfig[_codeAnnouncementModalDisplayCountKey] ?? 0;

    if (
      codeAnnouncementModalDisplayCount < _maxCodeAnnouncementModalDisplayCount
    ) {
      _showHubbleCodeAnnouncementModal = true;
      const newCodeAnnouncementModalDisplayCount =
        codeAnnouncementModalDisplayCount + 1;
      userConfig[_codeAnnouncementModalDisplayCountKey] =
        newCodeAnnouncementModalDisplayCount;
      updateUserConfig(userConfig);
    }

    if (_showHubbleCodeAnnouncementModal) {
      showHubbleCodeAnnouncementModal();
    }
  }

  onMount(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const showDummyAnnouncement =
      searchParams.get(QueryParam.Announcement) == "1";
    if (showDummyAnnouncement) {
      showHubbleCodeAnnouncementModal();
    }
  });

  function showHubbleCodeAnnouncementModal() {
    openModal(
      () => {
        return (
          <HubbleCodeAnnouncementModal
            onClick={() => {
              closeModal();
              setInnerFrameClass("");
              openDenominationModal({
                brandKey: "hubble-one",
                discountPercentage: 0,
              });
            }}
          />
        );
      },
      undefined,
      "!p-4",
      {
        showFloatingCrossIcon: true,
      }
    );
  }

  // @ts-ignore
  return (
    <Suspense
      fallback={
        <div class="flex items-center justify-center">
          <DottedLoader color="#999" from="squid catalogue suspense" />
        </div>
      }
    >
      <div class="noScrollbar flex-1 overflow-y-auto">
        <div
          class={`sticky top-0 z-${zIndexes.squidSearch} w-full border-b border-baseTertiaryMedium bg-white px-5 py-[10px] `}
        >
          <Search
            setIsSearchActive={setIsSearchActive}
            setClearSearch={setClearSearch}
            clearSearch={clearSearch}
            isSearchActive={isSearchActive}
            isProcurment={true}
            allBrands={products}
            openDenominationModal={(product: Product) => {
              return openDenominationModal({
                brandKey: product.brandKey,
                discountPercentage: product.voucherProduct?.discountPercentage,
              });
            }}
          />
        </div>

        <Spacer height={20} />

        <div class="noScrollbar flex flex-row justify-between gap-1 px-5">
          <div
            // @ts-ignore
            ref={allCategoriesPillRef}
            class="relative flex h-full w-[80px] cursor-pointer flex-col items-center justify-center gap-1"
          >
            <div
              // @ts-ignore
              ref={allCategoriesOverlayRef}
              class="absolute left-0 top-[100%] mt-2 flex w-[300px] flex-col gap-1 rounded-[12px] border border-baseTertiaryDark bg-baseTertiaryLight p-3"
              classList={{
                hidden: !showCategoryOverlay(),
              }}
              style={{
                "z-index": zIndexes.squidCategoryOverlay,
                "box-shadow": "0px 2px 20px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <span class="mx-2 my-[5px] w-[125px] select-none text-medium text-textNormal">
                Categories
              </span>
              <div class="grid grid-cols-2">
                <For each={routeData()?.allCategories}>
                  {(category) => (
                    <CategoryItemInOverlay
                      category={category}
                      onClick={handleCategoryClick}
                      setShowCategoryOverlay={setShowCategoryOverlay}
                    />
                  )}
                </For>
              </div>
            </div>
            <div
              class="flex h-[60px] w-[60px] items-center justify-center rounded-full border-[1px] border-[#ddd]"
              style={{
                "box-shadow": "0px 1px 2px rgba(0, 0, 0, 0.02)",
              }}
              classList={{
                "border-basePrimaryDark": showCategoryOverlay(),
              }}
            >
              <AllCategoriesIcon />
            </div>
            <span class="w-full select-none overflow-hidden truncate text-ellipsis whitespace-nowrap text-center text-f12Bold text-textDark">
              All categories
            </span>
          </div>
          <For each={displayCategories()}>
            {(categoryName) => (
              <CategoryPill
                categoryName={categoryName}
                categoryNameToCategory={categoryNameToCategory}
                selectedCategory={selectedCategory}
                handleCategoryClick={handleCategoryClick}
              />
            )}
          </For>
        </div>

        <Spacer height={32} />

        <Switch>
          <Match when={viewMode() === "loading"}>
            <div class="mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto px-4 md:grid-cols-5 md:gap-x-8 lg:grid-cols-6">
              <For each={[1, 2, 3, 4, 5, 6]}>
                {(_) => {
                  return <BrandLoadingPlaceholder />;
                }}
              </For>
            </div>
          </Match>
          <Match when={viewMode() === "category"}>
            <div>
              <CategorySectionTitle
                title={
                  categoryNameToCategory()[selectedCategory()!]?.metadata
                    ?.title ?? selectedCategory()
                }
                brandsCount={currentCategoryProducts()?.length || 0}
              />
              <div class="mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto px-4 md:grid-cols-5 md:gap-x-8 lg:grid-cols-6">
                <For each={currentCategoryProducts()}>
                  {(brand) => (
                    <SquidHomeBrandCard
                      brand={brand}
                      onClick={openDenominationModal}
                    />
                  )}
                </For>
              </div>
            </div>
          </Match>
          <Match when={viewMode() === "default"}>
            <div>
              <HubbleProductBanners
                onHubbleOneClick={() => {
                  openDenominationModal({
                    brandKey: "hubble-one",
                    discountPercentage: 0,
                  });
                }}
              />
              <PopularBrands
                products={routeData()?.popularBrands}
                onBrandClick={openDenominationModal}
              />
              <div class="mb-8 mt-4">
                <div class="flex items-center gap-3 px-5">
                  <p class="text-nowrap text-subtitleSmall text-textDark">
                    All brands
                  </p>
                  <hr class="h-px w-full bg-baseTertiaryMedium" />
                </div>
                <div class="mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto px-4 md:grid-cols-5 md:gap-x-8 lg:grid-cols-6">
                  <InfiniteScroll
                    hasMore={hasMore()}
                    next={debouncedFetchNextPage}
                  >
                    <For each={products()}>
                      {(brand) => (
                        <SquidHomeBrandCard
                          brand={brand}
                          onClick={openDenominationModal}
                        />
                      )}
                    </For>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </Match>
        </Switch>
      </div>
    </Suspense>
  );
}

type CategoryPillProps = {
  categoryName: string;
  handleCategoryClick: (category: string) => void;
  selectedCategory: Accessor<string | null>;
  categoryNameToCategory: Accessor<Record<string, CategoryData>>;
};

function CategoryPill(props: CategoryPillProps) {
  const IconComponent =
    squidCategoryIcons[props.categoryName] ?? SquidCategoryFallbackSvg;
  return (
    <div
      class="relative flex h-full w-[80px] cursor-pointer flex-col items-center justify-center gap-1"
      onClick={() => props.handleCategoryClick(props.categoryName)}
    >
      <div
        class={`flex h-[60px] w-[60px] items-center justify-center rounded-full border-[1px] ${
          props.selectedCategory() === props.categoryName
            ? "border-basePrimaryDark bg-basePrimaryDark text-white"
            : "border-[#ddd] hover:bg-baseTertiaryMedium"
        }`}
        style={{
          "box-shadow": "0px 1px 2px rgba(0, 0, 0, 0.02)",
        }}
      >
        <IconComponent />
        {props.selectedCategory() === props.categoryName && (
          <div class="absolute right-[8px] top-0 flex h-5 w-5 items-center justify-center rounded-full border-[1px] border-baseTertiaryDark bg-baseTertiaryLight p-[2px] text-basePrimaryDark">
            <CloseIcon />
          </div>
        )}
      </div>
      <span class="w-full select-none overflow-hidden truncate text-ellipsis whitespace-nowrap text-center text-f12Bold text-textDark">
        {props.categoryNameToCategory()[props.categoryName]?.metadata?.title ??
          props.categoryName}
      </span>
    </div>
  );
}

type CategoryItemProps = {
  category: CategoryData;
  onClick: (categoryName: string) => Promise<void>;
  setShowCategoryOverlay: Setter<boolean>;
};

function CategoryItemInOverlay(props: CategoryItemProps) {
  return (
    <span
      // `id` is used in handleClickOutside to tell that handler to not do anything and bubble up the event
      id={`${"category-item-in-overlay-" + props.category.name}`}
      class="w-[125px] cursor-pointer select-none rounded-[8px] px-2 py-[5px] text-left text-f12Bold text-textDark hover:bg-baseTertiaryMedium"
      onClick={async () => {
        console.log(`category item in overlay clicked: ${props.category.name}`);
        props.setShowCategoryOverlay(false);
        await props.onClick(props.category.name);
      }}
    >
      {props.category.metadata.title}
    </span>
  );
}
