"use server";

import { getSquidOrderDetail } from "../apis/server_apis";
import { SquidOrderDetailResponse } from "../types/order";

export type SquidOrderDetailRouteData = {
  details: SquidOrderDetailResponse;
};

export type ProcurementOrderDetailProps = {
  orderId: string;
};

export const getSquidOrderDetailRouteData = async (
  orderId: string
): Promise<SquidOrderDetailRouteData> => {
  let details = await getSquidOrderDetail({ orderId: orderId });
  return { details };
};
