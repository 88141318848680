import { createContext, JSX, useContext } from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";
import { SmartGCBrandDetails } from "~/server/types/gift";
import LocalStorageUtil from "~/utils/local_storage";
import { LocalStorageKey } from "~/types";

type SmartGreetingProviderProps = {
  children: JSX.Element;
};

export interface SmartGCDetails {
  brandDetails: null | SmartGCBrandDetails[];
  budget: null | number;
  senderName: null | string;
  receiverCardImageUrl: null | string;
  senderCardImageUrl: null | string;
  activationCode: null | string;
  curationId: null | string;
  receiverCardBackgroundColour: null | string;
}

type SmartGreetingContextValue = {
  smartGCDetails: SmartGCDetails;
  setSmartGCDetails: SetStoreFunction<SmartGCDetails>;
  setSmartGCDetailsToLocalForPreview: () => void;
  getSmartGCDetailsFromLocalPostPreview: () => void;
};

const SmartGreetingContext = createContext<SmartGreetingContextValue>();

export function SmartGreetingProvider(props: SmartGreetingProviderProps) {
  const [smartGCDetails, setSmartGCDetails] = createStore<SmartGCDetails>({
    curationId: null,
    brandDetails: null,
    budget: null,
    senderName: null,
    receiverCardImageUrl: null,
    senderCardImageUrl: null,
    activationCode: null,
    receiverCardBackgroundColour: null,
  });

  const setSmartGCDetailsToLocalForPreview = () => {
    LocalStorageUtil.setItem(
      LocalStorageKey.SmartGcDetails,
      JSON.stringify(smartGCDetails),
      15
    );
    LocalStorageUtil.setItem(LocalStorageKey.IsPreview, "true", 15);
  };

  const getSmartGCDetailsFromLocalPostPreview = () => {
    if (
      LocalStorageUtil.getItem<string>(LocalStorageKey.IsPreview) === "true"
    ) {
      const storedSmartGCDetails = JSON.parse(
        LocalStorageUtil.getItem<string>(LocalStorageKey.SmartGcDetails)!
      );

      setSmartGCDetails("brandDetails", storedSmartGCDetails.brandDetails);
      setSmartGCDetails("budget", storedSmartGCDetails.budget);
      setSmartGCDetails("senderName", storedSmartGCDetails.senderName);
      setSmartGCDetails(
        "receiverCardImageUrl",
        storedSmartGCDetails.receiverCardImageUrl
      );
      setSmartGCDetails(
        "senderCardImageUrl",
        storedSmartGCDetails.senderCardImageUrl
      );
      setSmartGCDetails("curationId", storedSmartGCDetails.curationId);
      setSmartGCDetails(
        "receiverCardBackgroundColour",
        storedSmartGCDetails.receiverCardBackgroundColour
      );

      LocalStorageUtil.removeItem("smartGCDetails");
      LocalStorageUtil.removeItem("isPreview");
    }
  };

  return (
    <SmartGreetingContext.Provider
      value={{
        smartGCDetails,
        setSmartGCDetails,
        setSmartGCDetailsToLocalForPreview,
        getSmartGCDetailsFromLocalPostPreview,
      }}
    >
      {props.children}
    </SmartGreetingContext.Provider>
  );
}

export function useSmartGreeting() {
  return useContext(SmartGreetingContext)!;
}
