import { useNavigate, useParams } from "@solidjs/router";

export default function BrandL2PageDeprecated() {
  const navigate = useNavigate();
  const params = useParams();

  const brandKey = params.brandKey;
  if (brandKey.endsWith("-gift-card")) {
    const actualBrand = brandKey.replace("-gift-card", "");
    navigate(`/buy-gift-card/${actualBrand}`);
  } else {
    navigate(`/buy-gift-card/${brandKey}`);
  }
}
