import { useNavigate } from "@solidjs/router";
import { failureIcon } from "~/assets/assets";
import { useModal } from "../modal";

export const PaymentFailure = ({
  modal,
  closeLink,
}: {
  modal?: boolean;
  closeLink: string;
}) => {
  const { closeModal } = useModal()!;
  const navigate = useNavigate();

  return (
    <div
      class={`relative flex  w-full flex-col overflow-y-auto bg-[linear-gradient(180deg,_#350E07_0%,_#000_60%)] md:w-[800px] ${modal ? "h-[311px] rounded-2xl p-6" : " h-full"}`}
    >
      <div
        class={`${!modal ? "relative top-2/4  -translate-y-1/2" : ""} flex flex-col items-center justify-center`}
      >
        <img src={failureIcon} alt="failure icon" class="h-11 w-11" />
        <p class="mt-4 text-h3 text-errorDark">Payment failed</p>
        <p class="text-normal text-white">
          Please try again or come back later
        </p>
      </div>
      <div
        class={`${!modal ? "absolute" : "mt-6"}  bottom-0 flex flex-col items-center justify-center gap-4 p-4`}
      >
        <p class="text-center text-normal text-textNormal">
          Any money deducted will be refunded in 3-5 business days
        </p>
        <button
          onClick={() => {
            if (modal) {
              closeModal();
            } else {
              return navigate(closeLink);
            }
          }}
          class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
        >
          <p class="text-buttonMedium font-bold text-textDark">Close</p>
        </button>
      </div>
    </div>
  );
};
