import { EventName } from "~/data/events";

export function hostOnAnalyticsEvent(event: EventName, properties?: object) {
  try {
    (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onAnalyticsEvent === "function" &&
      (window as any).AndroidHost.onAnalyticsEvent(
        event,
        JSON.stringify(properties || {})
      );
  } catch (e) {}

  // post a message to window
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "analytics",
        event,
        properties,
      })
    );
  } catch (e) {}

  try {
    window.parent.postMessage(
      JSON.stringify({
        type: "event",
        event,
        properties: properties || {},
      }),
      "*"
    );
  } catch (e) {}
}

export function hostReload() {
  if (
    (window as any).AndroidHost &&
    typeof (window as any).AndroidHost.reload === "function"
  ) {
    (window as any).AndroidHost.reload();
    return true;
  }

  return false;
}

export function hostOnAppLoaded() {
  try {
    if (
      (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onAppReady === "function"
    ) {
      (window as any).AndroidHost.onAppReady();
    }
  } catch (e) {}

  // post a message to window
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "action",
        action: "app_ready",
      })
    );
  } catch (e) {}
}

export function hostOnError() {
  try {
    if (
      (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onError === "function"
    ) {
      (window as any).AndroidHost.onError();
    }
  } catch (e) {}

  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "action",
        action: "app_error",
      })
    );
  } catch (e) {}
}
