import { toRupees } from "~/utils/number";
import { Meta, Title } from "@solidjs/meta";
import VerticalDragReveal, { useVerticalDragRevealPlayLottie } from "~/components/vertical_drag_reveal";
import { ButtonDark } from "~/components/gifting/verification/components";
import { useNavigate, useParams, useSearchParams } from "@solidjs/router";
import { createJob } from "~/utils/job";
import {
  getCoinsSummary,
  getGiftBoxV3,
  getGiftMetaData,
  getProductSearch,
  sendOtpForGiftBox,
  verifyGiftOtpV3,
} from "~/server/apis/client_apis";
import {
  GetGiftBoxV3Response,
  GiftBoxMetadata,
  SmartGCBrandDetails,
} from "~/server/types/gift";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Match,
  onMount,
  Setter,
  Show,
  Switch,
} from "solid-js";
import { DottedLoader } from "~/widgets/loader";
import HubbleImage from "~/widgets/hubble_image";
import { useModal } from "~/components/modal";
import { AuthenticationModal } from "~/components/smart_greeting/modals/authentication_modal";
import LocalStorageUtil from "~/utils/local_storage";
import {
  getV3GiftingDataStorageKey,
  gift_v3cache_duration_in_minutes,
  storeV3GiftSessionId,
} from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { showSnackBar } from "~/shared_states/snackbar";
import { PhosphorIcon } from "~/widgets/icons";
import { clearCookie, getCookie } from "~/utils/client_cookie";
import { generateDeviceVerificationToken } from "~/utils/common";
import { Header } from "~/types";
import { GiftBrandListingData } from "~/components/gifting/brand-collection-landing/cards_list";
import { APIError } from "~/utils/fetch";
import {
  closeGreyIcon,
  flipIcon,
  heartDivider,
  HubbleFullSvg,
  hubbleLogoSmartGCCover,
  soundOffIcon,
  soundOnIcon,
} from "~/assets/assets";
import { ActivationCodeInfoModal } from "~/components/smart_greeting/modals/activation_code_info_modal";
import { collectOtp } from "~/components/gifting/verification/verification";
import { popPage } from "~/shared_states/modal";
import { decodeBase64 } from "~/utils/encoding";
import { glitter_lottie } from "~/assets/assets";
import useScript from "~/utils/hooks/use_script";
import { isServer } from "solid-js/web";

if (!isServer && !window.customElements.get("lottie-player")) {
  useScript(
    "https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js",
    async () => {
      console.log(`lottie player loaded`);
    }
  );
}

type PreviewData = {
  senderName: string;
  budget: number;
  brandDetails: SmartGCBrandDetails[];
  receiverCardImageUrl: string;
  receiverCardBackgroundColour: string;
  wishMessage?: string;
  revealMusicUrl?: string;
  wishMessageBackgroundImageUrl?: string;
};

export default function SmartGcRevealLandingRoute() {
  const params = useParams();
  const giftingKey = params.giftingKey as string;

  const [errorMessage, setErrorMessage] = createSignal<string | undefined>(
    undefined
  );
  const [queryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";
  let previewData: PreviewData | undefined = undefined;
  const dataParam = queryParams.data;

  if (isPreview) {
    if (!dataParam) {
      throw new Error("Preview data is not present");
    }

    previewData = JSON.parse(decodeBase64(queryParams.data as string)) as PreviewData;
  }

  const [isActivationCodeInfoModalOpen, setActivationCodeInfoModalOpen] =
    createSignal(false);
  const [showLoginState, setShowLoginState] = createSignal<boolean>(false);
  const [showViewCardsState, setShowViewCardsState] =
    createSignal<boolean>(false);
  const [showLoader, setShowLoader] = createSignal<boolean>(false);
  const [isCardFlipped, setIsCardFlipped] = createSignal(false);

  const [isAudioPlaying, setIsAudioPlaying] = createSignal(false);
  const [isSoundTooltipVisible, setIsSoundTooltipVisible] = createSignal(false);
  let audioElement: HTMLAudioElement | undefined;

  const getGreetingDataJob = createJob<GiftBoxMetadata>({
    initialJob: async () => {
      return await getGiftMetaData(giftingKey);
    },
    errorCallback: (error) => {
      if (error instanceof APIError && error.code == 401) {
        clearCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));
        window.location.reload();
        return;
      }
      setErrorMessage(error.message);
    },
    successCallback: async (response) => {
      setErrorMessage(undefined);
    },
  });

  const getGiftAndManageStates = async () => {
    try {
      let deviceVerificationToken: string | undefined = undefined;
      let sessionId = getCookie(
        getV3GiftingDataStorageKey(giftingKey, "sessionId")
      );

      deviceVerificationToken = await getDeviceToken();

      let giftBoxMetadata = await getGiftBoxV3(
        giftingKey,
        {
          [Header.FpDeviceToken]: deviceVerificationToken ?? "",
          [Header.SessionId]: sessionId ?? "",
        },
        {
          isUnauthenticated: sessionId ? false : true,
        }
      );

      if (giftBoxMetadata.sessionId || sessionId) {
        sessionId = giftBoxMetadata.sessionId ?? sessionId;

        try {
          const coinsResponse = await getCoinsSummary({
            [Header.SessionId]: sessionId!,
          });
          if (coinsResponse.totalConsumed > 0) {
            setShowViewCardsState(true);
          }
        } catch (e) {}
        await setupGiftingData({
          giftingKey: giftingKey,
          sessionId: sessionId!,
          giftBoxMetadata,
        });
      } else {
        if (
          giftBoxMetadata.hasVerifiedPhoneNumber &&
          !giftBoxMetadata.sessionId
        ) {
          setShowLoginState(true);
        }
      }
    } catch (e: any) {
      console.log("Error in getGift function", e.message);
    }
  };

  const pageSetup = createJob({
    initialJob: async () => {
      await getGiftAndManageStates();
    },
  });

  onMount(() => {
    if (isPreview && previewData) {
      return;
    }
    getGreetingDataJob.run();
    pageSetup.run();
  });

  createEffect(() => {
    if (
      getGreetingDataJob.jobState() === "success" ||
      (isPreview && previewData)
    ) {
      const musicUrl =
        isPreview && previewData
          ? previewData.revealMusicUrl
          : getGreetingDataJob.jobResult()?.metadata.revealMusicUrl;

      if (musicUrl) {
        audioElement = new Audio(musicUrl);
        audioElement.loop = true;
      }
    }
  });

  const toggleAudio = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!audioElement) return;

    if (isAudioPlaying()) {
      audioElement.pause();
    } else {
      audioElement.play();
      setIsSoundTooltipVisible(false);
    }
    setIsAudioPlaying(!isAudioPlaying());
  };

  const handleCloseSoundToolTip = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSoundTooltipVisible(false);
  };

  const [isFirstFlip, setIsFirstFlip] = createSignal(true);
  let glitterLottieElement: HTMLElement | undefined;

  const playLottie = () => {
    try {
      // @ts-ignore
      glitterLottieElement?.play();
    } catch (e) {
      console.error(e);
    }
  };

  const handleFlip = () => {
    if (hasWishMessage()) {
      setIsCardFlipped(!isCardFlipped());
      if (isFirstFlip()) {
        setIsFirstFlip(false);
        setTimeout(() => {
          playLottie();
        }, 500); 
      }
    }
  };

  const handleShowSoundToolTip = () => {
    setTimeout(() => {
      setIsSoundTooltipVisible(true);
    }, 800);
  };

  const hasWishMessage = () => {
    return isPreview && previewData
      ? previewData.wishMessage
      : getGreetingDataJob.jobResult()?.metadata.wishMessage;
  };

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <Show when={isPreview}>
        <PreviewHeader />
      </Show>
      <div class={`h-full w-full ${isPreview ? "relative pt-[52px]" : ""}`}>
        <Show
          when={
            (getGreetingDataJob.jobState() == "success" &&
              getGreetingDataJob.jobResult() != null &&
              (pageSetup.jobState() == "success" ||
                pageSetup.jobState() == "error")) ||
            (isPreview && previewData)
          }
          fallback={<DottedLoader />}
        >
          <>
            <MetaTags />
            <Show
              when={
                (isPreview
                  ? previewData?.revealMusicUrl
                  : getGreetingDataJob.jobResult()?.metadata.revealMusicUrl)
              }
            >
              <div
                class="absolute right-4 z-20 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border  p-2 border-[#ffffff33] bg-[#0000004d]"
                onClick={(e) => toggleAudio(e)}
                classList={{
                  "top-[1.55rem]": !isPreview,
                  "top-[4.8rem]": isPreview,
                }}
              >
                <img
                  src={isAudioPlaying() ? soundOnIcon : soundOffIcon}
                  alt={isAudioPlaying() ? "sound on" : "sound off"}
                />
              </div>
              <Show when={isSoundTooltipVisible()}>
                <SoundTooltip />
              </Show>
            </Show>
            <VerticalDragReveal
              greetingMessage="Hey there 👋"
              senderName={
                isPreview && previewData
                  ? previewData.senderName
                  : getGreetingDataJob.jobResult()?.metadata.senderName
              }
              brandingImageSrc={hubbleLogoSmartGCCover}
              onAnimationComplete={handleShowSoundToolTip}
            >
              <Show
                when={!isPreview}
                fallback={<RevealCard previewData={previewData!}></RevealCard>}
              >
                <RevealCard
                  data={getGreetingDataJob.jobResult()!}
                  setActivationCodeInfoModalOpen={
                    setActivationCodeInfoModalOpen
                  }
                  showLoginState={showLoginState}
                  showViewCardsState={showViewCardsState}
                ></RevealCard>
              </Show>
            </VerticalDragReveal>
          </>
        </Show>
      </div>
      <Show when={isActivationCodeInfoModalOpen()}>
        <ActivationCodeInfoModal
          closeActivationCodeInfoModal={() => {
            setActivationCodeInfoModalOpen(false);
          }}
          userName={
            !isPreview && getGreetingDataJob.jobResult()
              ? getGreetingDataJob.jobResult()!.metadata.senderName
              : ""
          }
        />
      </Show>
    </>
  );

  function SoundTooltip() {
    return (
      <div class="relative">
        <div class="absolute right-4 top-[4.8rem] z-20 h-[60px] w-[129px] rounded-lg bg-[#0754D1] px-4 py-3 text-medium text-white">
          Sound ON for best experience
          <img
            src={closeGreyIcon}
            class="absolute right-1.5 top-1.5 cursor-pointer"
            alt="close tool tip"
            onClick={(e) => handleCloseSoundToolTip(e)}
          />
          <svg
            class="absolute -top-2 right-[0.8rem]"
            width="16"
            height="10"
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.43826 0.952173C7.23891 -0.0486406 8.76109 -0.0486412 9.56174 0.952172L16 9H0L6.43826 0.952173Z"
              fill="#0754D1"
            />
          </svg>
        </div>
      </div>
    );
  }

  function PreviewHeader() {
    const navigate = useNavigate();
    return (
      <div class="fixed left-0 right-0 top-0 z-50 flex h-[56px] flex-row  items-center justify-start text-nowrap border-b border-basePrimaryMedium bg-black px-1  text-white">
        <div
          class="flex items-center py-1.5 pl-4 pr-1"
          onClick={() => {
            navigate(-1);
            if (audioElement) {
              audioElement.pause();
              audioElement.currentTime = 0;
            }
          }}
        >
          <PhosphorIcon
            name="x"
            fontSize={18}
            size="bold"
            class="cursor-pointer"
          />
        </div>
        <span class="p-2 text-bold">Receiver's preview</span>
      </div>
    );
  }

  function RevealCard(
    props:
      | {
          data: GiftBoxMetadata;
          setActivationCodeInfoModalOpen: Setter<boolean>;
          showLoginState: Accessor<boolean>;
          showViewCardsState: Accessor<boolean>;
        }
      | {
          previewData: PreviewData;
        }
  ) {
    const navigate = useNavigate();
    const { openModal } = useModal()!;
    const params = useParams();
    const giftingKey = params.giftingKey as string;
    const isPreviewData = "previewData" in props;

    function sessionId() {
      return getCookie(getV3GiftingDataStorageKey(giftingKey, "sessionId"));
    }

    return (
      <div class="flex flex-col gap-5">
        <div
          class="relative aspect-[343/541] h-[548px] overflow-visible [perspective:2000px]"
          onClick={handleFlip}
        >
          <div class="pointer-events-none absolute z-50 h-full w-full max-w-[400px] overflow-hidden">
            <lottie-player
              ref={glitterLottieElement}
              src={glitter_lottie}
              autoplay={false}
              loop={false}
            ></lottie-player>
          </div>
          <div
            class={`relative h-full w-full transition-all duration-1000 ease-in-out 
            [transform-origin:center_center] [transform-style:preserve-3d] ${
              isCardFlipped()
                ? "[transform:rotateY(180deg)]"
                : "[transform:rotateY(0deg)]"
            }`}
          >
              <Show when={hasWishMessage()}>
              <div
                class="absolute w-[343px] mx-auto inset-0 rounded-3xl bg-cover bg-center bg-no-repeat will-change-transform  [backface-visibility:hidden]"
                style={{
                  "background-image": `url(${
                    (isPreviewData
                      ? props.previewData.wishMessageBackgroundImageUrl
                      : props.data.metadata.wishMessageBackgroundImageUrl) ?? ""
                  })`,
                }}
              >
                <div class="flex h-full flex-col items-center justify-center gap-4 px-8 py-6 text-white">
                  <div class="text-center text-h2">
                    Happy <br />
                    Valentine’s Day
                  </div>
                  <img src={heartDivider} alt="" />
                  <div class="text-[18px] font-normal whitespace-pre-wrap break-words w-[279px]">
                    {isPreview && previewData
                      ? previewData.wishMessage ?? ""
                      : getGreetingDataJob.jobResult()?.metadata.wishMessage ??
                        ""}
                  </div>
                  <div class="text-[18px] font-normal">
                    -{" "}
                    {isPreviewData
                      ? props.previewData?.senderName
                      : props.data.metadata.senderName}
                  </div>
                </div>
              </div>
            </Show>
            <div
              class="absolute inset-0 flex flex-col gap-4 rounded-3xl p-4 will-change-transform [transform:rotateY(180deg)] [backface-visibility:hidden]"
              style={{
                "background-color":
                  (isPreviewData
                    ? props.previewData.receiverCardBackgroundColour
                    : props.data.metadata.receiverCardBackgroundColour) ??
                  "#004D57",
              }}
            >
              <div>
                <img
                  src={
                    isPreviewData
                      ? props.previewData?.receiverCardImageUrl
                      : props.data?.metadata.receiverCardImageUrl
                  }
                  class="h-[255px] w-full rounded-xl"
                />
              </div>
              <div class="inline-flex flex-col gap-3 text-center">
                <div class="text-h3">
                  You have received <br />
                  {toRupees(
                    isPreviewData
                      ? props.previewData?.budget
                      : props.data.metadata.budget
                  )}{" "}
                  as gift
                </div>
                <div class="text-normal">
                  Shop on brands thoughtfully curated for <br />
                  you by{" "}
                  {isPreviewData
                    ? props.previewData?.senderName
                    : props.data.metadata.senderName}
                </div>
              </div>
              <div class="flex justify-between gap-1">
                <For
                  each={
                    isPreviewData
                      ? props.previewData.brandDetails
                      : props.data.metadata.brandDetails
                  }
                >
                  {(brandData) => (
                    <div class="h-[108px] w-[97px] rounded-[16px]">
                      <HubbleImage
                        src={brandData.iconImageUrl ?? brandData.logoUrl}
                        alt={brandData.name}
                        class="h-[108px] w-[97px] rounded-[16px]"
                        imageBorderStyleClass="border border-white border-opacity-20"
                      />
                    </div>
                  )}
                </For>
              </div>
            </div>
          </div>
        </div>
        <Show when={isCardFlipped() && hasWishMessage()}>
          <button
            class="flex items-center justify-center gap-2 text-white"
            onClick={handleFlip}
          >
            <img src={flipIcon} alt="flip icon" />
            <p class="text-mediumBold">View wish message</p>
          </button>
        </Show>
        <Show when={!isPreviewData && props.showViewCardsState()}>
          <div class="mt-4">
            <ButtonDark
              onClick={() => {
                navigate("/gift-box/m/" + giftingKey + "/profile");
              }}
              children={<div>My gift cards</div>}
              class="w-full !rounded-full border border-white bg-transparent text-textWhite"
            />
          </div>
        </Show>
        <ButtonDark
          onClick={() => {
            if (isPreview) {
              showSnackBar({
                level: "info",
                message: "Gift receiver will be able to shop",
              });
              return;
            } else {
              if (audioElement) {
                audioElement.pause();
                audioElement.currentTime = 0;
              }
              if (sessionId()) {
                return navigate("/gift-box/m/" + giftingKey);
              }
              if (!isPreviewData && props.showLoginState()) {
                return verifyUserMobile({
                  giftingKey: giftingKey,
                  onSuccess: () => {
                    popPage();
                    return navigate("/gift-box/m/" + giftingKey);
                  },
                });
              }
              openModal(() => {
                return AuthenticationModal({
                  giftingKey: giftingKey,
                  fetchGiftBoxAndSetDataInLocalStorage:
                    fetchGiftBoxAndSetDataInLocalStorage,
                  openActivationCodeInfoModal: () => {
                    if ("setActivationCodeInfoModalOpen" in props) {
                      props.setActivationCodeInfoModalOpen(true);
                    }
                  },
                });
              }, "dark");
            }
          }}
          children={
            <Switch>
              <Match when={!isPreview && sessionId() && showViewCardsState()}>
                <div>Shop more</div>
              </Match>
              <Match
                when={!isPreviewData && !sessionId() && props.showLoginState()}
              >
                <div>Login to continue</div>
              </Match>
              <Match when={true}>
                <div>Shop now</div>
              </Match>
            </Switch>
          }
          isLoading={showLoader()}
          class="w-full !rounded-full"
        />
        <div class="mb-2 flex flex-col items-center gap-1">
          <p class="text-center text-[10px] font-medium uppercase tracking-[1.8px] text-textWhite">
            Powered by
          </p>
          <HubbleFullSvg class="h-[17.6px] w-[81px]" fill="#fff" />
        </div>
      </div>
    );
  }

  function MetaTags() {
    return (
      <>
        <Title>{`A gift for you`}</Title>
        <Meta name="title" content={`A gift for you`} />
      </>
    );
  }

  async function verifyUserMobile(props: {
    giftingKey: string;
    onSuccess: () => void;
  }) {
    setShowLoader(true);
    let deviceVerificationToken: string | undefined = undefined;
    deviceVerificationToken = await getDeviceToken();

    if (deviceVerificationToken) {
      const response = await sendOtpForGiftBox({
        id: props.giftingKey,
        deviceVerificationToken: deviceVerificationToken,
      });
      setShowLoader(false);
      if (response) {
        collectOtp({
          submitOtp: async (submitOtpProps) => {
            return await verifyGiftOtpV3({
              id: props.giftingKey,
              otp: submitOtpProps.otp,
              otpToken: submitOtpProps.otpToken,
              headers: {
                [Header.FpDeviceToken]: (await getDeviceToken()) ?? "",
              },
            });
          },
          onVerificationSuccess: async (response) => {
            await fetchGiftBoxAndSetDataInLocalStorage({
              sessionId: response.sessionId,
              giftingKey: props.giftingKey,
            });
            props.onSuccess();
          },
          phoneNumber: "",
          otpToken: response.otpToken,
          resendOtp: async (number) => {
            return await sendOtpForGiftBox({
              id: props.giftingKey,
              deviceVerificationToken: deviceVerificationToken,
            });
          },
          buildSubtitle: OtpSubtitle,
        });
      }
    }
  }

  function OtpSubtitle() {
    return (
      <span class="text-medium text-textNormal underline-offset-2">
        Sent to verified mobile number
      </span>
    );
  }

  async function fetchGiftBoxAndSetDataInLocalStorage(props: {
    giftingKey: string;
    activationCode?: string;
    sessionId?: string;
  }) {
    let deviceVerificationToken: string | undefined = undefined;
    let sessionId = props.sessionId;

    deviceVerificationToken = await getDeviceToken();

    let giftingPath = props.giftingKey;

    if (props.activationCode) {
      giftingPath = giftingPath + "?activationCode=" + props.activationCode;
    }

    let giftBoxMetadata = await getGiftBoxV3(
      giftingPath,
      {
        [Header.FpDeviceToken]: deviceVerificationToken ?? "",
        [Header.SessionId]: sessionId ?? "",
      },
      {
        isUnauthenticated: sessionId ? false : true,
      }
    );

    if (giftBoxMetadata.sessionId) sessionId = giftBoxMetadata.sessionId;

    if (sessionId) {
      await setupGiftingData({
        giftingKey: props.giftingKey,
        activationCode: props.activationCode,
        sessionId,
        giftBoxMetadata,
      });
    }
  }

  async function setupGiftingData(props: {
    giftingKey: string;
    activationCode?: string;
    sessionId: string;
    giftBoxMetadata: GetGiftBoxV3Response;
  }) {
    let productSearchResponse = await getProductSearch({
      query: "",
      headers: {
        [Header.SessionId]: props.sessionId,
      },
    });

    let brands: GiftBrandListingData[] =
      productSearchResponse.data[0].products.map((product) => {
        return {
          id: product.id,
          plainLogoUrl: product.voucherProduct.plainLogoUrl,
          cardBackgroundColor: product.voucherProduct.cardBackgroundColor,
          title: product.voucherProduct.title,
          heroImageUrl: product.voucherProduct.heroImageUrl ?? undefined,
          status: product.voucherProduct.status,
        };
      });

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "brands"),
      JSON.stringify(brands),
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "isSmartGC"),
      true,
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "showTooltip"),
      false,
      gift_v3cache_duration_in_minutes
    );

    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "getGiftV3"),
      JSON.stringify(props.giftBoxMetadata),
      gift_v3cache_duration_in_minutes
    );
    storeV3GiftSessionId({
      giftingKey: props.giftingKey,
      sessionId: props.sessionId,
    });
    LocalStorageUtil.setItem(
      getV3GiftingDataStorageKey(props.giftingKey, "clickedUnwrap"),
      true,
      gift_v3cache_duration_in_minutes
    );
  }

  async function getDeviceToken() {
    let deviceVerificationToken: string | undefined = undefined;
    try {
      deviceVerificationToken = await generateDeviceVerificationToken();
    } catch (error) {
      console.log("Error getting device verification token", error);
    }

    return deviceVerificationToken;
  }
}
