import { hubbleCodeBanner } from "~/assets/assets";
import { ButtonRegular } from "~/widgets/button";
import GradientText from "~/widgets/gradient_text";
import HubbleImage from "~/widgets/hubble_image";
import { Spacer } from "~/widgets/spacer";

export default function HubbleCodeAnnouncementModal(props: {
  onClick: () => void;
}) {
  return (
    <div class="relative flex h-full w-full flex-col items-center  text-center text-textDark">
      <HubbleImage
        src={hubbleCodeBanner}
        class="aspect-[36/21] w-full"
        alt="hubble-code-banner"
      />
      <Spacer height={20} />
      <span class="text-smallBold uppercase tracking-[0.77px]">
        <GradientText gradient="linear-gradient(90deg, #5A236C 0%, #B44795 100%)">
          Introducing
        </GradientText>
      </span>
      <Spacer height={8} />
      <span class="text-[24px] font-semibold ">Hubble-ONE gift card</span>
      <Spacer height={2} />
      <span class="text-normal">
        The receiver can redeem this gift card
        <br />
        across 450+ brands
      </span>
      <Spacer height={24} />
      <ButtonRegular
        isEnabled={true}
        class="w-full !rounded-lg"
        onClick={props.onClick}
      >
        Learn more
      </ButtonRegular>
    </div>
  );
}
