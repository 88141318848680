import { cache, createAsync, useNavigate } from "@solidjs/router";
import { Accessor, createSignal, onCleanup, Show, Suspense } from "solid-js";
import { config } from "~/data/config";
import { getSquidProformaInvoicesRouteData } from "~/server/data/squid_proforma_invoices_route_data";
import { SquidProformaInvoicesResponse } from "~/server/types/order";
import { Divider } from "~/widgets/divider";
import { EmptyState } from "~/widgets/empty_state";
import { CircularLoader, DottedLoader } from "~/widgets/loader";
import { formatDateToDDMMMMYYYY } from "~/utils/date";
import { piEmptyState } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { sendPIOverEmail } from "~/server/apis/client_apis";
import { toIndianNumber } from "~/utils/number";
import { Tooltip } from "~/widgets/tooltip";
import { showSnackBar } from "~/shared_states/snackbar";
import { useSquidContext } from "~/context/squid_context";

const getSquidProformaInvoicesRouteData$C = cache(
  getSquidProformaInvoicesRouteData,
  "squid_proforma_invoices"
);

export default function ProformaInvoice() {
  const routeData: Accessor<SquidProformaInvoicesResponse | undefined> =
    createAsync<SquidProformaInvoicesResponse>(() => {
      return getSquidProformaInvoicesRouteData$C();
    });
  const [activeRow, setActiveRow] = createSignal<string>("");
  const { updatePIInfo, clearCart, clearAndAddProductsToCart } =
    useSquidContext();
  const navigator = useNavigate();
  const [isProceedingToCheckout, setIsProceedingToCheckout] =
    createSignal(false);
  const [sendingEmail, setSendingEmail] = createSignal<string[]>([]);

  const sendPIEmail = async (props: { piID: string }) => {
    setSendingEmail((prev) => [...prev, props.piID]);
    try {
      await sendPIOverEmail({ piID: props.piID });
      showSnackBar({
        level: "success",
        message: "email sent to your registered email-id",
      });
    } catch (e: any) {
      showSnackBar({
        level: "error",
        message: e.message,
      });
    }
    setSendingEmail((prev) => prev.filter((id) => id !== props.piID));
  };

  onCleanup(() => {
    if (!isProceedingToCheckout()) {
      clearCart();
    }
  });

  return (
    <Suspense
      fallback={
        <div class="flex w-full items-center justify-center">
          <DottedLoader color="#999" from="squid proforma suspense" />
        </div>
      }
    >
      <div class="w-full">
        <div class="px-5 py-4 text-h5 text-black">Proforma invoice</div>
        <Divider />
        <Show
          when={routeData()?.data && routeData()?.data?.length! > 0}
          fallback={emptyState()}
        >
          {PITable()}
        </Show>
      </div>
    </Suspense>
  );

  function PITable() {
    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 z-10  rounded-full bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  ID
                </th>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  NOTE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.data.map((PI) => (
                <tr
                  class={` text-medium text-textDark  last:rounded-b-lg hover:cursor-pointer   hover:bg-baseTertiaryMedium`}
                  onMouseEnter={() => {
                    setActiveRow(PI.proformaInvoiceId);
                  }}
                  onMouseLeave={() => {
                    setActiveRow("");
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    <div>{PI.proformaInvoiceId}</div>
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {formatDateToDDMMMMYYYY(new Date(PI.date))}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    <div>
                      {PI.notes?.length > 0 ? (
                        <Tooltip
                          parent={
                            <div class="max-w-[132px] overflow-hidden text-ellipsis">
                              {PI.notes}
                            </div>
                          }
                          tooltip={
                            <div class="mt-2 rounded bg-black px-2 py-1 text-textWhite">
                              {PI.notes}
                            </div>
                          }
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    ₹{toIndianNumber(PI.dueAmount)}
                  </td>

                  <td class="relative whitespace-nowrap border-r p-3">
                    <div class="flex items-center justify-center">
                      <div class="grow">
                        {PI.products.length > 4
                          ? PI.products
                              .slice(0, 4)
                              .map((item) => item.productName)
                              .join(", ")
                              .toString() +
                            " +" +
                            (PI.products.length - 4) +
                            " more"
                          : PI.products
                              .map((item) => item.productName)
                              .join(", ")}
                      </div>
                      <Show when={activeRow() === PI.proformaInvoiceId}>
                        <div class="absolute  right-0 flex items-center justify-center pr-3">
                          <Tooltip
                            parent={
                              <div
                                class="flex h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (sendingEmail().includes(PI.id)) {
                                    return;
                                  }
                                  sendPIEmail({ piID: PI.id });
                                }}
                              >
                                <Show
                                  when={sendingEmail().includes(PI.id)}
                                  fallback={
                                    <PhosphorIcon
                                      name="envelope"
                                      fontSize={20}
                                    />
                                  }
                                >
                                  <CircularLoader size={18} />
                                </Show>
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                Send PI over mail
                              </div>
                            }
                          />
                          <Tooltip
                            parent={
                              <div
                                class="mx-2 flex  h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white"
                                onClick={() => {
                                  window.open(
                                    `${config.receiptUrl}/proforma-invoice/${encodeURIComponent(PI.id)}`,
                                    "_blank"
                                  );
                                }}
                              >
                                <PhosphorIcon name="file-pdf" fontSize={20} />
                              </div>
                            }
                            tooltip={
                              <div class="mt-1 rounded bg-black px-2 py-1  text-medium text-white">
                                {" "}
                                View PI
                              </div>
                            }
                          />

                          <div
                            class=" h-7 content-center items-center rounded-[8px] bg-black px-3 text-mediumBold text-white"
                            onClick={(event) => {
                              event.stopPropagation();
                              setIsProceedingToCheckout(true);
                              clearAndAddProductsToCart(PI.products);
                              updatePIInfo(PI.id);
                              navigator("/checkout");
                            }}
                          >
                            Complete order
                          </div>
                        </div>
                      </Show>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Proforma invoices (PI) you generate will appear here"
        secondaryText="No PI generated yet"
        imageUrl={piEmptyState}
      />
    );
  }
}
