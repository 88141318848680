import { useNavigate } from "@solidjs/router";
import { Accessor, createEffect, createSignal, JSX, Show } from "solid-js";
import { loginAsset, squidLogo } from "~/assets/assets";
import { getSignedUrl, squidOnboarding } from "~/server/apis/client_apis";
import { Header } from "~/types";
import LocalStorageUtil from "~/utils/local_storage";
import { ButtonRegular } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";

export default function Register() {
  interface Address {
    address1?: string;
    address2?: string;
    place?: string;
    state?: string;
    pincode?: string;
    country?: string;
  }

  const [error, setError] = createSignal<string | null>(null);
  const [name, setName] = createSignal<string>("");
  const [email, setEmail] = createSignal<string>("");
  const [phone, setPhone] = createSignal<string>("");
  const [businessName, setBusinessName] = createSignal<string>("");
  const [gstNumber, setGstNumber] = createSignal<string>("");
  const [address, setAddress] = createSignal<Address>({
    address1: "",
    address2: "",
    place: "",
    state: "",
    pincode: "",
    country: "India",
  });
  const [file, setFile] = createSignal<File | null>(null);

  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const navigate = useNavigate();

  const errorStrip = (condition: boolean | undefined) => {
    return (
      <Show when={condition}>
        <div class="py-1 text-f12Bold text-errorDark">{error()}</div>
      </Show>
    );
  };

  createEffect(() => {
    if (LocalStorageUtil.getItem("form-data")) {
      const data = LocalStorageUtil.getItem<any>("form-data");

      setName(data["name"]);
      setEmail(data["email"]);
      setPhone(data["phone"]);
      setBusinessName(data["businessName"]);
      setGstNumber(data["businessDetails"]["gstNumber"]);
      if (data["businessDetails"]["address"]) {
        setAddress((prev) => ({
          address1: data["businessDetails"]["address"]["address1"],
          address2: data["businessDetails"]["address"]["address2"],
          pincode: data["businessDetails"]["address"]["pincode"],
          place: data["businessDetails"]["address"]["place"],
          state: data["businessDetails"]["address"]["state"],
        }));
      }
    }
  });

  const handleFileChange = (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      if (input.files[0].size < 5 * 1024 * 1024) {
        setFile(input.files[0]);
      } else {
        setError("GST certificate file-size should be less than 5MB");
      }
    }
  };

  const uploadFile = async () => {
    if (!file()) {
      return undefined;
    }

    try {
      const presignedUrl = await getSignedUrl({ contentType: file()!.type });
      if (presignedUrl.url) {
        const response = await fetch(presignedUrl.url, {
          method: "PUT",
          body: file()!,
          headers: {
            [Header.ContentType]: file()?.type || "application/pdf",
          },
        });

        if (response.ok) {
          return presignedUrl.url.split(".pdf")[0] + ".pdf";
        }
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  createEffect(() => {
    name();
    email();
    phone();
    businessName();
    gstNumber();
    address();
    file();

    setError(null);
  });

  const onboardClient = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const gstCertificateUrl = await uploadFile();
      const onboardingClient = await squidOnboarding({
        businessName: businessName()!,
        emailId: email()!,
        name: name()!,
        phoneNumber: phone()!,
        businessDetails: {
          gstNumber: gstNumber() != "" ? gstNumber() : undefined,
          address:
            address().address1 != "" ||
            address().address2 != "" ||
            address().pincode != "" ||
            address().place != "" ||
            address().state != ""
              ? address()
              : undefined,
          gstCertificateUrl: gstCertificateUrl,
        },
      });
      if (onboardingClient) {
        LocalStorageUtil.setItem(
          "form-data",
          {
            name: name(),
            email: email(),
            phone: phone(),
            businessName: businessName(),
            businessDetails: {
              gstNumber: gstNumber() ?? undefined,
              address:
                address().address1 != "" ||
                address().address2 != "" ||
                address().pincode != "" ||
                address().place != "" ||
                address().state != ""
                  ? address()
                  : undefined,
              gstCertificateUrl: gstCertificateUrl,
            },
          },
          5
        );
        if (onboardingClient.otpVerificationDetails) {
          navigate("/register/verify", {
            state: {
              email: email()!,
              emailToken: onboardingClient.otpVerificationDetails.email,
              phone: phone()!,
              phoneToken: onboardingClient.otpVerificationDetails.phoneNumber,
              id: onboardingClient.id,
            },
          });
        } else {
          navigate("/register/complete", {
            state: {
              id: onboardingClient.id,
            },
          });
        }
      }
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  return (
    <div class="flex min-h-screen ">
      <div class="w-[50%]"></div>
      <div class="fixed bottom-0 left-0 top-0 max-h-screen w-[50%]  bg-gray-400">
        <HubbleImage
          src={loginAsset}
          alt="login-asset"
          errorImage=""
          class="h-full w-full object-cover"
        />
        <div class="absolute left-14 top-10 flex flex-col items-start justify-start ">
          <div class="flex">
            <HubbleImage
              src={squidLogo}
              alt="logo-image"
              errorImage=""
              class="mr-1 h-10 w-10"
            />
            <div class="flex flex-col items-start justify-start">
              <div class="text-bold text-white">SQUID</div>
              <div class="text-f12Bold text-white">By Hubble</div>
            </div>
          </div>
          <div class="mt-11 w-[330px] text-h1 text-white">
            For all your gift card needs
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              450+ brands
            </div>
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              Best deals & prices
            </div>
          </div>
        </div>
      </div>
      <div class="flex  w-[50%] flex-col items-center justify-center  overflow-y-auto  align-middle">
        <div class="flex  flex-col items-start justify-start   gap-4 py-8">
          <div class="text-h2 text-black">Register</div>
          {InputField({
            label: "Your name*",
            errorCheckName: "name",
            inputType: "name",
            value: name,
            onInput: (e) => {
              setName(e.currentTarget.value);
            },
            placeholder: "Enter your full name",
          })}
          {InputField({
            label: "Your email*",
            errorCheckName: "email",
            inputType: "email",
            value: email,
            onInput: (e) => setEmail(e.currentTarget.value),
            placeholder: "Enter your email ID",
          })}
          {/* TODO:Only accept Num */}
          {InputField({
            label: "Your phone number*",
            errorCheckName: "phone",
            inputType: "phone",
            value: phone,
            onInput: (e) => setPhone(e.currentTarget.value),
            placeholder: "Enter your 10 digit phone number",
          })}
          {InputField({
            label: "Business name*",
            errorCheckName: "business",
            inputType: "name",
            value: businessName,
            onInput: (e) => setBusinessName(e.currentTarget.value),
            placeholder: "Enter you business name",
          })}
          {InputField({
            label: "GST number (optional)",
            errorCheckName: "gst",
            inputType: "name",
            value: gstNumber,
            onInput: (e) => setGstNumber(e.currentTarget.value),
            placeholder: "Enter GST no.",
          })}
          <div class="flex flex-col">
            <label class="mb-2 text-mediumBold text-textDark">
              Address - As on your GST certificate (Optional)
            </label>
            <input
              type="address"
              class={`w-[400px] rounded-t-[12px] border border-b-[0.5px] border-baseTertiaryDark px-4  py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-b focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark`}
              placeholder={"Name / Company name"}
              value={address().address1}
              onInput={(e) => {
                setAddress((prevAddress) => ({
                  ...prevAddress,
                  address1: e.currentTarget.value,
                }));
              }}
            />
            <input
              type="address"
              class={`w-[400px]  border border-b-[0.5px] border-baseTertiaryDark px-4 py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-b focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark`}
              placeholder={"Building, Apartment, street"}
              value={address().address2}
              onInput={(e) => {
                setAddress((prevAddress) => ({
                  ...prevAddress,
                  address2: e.currentTarget.value,
                }));
              }}
            />
            <div class="flex w-[400px]">
              <input
                type="address"
                class={`w-[50%] border border-e-[0.5px] border-baseTertiaryDark px-4 py-3  text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-e focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark `}
                placeholder={"City"}
                value={address().place}
                onInput={(e) => {
                  setAddress((prevAddress) => ({
                    ...prevAddress,
                    place: e.currentTarget.value,
                  }));
                }}
              />
              <input
                type="address"
                class={`w-[50%] border border-baseTertiaryDark  border-s-[0.5]  px-4 py-3  text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-s focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark `}
                value={address().state}
                placeholder={"State"}
                onInput={(e) => {
                  setAddress((prevAddress) => ({
                    ...prevAddress,
                    state: e.currentTarget.value,
                  }));
                }}
              />
            </div>
            <input
              type="address"
              class={`w-[400px] rounded-b-[12px] border border-t-0 border-baseTertiaryDark px-4 py-3  text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-t focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark `}
              value={address().pincode}
              placeholder={"Postal code"}
              onInput={(e) => {
                setAddress((prevAddress) => ({
                  ...prevAddress,
                  pincode: e.currentTarget.value,
                }));
              }}
            />
          </div>
          <input
            id="file-upload"
            type="file"
            accept="application/pdf, image/*"
            onChange={handleFileChange}
            class="hidden"
          ></input>
          <label for="file-upload">
            <div class="flex flex-col">
              <label class="mb-2 text-mediumBold text-textDark">
                {"Upload GST certificate (Optional)"}
              </label>
              <Show
                when={file()}
                fallback={
                  <div
                    class="flex h-12 w-[400px] cursor-pointer content-center rounded-[12px] border border-baseTertiaryDark px-4 py-3"
                    style={{
                      "box-shadow": "0px 1px 4px 0px rgba(0, 0, 0, 0.05);",
                    }}
                  >
                    <PhosphorIcon
                      name="cloud-arrow-up"
                      class="text-textDark"
                      fontSize={24}
                    />
                    <div class="pl-3 text-normal text-textNormal">
                      {"Upload Image or PDF (Maximum 5 MB)"}
                    </div>
                  </div>
                }
              >
                <div
                  class="flex h-12 w-[400px] cursor-pointer content-center rounded-[12px] border border-baseTertiaryDark px-4 py-3"
                  style={{
                    "box-shadow": "0px 1px 4px 0px rgba(0, 0, 0, 0.05);",
                  }}
                >
                  <PhosphorIcon
                    name="file-image"
                    class="text-errorDark"
                    fontSize={24}
                  />
                  <div class="w-full pl-3 text-normal text-textDark">
                    {file()?.name}
                  </div>
                  <div
                    class="h-6 w-6 cursor-pointer items-center align-middle"
                    onclick={(event) => {
                      event.stopPropagation();
                      setFile(null);
                    }}
                  >
                    <PhosphorIcon name="x" fontSize={20} />
                  </div>
                </div>
              </Show>

              {errorStrip(error()?.includes("GST Certificate"))}
            </div>
          </label>
          {/* TODO: refactor to better handling */}
          {errorStrip(
            error() != "" &&
              !error()?.includes("GST Certificate") &&
              !error()?.includes("name") &&
              !error()?.includes("email") &&
              !error()?.includes("phone") &&
              !error()?.includes("business")
          )}
          <ButtonRegular
            class=" mt-2 h-12 w-full rounded-[8px] disabled:cursor-not-allowed"
            isEnabled={
              name() != "" &&
              email() != "" &&
              phone() != "" &&
              businessName() != "" &&
              error() == null
            }
            isLoading={isLoading()}
            isRectangular={true}
            onClick={async () => {
              await onboardClient();
            }}
          >
            Continue
          </ButtonRegular>
        </div>
      </div>
    </div>
  );

  function InputField(props: {
    label: string;
    inputType: string | undefined;
    errorCheckName: string;
    value: Accessor<string>;
    placeholder: string;
    onInput:
      | JSX.InputEventHandlerUnion<HTMLInputElement, InputEvent>
      | undefined;
  }) {
    return (
      <div class="flex flex-col">
        <label class="mb-2 text-mediumBold text-textDark">{props.label}</label>
        <input
          type={props.inputType}
          value={props.value() ?? undefined}
          class={`w-[400px] rounded-[12px] border px-4  py-3 text-bold text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark ${
            error()?.includes(props.errorCheckName)
              ? "border-errorDark"
              : "border-baseTertiaryDark"
          }`}
          placeholder={props.placeholder}
          onInput={props.onInput}
        />
        {errorStrip(error()?.includes(props.errorCheckName))}
      </div>
    );
  }
}
