import { useNavigate, useParams } from "@solidjs/router";
import { onMount } from "solid-js";
import { ThreeDotLoader } from "~/widgets/button";

export default function PayURedirect() {
  const params = useParams();

  onMount(async () => {
    const navigate = useNavigate();
    navigate("/order/" + params.orderId, {
      state: {
        fromPurchasesPage: false,
      },
    });
  });

  return (
    <div class="flex h-full w-full items-center justify-center">
      <ThreeDotLoader color="#333" />
    </div>
  );
}
