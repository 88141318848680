export const smartGCFaqs = {
  gift: [
    {
      question: "What is the Hubble Smart Greeting Card?",
      answer:
        "Hubble smart greeting card is a greeting card with a rechargeable digital gift card embedded inside. Senders can add any amount and select brands for the receiver to redeem. The greeting card comes in a gift bag making it a complete gift for the occasion.",
    },
    {
      question: "How is this different from a regular greeting card?",
      answer:
        "The Hubble Smart Greeting card is a rechargeable and redeemable greeting card with a digital gift card inside. It comes with an embedded digital gift card which is accessible via the QR code printed on the card. The sender can customize the digital gift by choosing the gift amount and brands. The receiver can redeem their gift amount on the brands selected.",
    },
    {
      question: "Is the card customizable with a personal message?",
      answer:
        "The greeting card comes with a heartfelt message, you can add your personal message directly on the card using a pen.",
    },
    {
      question: "Do I need to download an app to use the smart greeting card?",
      answer:
        "No app is needed to use the smart greeting card, just scan the QR code on the greeting card.",
    },
    {
      question: "Can I share the QR code with others?",
      answer:
        "To keep your gift secure, do not share the QR code with anyone except for the intended recipient of the gift.",
    },
    {
      question: "What does the package include?",
      answer:
        "The package includes an elegant and sturdy gift bag, a premium quality greeting card and an instructions sleeve.",
    },
  ],
  giftBalance: [
    {
      question: "How to top-up the smart greeting card?",
      answer:
        "To top-up the smart greeting card, enter the amount you want to give, select any 3 brands and continue to checkout. You can make the payment using any payment method of your preference - UPI, debit card or credit card.",
    },
    {
      question: "Can I recharge once the balance runs out?",
      answer:
        "No, you can only recharge the digital gift card once. Once you have created your gift, it cannot be changed.",
    },
  ],
  redeem: [
    {
      question: "Can the gift amount be used on any brand?",
      answer:
        "The gift amount added by you can be used to purchase gift vouchers of any of the 3 brands selected by you. These gift vouchers can be used to buy the brand’s products and services.",
    },
    {
      question: "How will the receiver access the gift amount?",
      answer:
        "The receiver can use the gift amount to purchase gift vouchers of the brands you have selected. They can keep generating gift vouchers till the balance is exhausted.",
    },
    {
      question: "How many brands can the receiver choose?",
      answer:
        "The recipient can choose 1 or more brands from the 3 brands you have selected at the time of creating the digital gift.",
    },
  ],
  activationCode: [
    {
      question: "What is the activation code?",
      answer:
        "This is the code through which the receiver can access the gift. Please keep this code safe and share it only with the intended recipient of the gift.",
    },
    {
      question: "How can I get the activation code?",
      answer:
        "Once you complete the payment and digit gift has been created, you will see the activation code on the screen as well as receive the activation code on whatsapp on your mobile number.",
    },
    {
      question: "I didn’t get the activation code?",
      answer:
        "In case you did not receive the code and could not view it on your screen, please reach out to Hubble support via the registered mobile number or email support@myhubble.money.",
    },
  ],
  validityReturns: [
    {
      question: "What is the validity of the digital gift?",
      answer:
        "The digital gift is valid for 1 year from the date of recharging.",
    },
    {
      question: "What is the process for return and exchange?",
      answer:
        "This product does not qualify for return or exchange. Please read the details carefully before purchasing.",
    },
  ],
};
